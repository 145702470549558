/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import { Form, Popconfirm, Tooltip, Typography } from "antd";
import { t } from "i18next";
import { INPUT_TYPE, REGEX } from "config/constants";
import FormInput from "components/FormInput";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { CURRENCY_INPUT, IMPORT_REQUEST_TYPE, optionCurrency } from "features/Warehouse/constant";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";
import { SvgIcon } from "assets/icons";
import CustomTitle from "features/Serial/components/CustomTitle";
import InputTag from "../InputTag";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";

function TableProduct({
  pendingWarrantyNoteLoading,
  form,
  handleGetTableOrder,
  dataTableProduct,
  handleDeleteProduct,
  setSumPrice,
  setCurrencyFirst,
  handleKeyPress,
  typeSalesReturn,
}) {
  const [quantity, setQuantity] = useState([]);
  const [price, setPrice] = useState([]);
  const [currency, setCurrency] = useState([]);

  const handleQuantity = (index, value) => {
    const updatedQuantity = [...quantity];
    updatedQuantity[index] = parseFloat(value);
    setQuantity(updatedQuantity);
  };

  const handlePrice = (index, value) => {
    const updatedPrice = [...price];
    updatedPrice[index] = parseFloat(value);
    setPrice(updatedPrice);
  };

  const handleCurrency = (index, value) => {
    const updatedCurrency = [...currency];
    updatedCurrency[index] = value;
    setCurrency(updatedCurrency);
  };

  const formData = form?.getFieldsValue();
  const serialCodes = formData?.items && formData?.items?.map((item) => item?.serialCodes?.map((i) => i?.code || i));
  const [tags, setTags] = useState([]);
  const handleChange = (e, index) => {
    setTags((prevTags) => {
      const updatedTags = [...prevTags];
      updatedTags[index] = e;
      return updatedTags;
    });
  };
  useEffect(() => {
    setPrice(formData?.items?.map((item) => item.price));
    setQuantity(formData?.items?.map((item) => item.quantity));
    setCurrency(formData?.items?.map((item) => item.currency));
    setTags(serialCodes);
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    let arrPrice = price?.map((item) => (isNaN(item) || item === null || item === undefined ? 0 : item));
    let arrQuanlity = quantity?.map((item) => (isNaN(item) || item === null || item === undefined ? 0 : item));
    let sum = 0;
    for (let i = 0; i < arrPrice?.length; i++) {
      if (arrPrice[i] !== 0 && arrQuanlity[i] !== 0) {
        sum += arrPrice[i] * arrQuanlity[i];
      }
    }
    setSumPrice(arrQuanlity && arrPrice ? sum : 0);
    setCurrencyFirst(currency?.length > 0 && currency[0]);
  }, [JSON.stringify(price), JSON.stringify(quantity), JSON.stringify(currency)]);

  const columns = [
    {
      align: "center",
      title: "STT",
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      align: "center",
      key: "codeorder",
      title: <span>{t("serialImportEdit.codeOrder")}</span>,
      render: (_, record, index) => {
        return (
          <Link to={`/order/${record?.orderID}`} target="_blank">
            <Typography.Link strong>{record?.orderCode}</Typography.Link>
          </Link>
        );
      },
    },
    {
      title: <span>{t("warehouse.recommendImport.PARAMETER")}</span>,
      align: "left",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Link to={`/product/detail/${record?.productID || record?.product?.id}`} target="_blank">
              <Typography.Link strong>{record?.code || record?.product?.code}</Typography.Link>
            </Link>
            <CustomTextHiddenLine
              text={
                <Tooltip title={record?.name || record?.product?.name}>
                  <Typography.Text strong>{record?.name || record?.product?.name}</Typography.Text>
                </Tooltip>
              }
            />
          </div>
        );
      },
      isHidden: false,
      width: 280,
    },
    {
      align: "center",
      key: "quantityOrder",
      title: <span>{t("warehouse.salesReturn.quantityOrder")}</span>,
      render: (_, record, index) => {
        return (
          <Link>
            <Typography.Text>{record?.quantityOrder}</Typography.Text>
          </Link>
        );
      },
      width: 150,
    },
    {
      align: "center",
      title:
        typeSalesReturn === IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY
          ? t("warehouse.recommendImport.quantity")
          : t("warehouse.salesReturn.quantityReturn"),
      dataIndex: "quantity",
      width: 150,
      render: (_, record, index) => {
        const tagsCount = formData?.items && formData?.items[index]?.serialCodes?.length;
        return (
          <FormInput
            {...{
              type: INPUT_TYPE.CURRENCY_INPUT,
              formItemOptions: {
                name: [index, "quantity"],
                rules: [
                  {
                    required: true,
                    message: t("warehouse.create.pleaseEnter"),
                  },
                  {
                    pattern: REGEX.NUMBER,
                  },
                  {
                    validator: (rule, value) => {
                      if ((value !== null && value < tagsCount) || value === 0) {
                        return Promise.reject(
                          <div style={{ fontSize: "12px", textAlign: "center" }}>
                            <span>{t("warehouse.salesReturn.validateQuantity")}</span>
                            <br />
                            <span>{t("warehouse.salesReturn.reality")}</span>
                          </div>
                        );
                      } else if (value > record?.quantityOrder && typeSalesReturn === IMPORT_REQUEST_TYPE.REFUND) {
                        return Promise.reject(
                          <div style={{ fontSize: "12px", textAlign: "center" }}>
                            <span
                              style={{
                                width: "130px",
                                display: "block",
                              }}
                            >
                              {t("warehouse.salesReturn.validateQuantity2")}
                            </span>
                          </div>
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ],
              },
              inputOptions: {
                style: { width: "70px" },
                maxLength: 5,
                min: 0,
                className: "form-text-center",
                onChange: (e) => handleQuantity(index, e),
                onKeyPress: handleKeyPress,
                placeholder: "0",
              },
            }}
          />
        );
      },
    },
    {
      align: "center",
      title: <span>{t("warehouse.salesReturn.PRICERETURN")}</span>,
      dataIndex: "price",
      render: (_, record, index) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormInput
            {...{
              type: INPUT_TYPE.CURRENCY_INPUT,
              formItemOptions: {
                name: [index, "price"],
                rules: [
                  {
                    required: true,
                    message: t("warehouse.create.pleaseEnter"),
                  },
                  {
                    pattern: REGEX.NUMBER,
                    message: t("serialIE.error.serialItems"),
                  },
                ],
              },
              inputOptions: {
                maxLength: 11,
                min: 0,
                style: { width: "110px" },
                className: "form-text-center form-border-radius-right",
                onChange: (e) => handlePrice(index, e),
                onKeyPress: handleKeyPress,
                placeholder: "0",
              },
            }}
          />
          <FormInput
            {...{
              type: INPUT_TYPE.SELECT,
              formItemOptions: {
                name: [index, "currency"],
              },
              inputOptions: {
                options: optionCurrency,
                onChange: (e) => handleCurrency(index, e),
                className: "form-border-radius-left",
              },
            }}
          />
        </div>
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.money"),
      render: (_, __, index) => {
        const currentQuantity = (quantity && quantity[index]) || 0;
        const currentPrice = (price && price[index]) || 0;
        const currentCurrency = (currency && currency[index]) || CURRENCY_INPUT?.VND;
        const tongthanhtien = currentQuantity && currentPrice ? currentQuantity * currentPrice : 0;
        return currentCurrency === CURRENCY_INPUT?.VND
          ? formatCurrency(tongthanhtien)
          : formatCurrencyDollar(tongthanhtien);
      },
    },
    {
      align: "center",
      title: "VAT",
      render: (_, record, index) => (
        <FormInput
          style={{ width: "50px" }}
          {...{
            type: INPUT_TYPE.CHECK_BOX,
            formItemOptions: {
              name: [index, "isVat"],
              valuePropName: "checked",
            },
            inputOptions: {},
          }}
        />
      ),
    },
    {
      align: "center",
      title: <span>{t("warehouse.salesReturn.reasonReturn")}</span>,
      render: (_, record, index) => (
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              name: [index, "importReason"],
            },
            inputOptions: {
              placeholder: t("warehouse.salesReturn.reasonInput"),
            },
          }}
        />
      ),
    },
    {
      align: "center",
      width: "20%",
      title: (
        <CustomTitle
          title={t("serialIE.productInfo.detailSerialB")}
          subTitle={
            <Typography.Text className={styles["sub-title"]} style={{ fontSize: 12 }}>
              {t("serialIE.productInfo.note")}
            </Typography.Text>
          }
        />
      ),
      render: (_, record, index) => {
        return (
          <Form.Item
            name={[index, "serialCodes"]}
            rules={[
              {
                validator: (rule, value) => {
                  const quantity = formData?.items && formData?.items[index]?.quantity;

                  if (value?.length > quantity) {
                    return Promise.reject(
                      <span style={{ fontSize: "12px" }}>{t("warehouse.salesReturn.validateQuantitySerial")}</span>
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputTag
              isHidden={true}
              tags={tags && tags[index]}
              onChange={(e) => handleChange(e, index)}
              idPopup={false}
              productId={record?.id}
              orderID={record?.orderID}
              quantity={formData?.items && formData?.items[index]?.quantity}
            />
          </Form.Item>
        );
      },
    },
    {
      align: "center",
      render: (_, record, index) => (
        <Popconfirm
          title={t("warehouse.create.messageConfirmDelete")}
          cancelText={t("common.cancel")}
          okText={t("serialIE.Comfirm")}
          onConfirm={() => handleDeleteProduct(record?.id, index, record?.idItem)}
        >
          <SvgIcon.TrashFullCartIcon style={{ fontSize: "16px", color: "#5B6673" }} />
        </Popconfirm>
      ),
    },
  ];

  const filteredColumns =
    typeSalesReturn === IMPORT_REQUEST_TYPE.REFUND
      ? columns.filter((column) => column.key !== "codeorder")
      : columns.filter((column) => column.key !== "quantityOrder");

  return (
    <div className={styles["custom-table"]}>
      <CustomTable
        columns={filteredColumns}
        dataSource={dataTableProduct}
        rowKey={(record) => record?.id}
        scroll={{
          x: 900,
        }}
        loading={pendingWarrantyNoteLoading}
      />
    </div>
  );
}

export default TableProduct;
