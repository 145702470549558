/* eslint-disable react-hooks/exhaustive-deps */
import { useGetSellerCreateCart } from 'hooks/seller';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { uniqBy } from 'lodash';
import { CURRENCY_INPUT, IMPORT_REQUEST_TYPE, WARRENTY_PERIOD } from 'features/Warehouse/constant';
import { notify } from 'utils/helperFuncs';
import { useCreateImportRequest } from 'hooks/warehouse/mutation';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { useWarehouse } from 'hooks/warehouse';

const useCreate = () => {
  const [form] = useForm();
  const formData = form.getFieldsValue();
  const [sellerInfo, setSellerInfo] = useState(null);

  const [paramsWarehouse] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { warehouses } = useWarehouse(paramsWarehouse);
  const valueImportWarehouse = warehouses?.map(({ id, name }) => ({ value: id, label: name }));
  const [dataTableProduct, setDataTableProduct] = useState([]);
  const history = useHistory();

  const [sumPrice, setSumPrice] = useState(0);
  const [currencyFirst, setCurrencyFirst] = useState(0);

  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null, isActive: true, isSupplier: true },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    sellers,
    loadMoreData: loadMoreSeller,
    hasMoreData: hasMoreSeller,
  } = useGetSellerCreateCart(paramsSeller, 'cache-first');

  const onSearchSeller = (value) => {
    setParamsSeller({
      filters: { ...paramsSeller.filters, query: value || null, isSupplier: true },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleGetProduct = (data) => {
    const formData = form.getFieldsValue();
    const newData = {
      productID: data?.id,
      productName: data?.name,
      price: null,
      currency: CURRENCY_INPUT?.VND,
      warranty: null,
      warrantyPeriod: WARRENTY_PERIOD?.MONTH,
      isVat: false,
      creditDays: null,
      paymentNote: null,
    };
    form.setFieldsValue({
      ...formData,
      items: uniqBy([...formData.items, newData], 'productID'),
    });
    setDataTableProduct(uniqBy([...dataTableProduct, data], 'id'));
  };
  const handleAddProduct = (values) => {
    const formData = form.getFieldsValue();
    const idcheck = uuidv4();
    const newData = {
      isAdd: true,
      productID: idcheck,
      productName: values?.name,
      quantity: values?.quantity || null,
      price: values?.price || null,
      currency: values?.currency,
      warranty: values?.warranty || null,
      warrantyPeriod: values?.warrantyPeriod,
      isVat: values?.isVat,
      creditDays: values?.creditDays || null,
      paymentNote: values?.paymentNote,
      expectedDeliveryTime: values?.expectedDeliveryTime,
    };
    form.setFieldsValue({
      ...formData,
      items: [...formData.items, newData],
    });
    setDataTableProduct(uniqBy([...dataTableProduct, { ...values, id: idcheck }], 'id'));
  };
  const handleDeleteProduct = (id, index) => {
    const formData = form.getFieldsValue();
    const dataFormDelete = formData?.items?.filter((item) => item.productID !== id);
    form.setFieldsValue({
      ...formData,
      items: dataFormDelete,
    });
    const dataTableDelete = dataTableProduct?.filter((item) => item.id !== id);
    setDataTableProduct(dataTableDelete);
  };

  const initialValues = {
    items: [],
    warehouseID: null,
    type: IMPORT_REQUEST_TYPE.DOMESTIC,
  };

  const { handleCreateImportReques } = useCreateImportRequest();
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const passwordInputRef = useRef(null);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const handlePasswordKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setIsFormCompleted(true);
      passwordInputRef.current.blur();
    }
  };

  const handleCreateClick = () => {
    setIsFormCompleted(true);
  };

  const handleFinish = async (values) => {
    setLoadingCreate(true);
    await form.validateFields().then(async (values) => {
      if (isFormCompleted) {
        const params = {
          supplierID: values?.supplierID,
          warehouseID: values?.warehouseID,
          type: values?.type,
          paymentNotes: values?.paymentNotes,
          paymentTerms: values?.paymentTerms,
          paymentMethods: values?.paymentMethods,
          note: values?.note,
          fileURLs: values?.fileURLs,
          items: values?.items?.map((item, i) => {
            return {
              productID: item?.isAdd ? undefined : item?.productID,
              productName: item?.productName,
              quantity: item?.quantity,
              price: item?.price,
              currency: item?.currency,
              warranty: item?.warranty,
              warrantyPeriod: item?.warrantyPeriod,
              isVat: item?.isVat,
              creditDays: item?.creditDays,
              paymentNote: item?.paymentNote,
              expectedDeliveryTime: item?.expectedDeliveryTime,
            };
          }),
        };
        try {
          if (values?.items?.length > 0) {
            await handleCreateImportReques({ importRequest: params })
              .then(() => {
                notify.success({ message: t('serialIE.notify.successCreate') });
                history.push('/warehouse-list');
              })
              .catch((error) => {
                notify.error({
                  message: error.message,
                });
              });
          } else {
            notify.error({
              message: t('warehouse.create.PleaseChooseProduct'),
            });
          }
        } catch (error) {
          console.info(error);
        } finally {
          setLoadingCreate(false);
        }
      }
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  const handleChangeInfo = (value, option) => {
    setSellerInfo(option?.seller);
  };
  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
  }, []);
  return {
    form,
    valueImportWarehouse,
    sellers,
    dataTableProduct,
    handleGetProduct,
    handleFinish,
    initialValues,
    handleAddProduct,
    handleChangeInfo,
    sellerInfo,
    loadMoreSeller,
    hasMoreSeller,
    onSearchSeller,
    history,
    loadingCreate,
    handleDeleteProduct,
    formData,
    setSumPrice,
    setCurrencyFirst,
    currencyFirst,
    sumPrice,
    handleCreateClick,
    handlePasswordKeyPress,
    handleKeyPress,
  };
};

export default useCreate;
