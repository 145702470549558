import React, { useState } from "react";
import { t } from "i18next";
import { Button, Radio, Space, Switch, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { useUpdateSellerStatus } from "hooks/seller";
import { notify } from "utils/helperFuncs";
import { IS_ACTIVES, reasonLock, TOGGLE } from "features/Supplier/constant";
import { INPUT_TYPE } from "config/constants";
import FormInput from "components/FormInput";
import Img from "assets/images";
import { useSupplierPermissions } from "hooks/supplier";

const ModalSwitch = ({ selectedSwitch, form, record, onChangeStatus, isActiveSupplier, reason }) => {
  const [visible, setVisible] = useState(false);
  const { handleUpdateSellerStatus, loading: loadingUpdateStatus } = useUpdateSellerStatus();
  const { canDeactivateSupplier } = useSupplierPermissions();

  const handleOpen = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleConfirmLockAcc = async () => {
    const { id, isActiveSupplier } = selectedSwitch;
    await form.validateFields().then(async (values) => {
      await handleUpdateSellerStatus({
        id,
        isActiveSupplier,
        reason: isActiveSupplier ? undefined : values,
      })
        .then(() => {
          form.resetFields();
          handleCancel();
          notify.success({
            message: isActiveSupplier ? t("seller.activeSellerSuccess") : t("seller.deactivateSellerSuccess"),
          });
        })
        .catch((err) => {
          notify.error({
            message: isActiveSupplier ? t("seller.activeSellerError") : t("seller.deactivateSellerError"),
          });
        });
    });
  };

  return canDeactivateSupplier ? (
    <CustomModal
      {...{
        message: isActiveSupplier ? (
          <Typography>
            {t("supplier.active.first")}
            <Typography.Link strong> {record?.fullName || "--"} </Typography.Link>
            {t("supplier.active.second")}
          </Typography>
        ) : (
          <>
            <Typography.Title level={5}>{t("seller.lockAcc")}</Typography.Title>
            <Typography.Paragraph type="warning">{t("supplier.note")}</Typography.Paragraph>
            <FormInput
              type={INPUT_TYPE.RADIO_GROUP}
              formItemOptions={{ name: "defaultReason" }}
              inputChildren={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {reasonLock.map((item, index) => (
                    <Radio style={{ padding: 8 }} key={index} value={item.value}>
                      {item.label}
                    </Radio>
                  ))}

                  <Radio style={{ padding: 8 }} value={TOGGLE.OTHER}>
                    <Space>
                      <Typography.Text>{t("common.other")}</Typography.Text>
                      <FormInput
                        formItemOptions={{
                          name: "freeText",
                          style: { margin: 0 },
                          rules: [
                            {
                              required: !reason && true,
                              message: t("common.validateRequired"),
                            },
                          ],
                        }}
                        inputOptions={{
                          placeholder: t("seller.reasonLockPlace"),
                          disabled: reason && true,
                          maxLength: 500,
                        }}
                      />
                    </Space>
                  </Radio>
                </div>
              }
            />
          </>
        ),
        hideConfirmButton: true,
        visible: false, //visible,
        onCancel: handleCancel,
        onOpen: handleOpen,
        addMoreButton: (
          <Button type="primary" onClick={handleConfirmLockAcc} loading={loadingUpdateStatus}>
            {t("common.confirm")}
          </Button>
        ),
        centered: true,
        footer: false,
      }}
      icon={record.isActiveSupplier === true ? Img.LockedIcon : Img.UnlockedIcon}
      customComponent={
        <Switch
          onChange={(value) => onChangeStatus(record, value)}
          loading={loadingUpdateStatus}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          checked={record?.isActiveSupplier === IS_ACTIVES.ACTIVE}
          disabled={true}
        />
      }
    />
  ) : (
    <Switch
      checkedChildren="ON"
      unCheckedChildren="OFF"
      checked={record?.isActiveSupplier === IS_ACTIVES.ACTIVE}
      disabled={!canDeactivateSupplier}
    />
  );
};

export default ModalSwitch;
