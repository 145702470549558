import { SvgIcon } from "assets/icons";
import { PAYMENT_METHOD, PRIORITY_LEVEL, SHIPPING_TYPE, STATUS_ORDER, STOCK_STATUS } from "config/constants";
import { t } from "i18next";
import { decimalTimeToTimeString } from "utils/dateTime";

export const ORDER_PRIORITY = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
};

export const ORDER_STATUS = {
  WAITING_FOR_APPROVAL_STOCK: "WAITING_FOR_APPROVAL_STOCK",
  WAITING_FOR_ENOUGH_STOCK: "WAITING_FOR_ENOUGH_STOCK",
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  WAITING_FOR_PACKAGING: "WAITING_FOR_PACKAGING",
  WAITING_FOR_EXPORTING: "WAITING_FOR_EXPORTING",
  WAITING_FOR_SHIPPING: "WAITING_FOR_SHIPPING",
  WAITING_FOR_SHIPPING_NOT_SET: "WAITING_FOR_SHIPPING_NOT_SET",
  WAITING_FOR_SHIPPING_WAITING: "WAITING_FOR_SHIPPING_WAITING",
  WAITING_FOR_ASSEMBLY: "WAITING_FOR_ASSEMBLY",
  DELIVERING: "DELIVERING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const STATUS_STOCK = {
  //thực tế
  ACTUTAL_STOCK: [
    ORDER_STATUS.DELIVERING,
    ORDER_STATUS.COMPLETED,
    ORDER_STATUS.CANCELED,
    ORDER_STATUS.WAITING_FOR_ASSEMBLY,
    ORDER_STATUS.WAITING_FOR_SHIPPING,
  ],
  //thực tế / khả dụng
  ACTUTAL_AND_AVAILABLE_STOCK: [ORDER_STATUS.WAITING_FOR_EXPORTING, ORDER_STATUS.WAITING_FOR_PACKAGING],
};

export const CANCEL_ORDER_REASON = {
  LACK_OF_STOCK: "LACK_OF_STOCK",
  OUT_OF_DEBT: "OUT_OF_DEBT",
  UNABLE_TO_PRE_ORDER: "UNABLE_TO_PRE_ORDER",
  CAN_NOT_BE_SOLD_AT_THIS_PRICE: "CAN_NOT_BE_SOLD_AT_THIS_PRICE",
  OVERDUE_ORDER_PAYMENT: "OVERDUE_ORDER_PAYMENT",
  CHANGE_ORDER_INFORMATION: "CHANGE_ORDER_INFORMATION",
  CHANGE_PRODUCTS_IN_THE_ORDER: "CHANGE_PRODUCTS_IN_THE_ORDER",
  CUSTOMERS_NO_LONGER_NEED_TO_BUY: "CUSTOMERS_NO_LONGER_NEED_TO_BUY",
  OTHER: "OTHER",
};

export const TOGGLE_OFF_AGENT = {
  OVERDUE_DEBT: "OVERDUE_DEBT",
  BAD_DEBT: "BAD_DEBT",
};

export const WARNING_MODAL_ACTION = {
  APPROVAL_STOCK_LIST: "APPROVAL_STOCK_LIST",
  APPROVAL_STOCK_DETAIL: "APPROVAL_STOCK_DETAIL",
  WAITING_STOCK_LIST: "WAITING_STOCK_LIST",
  WAITING_STOCK_DETAIL: "WAITING_STOCK_DETAIL",
  DENY_STOCK_LIST: "DENY_STOCK_LIST",
  DENY_STOCK_DETAIL: "DENY_STOCK_DETAIL",
  CONFIRM_STOCK_LIST: "CONFIRM_STOCK_LIST",
  CONFIRM_STOCK_DETAIL: "CONFIRM_STOCK_DETAIL",
};

export const ORDER_TYPE = {
  SELLER: "SELLER",
  CUSTOMER: "CUSTOMER",
};

export const CUSTOMER_PAYMENT_METHOD = {
  COD: "COD",
  CASH: "CASH",
};

export const PAYMENT_METHOD_AGENT = {
  DEBT: "DEBT",
  CASH: "CASH",
};

export const TYPE_PRODUCT = {
  MATERIAL: "MATERIAL",
  SERVICE: "SERVICE",
};

export const DELIVER_TYPE = {
  SHIPPER: "SHIPPER",
  DELIVER_UNIT: "DELIVER_UNIT",
  CLIENT: "CLIENT",
};

export const DELIVER_ORDER_STATUS = {
  FREE: "FREE",
  WAIT_TO_DELIVER: "WAIT_TO_DELIVER",
  IN_DELIVER: "IN_DELIVER",
};

export const SHIPPING_STATUS = {
  WAITING: "WAITING",
  CONFIRM_PICKED: "CONFIRM_PICKED",
  NOT_SET: "NOT_SET",
};

export const PAYMENT_STATUS = {
  PENDING: "PENDING",
  WAITING_APPROVAL: "WAITING_APPROVAL",
  PAID: "PAID",
};

export const renderColorTagByPaymentStatus = (paymentStatus) => {
  switch (paymentStatus) {
    case PAYMENT_STATUS.PENDING:
      return "orange";
    case PAYMENT_STATUS.WAITING_APPROVAL:
      return "blue";
    case PAYMENT_STATUS.PAID:
      return "green";
    default:
      return "black";
  }
};

export const renderStringTagByPaymentStatus = (paymentStatus) => {
  switch (paymentStatus) {
    case PAYMENT_STATUS.PENDING:
      return t("bank_transfer.order.pending");
    case PAYMENT_STATUS.WAITING_APPROVAL:
      return t("bank_transfer.order.waiting_approval");
    case PAYMENT_STATUS.PAID:
      return t("bank_transfer.order.paid");
    default:
      return t("bank_transfer.order.pending");
  }
};

export const SALES_CHANNEL = {
  WEB: "WEB",
  APP: "APP",
  SELLER_WEB: "SELLER_WEB",
};

export const tagColorSalesChannel = {
  [SALES_CHANNEL.WEB]: "magenta",
  [SALES_CHANNEL.APP]: "green",
  [SALES_CHANNEL.SELLER_WEB]: "gold",
};

export const STAFF_CANCEL_REASON = {
  NOT_ENOUGH: "NOT_ENOUGH",
  NOT_ENOUGH_DEBT_LIMIT: "NOT_ENOUGH_DEBT_LIMIT",
  CANNOT_IMPORT_GOODS: "CANNOT_IMPORT_GOODS",
  SALE_CANNOT_BE_AT_CURRENT_UNIT_PRICE: "SALE_CANNOT_BE_AT_CURRENT_UNIT_PRICE",
  OVERDUE_ORDER_PAYMENT: "OVERDUE_ORDER_PAYMENT",
  CHANGE_ORDER_INFORMATION_STAFF: "CHANGE_ORDER_INFORMATION_STAFF",
  CHANGE_PRODUCTS_IN_ORDER_STAFF: "CHANGE_PRODUCTS_IN_ORDER_STAFF",
  CUSTOMERS_CHANGE_MIND_AND_DONT_BUY_ANYMORE: "CUSTOMERS_CHANGE_MIND_AND_DONT_BUY_ANYMORE",
  OTHER_STAFF: "OTHER_STAFF",
};

export const STAFF_CANCEL_REASON_ARRAY = [
  STAFF_CANCEL_REASON.NOT_ENOUGH,
  STAFF_CANCEL_REASON.NOT_ENOUGH_DEBT_LIMIT,
  STAFF_CANCEL_REASON.CANNOT_IMPORT_GOODS,
  STAFF_CANCEL_REASON.SALE_CANNOT_BE_AT_CURRENT_UNIT_PRICE,
  STAFF_CANCEL_REASON.OVERDUE_ORDER_PAYMENT,
  STAFF_CANCEL_REASON.CHANGE_ORDER_INFORMATION_STAFF,
  STAFF_CANCEL_REASON.CHANGE_PRODUCTS_IN_ORDER_STAFF,
  STAFF_CANCEL_REASON.CUSTOMERS_CHANGE_MIND_AND_DONT_BUY_ANYMORE,
  STAFF_CANCEL_REASON.OTHER_STAFF,
];

export const SELLER_CANCEL_REASON = {
  CHANGE_ORDER_INFORMATION: "CHANGE_ORDER_INFORMATION",
  CHANGE_PRODUCTS_IN_ORDER: "CHANGE_PRODUCTS_IN_ORDER",
  FIND_A_BETTER_PLACE_TO_BUY: "FIND_A_BETTER_PLACE_TO_BUY",
  DELIVERY_TIME_IS_TOO_LONG: "DELIVERY_TIME_IS_TOO_LONG",
  NO_NEED_TO_BUY_ANY_MORE: "NO_NEED_TO_BUY_ANY_MORE",
  OTHER_SELLER: "OTHER_SELLER",
};

export const SELLER_CANCEL_REASON_ARRAY = [
  SELLER_CANCEL_REASON.CHANGE_ORDER_INFORMATION,
  SELLER_CANCEL_REASON.CHANGE_PRODUCTS_IN_ORDER,
  SELLER_CANCEL_REASON.FIND_A_BETTER_PLACE_TO_BUY,
  SELLER_CANCEL_REASON.DELIVERY_TIME_IS_TOO_LONG,
  SELLER_CANCEL_REASON.NO_NEED_TO_BUY_ANY_MORE,
  SELLER_CANCEL_REASON.OTHER_SELLER,
];

export const SELLER_CANCEL_REASON_LABEL = {
  BY_SELLER: "order.cancelReasons.BY_SELLER",
  CHANGE_ORDER_INFORMATION: "order.cancelReasons.CHANGE_ORDER_INFORMATION",
  CHANGE_PRODUCTS_IN_ORDER: "order.cancelReasons.CHANGE_PRODUCTS_IN_ORDER",
  FIND_A_BETTER_PLACE_TO_BUY: "order.cancelReasons.FIND_A_BETTER_PLACE_TO_BUY",
  DELIVERY_TIME_IS_TOO_LONG: "order.cancelReasons.DELIVERY_TIME_IS_TOO_LONG",
  NO_NEED_TO_BUY_ANY_MORE: "order.cancelReasons.NO_NEED_TO_BUY_ANY_MORE",
  OTHER_SELLER: "order.cancelReasons.OTHER",
};

export const STAFF_CANCEL_REASON_LABEL = {
  BY_STAFF: "order.cancelReasons.BY_STAFF",
  NOT_ENOUGH: "order.cancelReasons.NOT_ENOUGH",
  NOT_ENOUGH_DEBT_LIMIT: "order.cancelReasons.NOT_ENOUGH_DEBT_LIMIT",
  CANNOT_IMPORT_GOODS: "order.cancelReasons.CANNOT_IMPORT_GOODS",
  SALE_CANNOT_BE_AT_CURRENT_UNIT_PRICE: "order.cancelReasons.SALE_CANNOT_BE_AT_CURRENT_UNIT_PRICE",
  OVERDUE_ORDER_PAYMENT: "order.cancelReasons.OVERDUE_ORDER_PAYMENT",
  CHANGE_ORDER_INFORMATION_STAFF: "order.cancelReasons.CHANGE_ORDER_INFORMATION_STAFF",
  CHANGE_PRODUCTS_IN_ORDER_STAFF: "order.cancelReasons.CHANGE_PRODUCTS_IN_ORDER_STAFF",
  CUSTOMERS_CHANGE_MIND_AND_DONT_BUY_ANYMORE: "order.cancelReasons.CUSTOMERS_CHANGE_MIND_AND_DONT_BUY_ANYMORE",
  OTHER_STAFF: "order.cancelReasons.OTHER",
};

export const renderOrderPriority = (priorityLevel) => {
  switch (priorityLevel) {
    case PRIORITY_LEVEL.LOW:
      return <span>{t("order.orderList.low")}</span>;
    case PRIORITY_LEVEL.MEDIUM:
      return <span className="priority-medium">{t("order.orderList.medium")}</span>;
    case PRIORITY_LEVEL.HIGH:
      return <span className="priority-high">{t("order.orderList.high")}</span>;
    default:
      return;
  }
};

export const renderShippingMethod = (method) => {
  switch (method) {
    case SHIPPING_TYPE.BUS:
      return t("order.orderList.bus");
    case SHIPPING_TYPE.DELIVER_3PARTY:
      return t("order.orderList.deliver3Party");
    case SHIPPING_TYPE.PICK_UP_COUNTER:
      return t("order.orderList.pickUpCounter");
    case SHIPPING_TYPE.URBAN_COD:
      return t("order.orderList.urbanCOD");
    default:
      return;
  }
};

export const renderPaymentMethod = (method) => {
  switch (method) {
    case PAYMENT_METHOD.Cash:
      return t("order.orderList.cash");
    case PAYMENT_METHOD.CashAndDebt:
      return t("order.orderList.cashAndDebit");
    case PAYMENT_METHOD.COD:
      return t("order.orderList.COD");
    case PAYMENT_METHOD.Debt:
      return t("order.orderList.debt");
    default:
      return;
  }
};

export const renderStatus = (status) => {
  switch (status) {
    case STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER:
      return t("order.orderStatus.waitingForApprovalOrder");
    case STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK:
      return t("order.orderStatus.waitingForApprovalStock");
    case STATUS_ORDER.WAITING_FOR_PACKAGING:
      return t("order.orderStatus.waitingForPackaging");
    case STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK:
      return t("order.orderStatus.waitingForEnoughStock");
    case STATUS_ORDER.WAITING_FOR_PAYMENT:
      return t("order.orderStatus.waitingForPayment");
    case STATUS_ORDER.WAITING_FOR_EXPORTING:
      return t("order.orderStatus.waitingForExporting");
    case STATUS_ORDER.WAITING_FOR_ASSEMBLY:
      return t("order.orderStatus.waitingForAssembly");
    case STATUS_ORDER.WAITING_FOR_SHIPPING:
      return t("order.orderStatus.waitingForDelivery");
    case STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET:
      return t("order.orderStatus.waitingForDispatch");
    case STATUS_ORDER.WAITING_FOR_SHIPPING_WAITING:
      return t("order.orderStatus.waitingForDelivery");
    case STATUS_ORDER.DELIVERING:
      return t("order.orderStatus.delivering");
    case STATUS_ORDER.COMPLETED:
      return <div className="order-completed">{t("order.orderStatus.completed")}</div>;
    case STATUS_ORDER.CANCELED:
      return <div className="order-cancel">{t("order.orderStatus.cancel")}</div>;
    default:
      return;
  }
};

export const renderVATAndFullStockRequired = (value) => {
  switch (value) {
    case true:
      return <SvgIcon.SuccessIcon />;
    case false:
      return <SvgIcon.ErrorIcon />;
    default:
      return;
  }
};

export const renderRemainingDeliverTime = (time) => {
  if (time < 24 && time > 0) {
    return <div className="yellow-remaining-deliver-time">{decimalTimeToTimeString(time)}</div>;
  }
  if (time <= 0) {
    return <div className="red-remaining-deliver-time">- {decimalTimeToTimeString(time)}</div>;
  }
  return <div className="green-remaining-deliver-time">{decimalTimeToTimeString(time)}</div>;
};

export const renderStockStatus = (value) => {
  switch (value) {
    case STOCK_STATUS.FULL:
      return <div>{t("common.full")}</div>;
    case STOCK_STATUS.LACK:
      return <div className="yellow-stock-status">{t("common.lack")}</div>;
    case STOCK_STATUS.OUT_OF_STOCK:
      return <div className="red-stock-status">{t("common.outOfStock")}</div>;
    default:
      return;
  }
};
