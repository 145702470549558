import {
  faBars,
  faBell,
  faBox,
  faCaretRight,
  faChartBar,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCog,
  faCopy,
  faFile,
  faFilePdf,
  faFilter,
  faImage,
  faImages,
  faKey,
  faLock,
  faMedal,
  faMinusSquare,
  faSearch,
  faShieldAlt,
  faShoppingCart,
  faSignOutAlt,
  faTrash,
  faUser,
  faUserFriends,
  faUserLock,
  faUsers,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReactComponent as Checked } from "./Checked.svg";
import { ReactComponent as AccountantIcon } from "./accountant-icon.svg";
import { ReactComponent as AddCartIcon } from "./add-cart-icon.svg";
import { ReactComponent as AddProductCartIcon } from "./add-product-cart-icon.svg";
import { ReactComponent as ArrowCircleDownIcon } from "./arrow-down.svg";
import { ReactComponent as CampaignIcon } from "./campaign-icon.svg";
import { ReactComponent as CancelOrderIcon } from "./cancel-order-icon.svg";
import { ReactComponent as CartIcon } from "./cart-icon.svg";
import { ReactComponent as ChevronDown } from "./chevron_down.svg";
import { ReactComponent as CloseIcon } from "./close_small.svg";
import { ReactComponent as CloudDownloadIcon } from "./cloud-download-icon.svg";
import { ReactComponent as CopyCartIcon } from "./copy-cart-icon.svg";
import { ReactComponent as CopyIcon } from "./copy-icon.svg";
import { ReactComponent as CurrentOrderIcon } from "./current-order-icon.svg";
import { ReactComponent as EditCartIcon } from "./edit-cart-icon.svg";
import { ReactComponent as EditSquare } from "./edit-square.svg";
import { ReactComponent as EditIcon } from "./edit_icon.svg";
import { ReactComponent as EditIcon2 } from "./edit_icon_2.svg";
import { ReactComponent as EmptyIcon } from "./empty-icon.svg";
import { ReactComponent as ErrorFilledIcon } from "./error-filled-icon.svg";
import { ReactComponent as ErrorIcon } from "./error-icon.svg";
import { ReactComponent as ExportFileCartIcon } from "./export-file-cart-icon.svg";
import { ReactComponent as ExportFileIcon } from "./export-file-icon.svg";
import { ReactComponent as EyeIcon } from "./eye.svg";
import { ReactComponent as FaceSmile } from "./face-smile.svg";
import { ReactComponent as FirstMedal } from "./first-medal.svg";
import { ReactComponent as FutureOrderIcon } from "./future-order-icon.svg";
import { ReactComponent as HelpCircleIcon } from "./help-circle-icon.svg";
import { ReactComponent as HelpIcon } from "./help_circle_outline.svg";
import { ReactComponent as FixedPriceIcon } from "./icon-combo-gia-co-dinh.svg";
import { ReactComponent as DiscountIcon } from "./icon-combo-giam-tien.svg";
import { ReactComponent as GiftIcon } from "./icon-combo-tang-qua.svg";
import { ReactComponent as EmptyData } from "./icon-empty-data.svg";
import { ReactComponent as IconCoppy } from "./icon_coppy.svg";
import { ReactComponent as IconDelete } from "./icon_delete.svg";
import { ReactComponent as IconInfoQuestion } from "./icon_info_question.svg";
import { ReactComponent as IconMore } from "./icon_more.svg";
import { ReactComponent as IconUploadImage } from "./icon_upload_image.svg";
import { ReactComponent as IconViewList } from "./icon_view_list.svg";
import { ReactComponent as ImageIcon } from "./image-icon.svg";
import { ReactComponent as IconUpload, ReactComponent as ImportPriceSeries } from "./import-price-series.svg";
import { ReactComponent as InfoCircleIcon } from "./info-circle-icon.svg";
import { ReactComponent as LogoFileWordIcon } from "./logo_file_word_icon.svg";
import { ReactComponent as PastOrderIcon } from "./past-order-icon.svg";
import { ReactComponent as IconPotentialCustomer } from "./potential-customer-final.svg";
import { ReactComponent as PrinterBlueIcon } from "./printer-blue.svg";
import { ReactComponent as PrinterIcon } from "./printer.svg";
import { ReactComponent as ProductIcon } from "./product-icon.svg";
import { ReactComponent as RechargeIcon } from "./recharge-icon.svg";
import { ReactComponent as SecondMedal } from "./second-medal.svg";
import { ReactComponent as SerialIcon } from "./serial-icon.svg";
import { ReactComponent as ShareIcon } from "./share-icon.svg";
import { ReactComponent as SortIcon } from "./sort-icon.svg";
import { ReactComponent as SuccessIcon } from "./success-icon.svg";
import { ReactComponent as SuccessOrderIcon } from "./success-order-icon.svg";
import { ReactComponent as ThirdMedal } from "./third-medal.svg";
import { ReactComponent as TransactionHistory } from "./transaction-history.svg";
import { ReactComponent as TrashFullCartIcon } from "./trash-full-cart-icon.svg";
import { ReactComponent as WarningFileUpload } from "./warning-file-upload.svg";
import { ReactComponent as WarningFilledIcon } from "./warning-filled-icon.svg";
import { ReactComponent as Xlsx } from "./xlsx.svg";

const Icon = {
  CustomIcon: FontAwesomeIcon,
  faCog,
  faUserFriends,
  faBell,
  faUser,
  faChevronDown,
  faLock,
  faSignOutAlt,
  faBars,
  faSearch,
  faFilter,
  faTrash,
  faImages,
  faBox,
  faMinusSquare,
  faCheckSquare,
  faUserLock,
  faCopy,
  faCaretRight,
  faChevronRight,
  faChevronLeft,
  faFile,
  faWindowClose,
  faImage,
  faFilePdf,
  faShoppingCart,
  faUsers,
  faCheckCircle,
  faMedal,
  faChartBar,
  faShieldAlt,
  faKey,
};

const SvgIcon = {
  ProductIcon,
  RechargeIcon,
  TransactionHistory,
  AccountantIcon,
  CloudDownloadIcon,
  SuccessIcon,
  ErrorIcon,
  EditIcon,
  AddCartIcon,
  CopyCartIcon,
  ExportFileCartIcon,
  EditCartIcon,
  HelpCircleIcon,
  TrashFullCartIcon,
  AddProductCartIcon,
  InfoCircleIcon,
  CartIcon,
  CloseIcon,
  SortIcon,
  PrinterIcon,
  ImageIcon,
  WarningFilledIcon,
  FaceSmile,
  CampaignIcon,
  FirstMedal,
  SecondMedal,
  ThirdMedal,
  EmptyIcon,
  ShareIcon,
  ExportFileIcon,
  CancelOrderIcon,
  CurrentOrderIcon,
  FutureOrderIcon,
  PastOrderIcon,
  SuccessOrderIcon,
  PrinterBlueIcon,
  ArrowCircleDownIcon,
  EyeIcon,
  SerialIcon,
  ImportPriceSeries,
  Xlsx,
  Checked,
  LogoFileWordIcon,
  WarningFileUpload,
  ChevronDown,
  EditIcon2,
  IconUploadImage,
  CopyIcon,
  DiscountIcon,
  GiftIcon,
  FixedPriceIcon,
  IconMore,
  IconCoppy,
  IconDelete,
  IconViewList,
  EditSquare,
  IconPotentialCustomer,
  IconUpload,
  EmptyData,
  IconInfoQuestion,
  ErrorFilledIcon,
  HelpIcon,
};

export { FontAwesomeIcon, Icon, SvgIcon };
