import { gql } from "@apollo/client";

export const CREATE_ORDER = gql`
  mutation create($cartID: ID!) {
    order {
      create(cartID: $cartID) {
        id
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation updateStatus($orderIDs: [ID!]!, $newStatus: OrderStatus!) {
    order {
      updateStatus(orderIDs: $orderIDs, newStatus: $newStatus)
    }
  }
`;

export const APPROVE_STOCK = gql`
  mutation approveStock($orderIDs: [ID!]!) {
    order {
      approveStock(orderIDs: $orderIDs)
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($orderIDs: [ID!]!, $reason: String) {
    order {
      cancel(orderIDs: $orderIDs, reason: $reason)
    }
  }
`;

export const APPROVE_PAYMENT = gql`
  mutation approvePayment($orderIDs: ID!, $paymentProofs: [String!]) {
    order {
      approvePayment(orderID: $orderIDs, paymentProofs: $paymentProofs)
    }
  }
`;

export const CONFIRM_PAYMENT = gql`
  mutation confirmPayment($orderIDs: [ID!]!) {
    order {
      confirmPayment(orderIDs: $orderIDs)
    }
  }
`;

export const CONFIRM_EXPORT_ORDER = gql`
  mutation confirmExportOrder($orderIDs: [ID!]!) {
    order {
      export(orderIDs: $orderIDs)
    }
  }
`;

export const COMPLETED_ORDER = gql`
  mutation completedOrder($orderID: ID!, $imageURLs: [String!]) {
    order {
      complete(orderID: $orderID, imageURLs: $imageURLs)
    }
  }
`;

export const CONFIRM_DELIVER = gql`
  mutation confirmDeliver($orderIDs: [ID!]!, $shipperID: ID!) {
    order {
      deliver(orderIDs: $orderIDs, shipperID: $shipperID)
    }
  }
`;

export const WAITING_FOR_ENOUGH_STOCK = gql`
  mutation waitingStock($orderIDs: [ID!]!) {
    order {
      waitStock(orderIDs: $orderIDs)
    }
  }
`;

export const DENY_STOCK = gql`
  mutation denyStock($orderIDs: [ID!]!) {
    order {
      denyStock(orderIDs: $orderIDs)
    }
  }
`;

export const EXPORT_ORDER = gql`
  mutation exportOrder($orderID: ID, $fileType: ExportFileType!, $orderIDs: [ID!]) {
    order {
      exportFile(orderID: $orderID, fileType: $fileType, orderIDs: $orderIDs) {
        url
      }
    }
  }
`;

export const COMPLETE_ASSEMBLY = gql`
  mutation assembly($orderIDs: [ID!]!) {
    order {
      assembly(orderIDs: $orderIDs)
    }
  }
`;

export const PACKAGE_ORDER = gql`
  mutation package($orderIDs: [ID!]!) {
    order {
      package(orderIDs: $orderIDs)
    }
  }
`;

export const CONFIRM_D3P = gql`
  mutation confirmDeliverShippingProvider($orderIDs: [ID!]!, $imageURLs: [String!]) {
    order {
      confirmDeliverShippingProvider(orderIDs: $orderIDs, imageURLs: $imageURLs)
    }
  }
`;

export const SPLIT = gql`
  mutation split($orderID: ID!, $request: [SplitOrderInput!]) {
    order {
      split(orderID: $orderID, request: $request) {
        id
      }
    }
  }
`;

export const CONFIRM_CUSTOMER_PICKED = gql`
  mutation confirmCustomerPicked($orderIDs: [ID!]!, $imageURLs: [String!]) {
    order {
      confirmCustomerPicked(orderIDs: $orderIDs, imageURLs: $imageURLs)
    }
  }
`;

export const CLONE_CART_UPDATE_DETAIL = gql`
  mutation cloneCartUpdateDetail($orderID: ID!) {
    order {
      cloneCartUpdateDetail(orderID: $orderID) {
        id
      }
    }
  }
`;

export const CONFIRM_UPDATE_DETAIL = gql`
  mutation confirmUpdateDetail($orderID: ID!) {
    order {
      confirmUpdateDetail(orderID: $orderID)
    }
  }
`;

export const CANCEL_UPDATE_DETAIL = gql`
  mutation cancelUpdateDetail($orderID: ID!) {
    order {
      cancelUpdateDetail(orderID: $orderID)
    }
  }
`;

export const UPDATE_SHIPPER = gql`
  mutation updateShipper($orderIDs: [ID!], $shipperID: ID!) {
    order {
      updateShipper(orderIDs: $orderIDs, shipperID: $shipperID)
    }
  }
`;

export const APPROVE_ORDER = gql`
  mutation approveOrder($orderIDs: [ID!]!) {
    order {
      approveOrder(orderIDs: $orderIDs)
    }
  }
`;

export const CONFIRM_PICKED_FOR_SHIPPING = gql`
  mutation confirmPickedForShipping($orderIDs: [ID!]) {
    order {
      confirmPickedForShipping(orderIDs: $orderIDs)
    }
  }
`;

export const CREATE_ORDER_TO_SHIPPING_PROVIDER_INPUT = gql`
  mutation CreateOrderToShippingProviderInput($request: CreateOrderToShippingProviderInput) {
    order {
      createOrderToShippingProvider(request: $request)
    }
  }
`;

export const UPDATE_SHIPPING_PROVIDER = gql`
  mutation updateShippingProvider($orderID: ID!, $newShippingProviderID: ID!, $newShipperID: ID) {
    shippingProvider {
      updateShippingProvider(
        orderID: $orderID
        newShippingProviderID: $newShippingProviderID
        newShipperID: $newShipperID
      )
    }
  }
`;

export const SET_DELIVERY_DEADLINE = gql`
  mutation setDeliveryDeadline($orderID: ID!, $input: SetDeliveryDeadlineInput!) {
    order {
      setDeliveryDeadline(orderID: $orderID, input: $input)
    }
  }
`;
