import React from 'react';
import CustomTable from 'components/CustomTable';
import useCategoriesList from './useCategoriesList';
import { t } from 'i18next';
import Toolbar from './Toolbar';

function CategoriesList({ dataDetail }) {
  const { columns, newData, loading, handleSearch, onChangeParams, params } = useCategoriesList();

  return (
    <>
      <div className="class-custom">
        <div className="tag-modal">{dataDetail.fullName}</div>
        <Toolbar
          handleSearch={handleSearch}
          showButton={false}
          showFilterDate={false}
          placeholder={t('seller.categoriesList.placeHolder')}
          params={params}
          onChangeParams={onChangeParams}
        />
      </div>
      <CustomTable
        loading={loading}
        rowKey={(record) => record?.id}
        columns={columns}
        dataSource={newData}
        isLeftFooter={false}
        pagination
      />
    </>
  );
}

export default CategoriesList;
