import { TabsMenu } from 'components/CustomTabs';
import PageHeader from 'components/PageHeader';
import useList from './useList';
import './index.scss';
import Toolbar from './components/Toolbar';
import CustomTable from 'components/CustomTable';
import { RECOMMEND_IMPORT } from '../../constant';
import TableProductList from './components/TableListProduct';
import { Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { t } from 'i18next';

function ListRecommendImport() {
  const {
    params,
    handleChange,
    onChangeParams,
    data,
    statusTabs,
    loading,
    isStatusAll,
    handleSearch,
    sellersOptions,
    handleRemoveFilterTag,
    filterTags,
    setFilterTags,
    setParams,
    OPTION_OWNER_TYPE,
    renderColumns,
    OPTION_PRICE_BID_STATUS,
    handleRowClick,
    selectedRow,
    showAllRows,
    handleToggleRows,
    selectedHightline,
    sizeScrollPageAll,
    paginationData,
    GET_QUERY,
    handleChangeTable,
    dataCount,
    warehouseOptions,
    optionImportRequestType,
    staffs,
    setSelectedHightline,
    setSizeScrollPageAll,
    setSelectedRow,
    canImportRequestCreate,
    canImportRequestViewCreatedByMe,
    canImportRequestView,
  } = useList();
  const tabs = [
    {
      title: (
        <div className="title">
          {t('warehouse.recommendImport.all')} ({dataCount?.ALL || 0})
        </div>
      ),
      key: RECOMMEND_IMPORT.ALL,
    },
    {
      title: (
        <div className="title">
          {t('warehouse.recommendImport.pending')} ({dataCount?.WAITING_APPROVAL || 0})
        </div>
      ),
      key: RECOMMEND_IMPORT.WAITING_APPROVAL,
    },
    {
      title: (
        <div className="title">
          {t('warehouse.recommendImport.rejected')} ({dataCount?.REJECTED || 0})
        </div>
      ),
      key: RECOMMEND_IMPORT.REJECTED,
    },
    {
      title: (
        <div className="title">
          {t('warehouse.recommendImport.waitingBuy')} ({dataCount?.WAITING_FOR_BUYING || 0})
        </div>
      ),
      key: RECOMMEND_IMPORT.WAITING_FOR_BUYING,
    },
    {
      title: (
        <div className="title">
          {t('warehouse.recommendImport.complete')} ({dataCount?.COMPLETED || 0})
        </div>
      ),
      key: RECOMMEND_IMPORT.COMPLETED,
    },

    {
      title: (
        <div className="title">
          {t('warehouse.recommendImport.canceled')} ({dataCount?.CANCELED || 0})
        </div>
      ),
      key: RECOMMEND_IMPORT.CANCELED,
    },
  ];
  const renderTable = () => {
    return (
      <div className="table-parent">
        <CustomTable
          loading={loading}
          columns={renderColumns(statusTabs)}
          dataSource={data}
          scroll={sizeScrollPageAll}
          rowClassName={(record, index) => (index === selectedHightline ? 'highlight' : '')}
          onRow={(record, index) => ({
            onClick: () => handleRowClick(record, index),
          })}
          pagination={{
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }}
          onChange={handleChangeTable}
        />
      </div>
    );
  };
  const renderSelectedRowTable = () => {
    if (!selectedRow) {
      return null;
    }
    return (
      <div>
        <div className="show-hidden">
          <Button onClick={handleToggleRows}>
            {showAllRows ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </Button>
        </div>
        {!showAllRows && <TableProductList selectedRow={selectedRow} GET_QUERY={GET_QUERY} />}
      </div>
    );
  };
  return (
    <div className="list-recommend-import">
      <PageHeader
        pageTitle={t('warehouse.recommendImport.listrecomendImport')}
        routes={[
          { path: '/warehouse-list', name: t('warehouse.recommendImport.buy') },
          { path: '/warehouse-list', name: t('warehouse.recommendImport.recommendImport') },
          { path: '/', name: t('warehouse.recommendImport.listrecomendImport') },
        ]}
      />
      <div className="campaign-container">
        <TabsMenu defaultActiveKey={statusTabs} tabs={tabs} onChange={handleChange} />
        <Toolbar
          handleSearch={handleSearch}
          OPTION_OWNER_TYPE={OPTION_OWNER_TYPE}
          handleRemoveFilterTag={handleRemoveFilterTag}
          setFilterTags={setFilterTags}
          filterTags={filterTags}
          setParams={setParams}
          sellersOptions={sellersOptions}
          isStatusAll={isStatusAll}
          params={params}
          onChangeParams={onChangeParams}
          statusTabs={statusTabs}
          OPTION_PRICE_BID_STATUS={OPTION_PRICE_BID_STATUS}
          warehouseOptions={warehouseOptions}
          optionImportRequestType={optionImportRequestType}
          staffs={staffs}
          setSelectedHightline={setSelectedHightline}
          setSizeScrollPageAll={setSizeScrollPageAll}
          setSelectedRow={setSelectedRow}
          canImportRequestCreate={canImportRequestCreate}
          canImportRequestViewCreatedByMe={canImportRequestViewCreatedByMe}
          canImportRequestView={canImportRequestView}
        />
        <div className="table-list-recommend-import">
          {renderTable()} {renderSelectedRowTable()}
        </div>
      </div>
    </div>
  );
}

export default ListRecommendImport;
