import { InsertRowBelowOutlined } from '@ant-design/icons';
import RecommendImport from './RecommendImport';
import CreateRecommendImport from 'features/Warehouse/RecommendImport/Create';
import DetailRecommendImport from 'features/Warehouse/RecommendImport/Detail';
import EditRecommendImport from 'features/Warehouse/RecommendImport/Edit';
import SalesReturn from './SalesReturn';
import CreateSalesReturn from 'features/Warehouse/SalesReturn/Create';
import DetailSalesReturn from 'features/Warehouse/SalesReturn/Detail';
import EditSalesReturn from 'features/Warehouse/SalesReturn/Edit';

const router = [
  {
    key: 'warehouse',
    title: 'warehouse.recommendImport.warehouse',
    icon: <InsertRowBelowOutlined />,
    children: [
      {
        key: 'recommendImport',
        title: 'warehouse.recommendImport.recommendImport',
        path: '/warehouse-list',
        exact: true,
        component: RecommendImport,
      },
      {
        key: 'salesReturn',
        title: 'warehouse.salesReturn.salesReturn',
        path: '/sales-return',
        exact: true,
        component: SalesReturn,
      },
    ],
  },
  {
    key: 'recommendImport-create',
    path: '/recommendImport/create',
    component: CreateRecommendImport,
    exact: true,
  },
  {
    key: 'detail-recommendImport',
    path: '/recommendImport/detail/:id',
    component: DetailRecommendImport,
    exact: true,
  },
  {
    key: 'edit-recommendImport',
    path: '/recommendImport/edit/:id',
    component: EditRecommendImport,
    exact: true,
  },
  {
    key: 'create-salesReturn',
    path: '/salesReturn/create',
    component: CreateSalesReturn,
    exact: true,
  },
  {
    key: 'detail-salesReturn',
    path: '/salesReturn/detail/:id',
    component: DetailSalesReturn,
    exact: true,
  },
  {
    key: 'edit-salesReturn',
    path: '/salesReturn/edit/:id',
    component: EditSalesReturn,
    exact: true,
  },
];

export default router;
