import { STATUS_ORDER } from "config/constants";
import { t } from "i18next";
import { ORDER_STATUS } from "pages/App/Order/conts";
import i18n from "locales/i18n";
import { buildQueryString } from "utils/helperFuncs";
import { omit } from "lodash";

export const PAYMENT_METHOD = {
  DEBT: "DEBT",
  WALLET: "WALLET",
  CASH: "CASH",
  CashAndDebt: "CASH_AND_DEBT",
  COD: "COD",
};

export const COMPANY_TYPE = {
  COMPANY: "COMPANY",
  PERSONAL: "PERSONAL",
};

export const IS_USED_APP = {
  USED: "USED",
  INVITED: "INVITED",
  UNINVITED: "UNINVITED",
};

export const ACTIVITY_TABS = {
  ACTIVE_HISTORY: "ACTIVE_HISTORY",
  BUY_HISTORY: "BUY_HISTORY",
  BOOK_ADDRESS: "BOOK_ADDRESS",
};

export const convertPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case PAYMENT_METHOD.CASH:
      return t("order.paymentMethods.cash");
    case PAYMENT_METHOD.DEBT:
      return t("order.paymentMethods.debt");
    case PAYMENT_METHOD.WALLET:
      return t("order.paymentMethods.wallet");
    default:
      return;
  }
};

export const convertTypeAgent = (typeAgent) => {
  switch (typeAgent) {
    case COMPANY_TYPE.COMPANY:
      return t("seller.company");
    case COMPANY_TYPE.PERSONAL:
      return t("seller.personal");
    default:
      return;
  }
};
export const renderPaymentMethod = (method) => {
  switch (method) {
    case PAYMENT_METHOD.CASH:
      return t("order.orderList.cash");
    case PAYMENT_METHOD.CashAndDebt:
      return t("order.orderList.cashAndDebit");
    case PAYMENT_METHOD.COD:
      return t("order.orderList.COD");
    case PAYMENT_METHOD.DEBT:
      return t("order.orderList.debt");
    default:
      return;
  }
};

export const convertOrderStatus = (status) => {
  switch (status) {
    case ORDER_STATUS.WAITING_FOR_APPROVAL_STOCK:
      return <span style={{ color: "#EF9351" }}>{t("order.orderStatus.waitingForApprovalStock")}</span>;
    case ORDER_STATUS.WAITING_FOR_ENOUGH_STOCK:
      return <span style={{ color: "#EF9351" }}>{t("order.orderStatus.waitingForEnoughStock")}</span>;
    case ORDER_STATUS.WAITING_FOR_PAYMENT:
      return <span style={{ color: "#EF9351" }}>{t("order.orderStatus.waitingForPayment")}</span>;
    case ORDER_STATUS.WAITING_FOR_PACKAGING:
      return <span style={{ color: "#EF9351" }}>{t("order.orderStatus.waitingForPackaging")}</span>;
    case ORDER_STATUS.WAITING_FOR_EXPORTING:
      return <span style={{ color: "#EF9351" }}>{t("order.orderStatus.waitingForExporting")}</span>;
    case ORDER_STATUS.WAITING_FOR_SHIPPING:
      return <span style={{ color: "#EF9351" }}>{t("order.orderStatus.waitingForDelivery")}</span>;
    case ORDER_STATUS.DELIVERING:
      return <span style={{ color: "#EF9351" }}>{t("order.orderStatus.delivering")}</span>;
    case ORDER_STATUS.COMPLETED:
      return <span className="order-completed">{t("order.orderStatus.completed")}</span>;
    case ORDER_STATUS.CANCELED:
      return <span className="order-cancel">{t("order.orderStatus.cancel")}</span>;
    default:
      return "";
  }
};

export const OrderSummary = ({ all, completed, processing, cancel }) => {
  return (
    <div className="parent no-row">
      <span className="text-bold">{t("seller.activitylogtabs.table.orderTotal")}</span>
      <span className="">{all}</span>
      <span className="text-bold green">{t("seller.activitylogtabs.table.orderComplete")}</span>
      <span className="green">{completed}</span>
      <span className="text-bold yellow">{t("seller.activitylogtabs.table.orderProcessing")}</span>
      <span className="yellow">{processing}</span>
      <span className="text-bold red">{t("seller.activitylogtabs.table.orderCancel")}</span>
      <span className="red">{cancel}</span>
    </div>
  );
};

export const orderStatusOptions = [
  {
    label: i18n.t("order.orderStatus.waitingForApprovalStock"),
    value: STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK,
  },
  {
    label: i18n.t("order.orderStatus.waitingForEnoughStock"),
    value: STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK,
  },
  { label: i18n.t("order.orderStatus.waitingForPayment"), value: STATUS_ORDER.WAITING_FOR_PAYMENT },
  {
    label: i18n.t("order.orderStatus.waitingForExporting"),
    value: STATUS_ORDER.WAITING_FOR_EXPORTING,
  },
  {
    label: i18n.t("order.orderStatus.waitingForAssembly"),
    value: STATUS_ORDER.WAITING_FOR_ASSEMBLY,
  },
  {
    label: i18n.t("order.orderStatus.waitingForDelivery"),
    value: STATUS_ORDER.WAITING_FOR_SHIPPING,
  },
  { label: i18n.t("order.orderStatus.delivering"), value: STATUS_ORDER.DELIVERING },
  { label: i18n.t("order.orderStatus.completed"), value: STATUS_ORDER.COMPLETED },
  { label: i18n.t("order.orderStatus.cancel"), value: STATUS_ORDER.CANCELED },
  {
    label: i18n.t("order.orderStatus.waitingForPackaging"),
    value: STATUS_ORDER.WAITING_FOR_PACKAGING,
  },
];

export const paymentMethodOptions = [
  { value: PAYMENT_METHOD.CASH, label: i18n.t("order.orderList.cash") },
  { value: PAYMENT_METHOD.CashAndDebt, label: i18n.t("order.orderList.cashAndDebit") },
  { value: PAYMENT_METHOD.COD, label: i18n.t("order.orderList.COD") },
  { value: PAYMENT_METHOD.DEBT, label: i18n.t("order.orderList.debt") },
];

export const ACTION_ACTIVITY_LOGS = {
  ACTIVATE_ACCOUNT: "ACTIVATE_ACCOUNT",
  DEACTIVATE_ACCOUNT: "DEACTIVATE_ACCOUNT",
  UPDATE_INFO: "UPDATE_INFO",
};

export const convertActionActivityLogs = (action) => {
  switch (action) {
    case ACTION_ACTIVITY_LOGS.ACTIVATE_ACCOUNT:
      return "seller.activitylogtabs.table.activityAccount";
    case ACTION_ACTIVITY_LOGS.DEACTIVATE_ACCOUNT:
      return "seller.activitylogtabs.table.deactivityAccount";
    case ACTION_ACTIVITY_LOGS.UPDATE_INFO:
      return "seller.activitylogtabs.table.updateInfo";
    default:
      return;
  }
};

export const actionActivityLogsOptions = [
  {
    label: i18n.t("seller.activitylogtabs.table.activityAccount"),
    value: ACTION_ACTIVITY_LOGS.ACTIVATE_ACCOUNT,
  },
  {
    label: i18n.t("seller.activitylogtabs.table.deactivityAccount"),
    value: ACTION_ACTIVITY_LOGS.DEACTIVATE_ACCOUNT,
  },
  {
    label: i18n.t("seller.activitylogtabs.table.updateInfo"),
    value: ACTION_ACTIVITY_LOGS.UPDATE_INFO,
  },
];

export const convertStatus = (status) => {
  return status ? (
    <span style={{ color: "#00AB78" }}>{t("seller.cooperation")}</span>
  ) : (
    <span style={{ color: "#D2434D" }}>{t("seller.stopCooperation")}</span>
  );
};

export const statusOptions = [
  {
    label: i18n.t("seller.cooperation"),
    value: true,
  },
  {
    label: i18n.t("seller.stopCooperation"),
    value: false,
  },
];

export const formatBuildQueryString = (values) => {
  buildQueryString({
    params: {
      ...values?.filters,
      ...omit(values?.filters, "timeRange"),
      offset: values?.pagination?.offset,
      limit: values?.pagination?.limit,
      from: values?.filters?.timeRange?.from,
      to: values?.filters?.timeRange?.to,
    },
  });
};
