/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useGetDetailImportRequest } from 'hooks/recommendimport';
import { useWarehouse } from 'hooks/warehouse';
import { useGetSellerCreateCart } from 'hooks/seller';
import { CURRENCY_INPUT, WARRENTY_PERIOD } from 'features/Warehouse/constant';
import { v4 as uuidv4 } from 'uuid';
import { uniqBy } from 'lodash';
import { notify } from 'utils/helperFuncs';
import { t } from 'i18next';
import { useUpdateImportRequest } from 'hooks/warehouse/mutation';
import moment from 'moment';

function useList() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const formData = form.getFieldsValue();
  const [sumPrice, setSumPrice] = useState(0);

  const [currencyFirst, setCurrencyFirst] = useState(0);
  const [dataTableProduct, setDataTableProduct] = useState([]);

  const [paramsWarehouse] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });
  const { warehouses } = useWarehouse(paramsWarehouse);
  const valueImportWarehouse = warehouses?.map(({ id, name }) => ({ value: id, label: name }));

  const { data, loading } = useGetDetailImportRequest({ id: id });

  const [sellerInfo, setSellerInfo] = useState(null);

  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null, isActive: true },
    pagination: { offset: 0, limit: 10 },
  });
  const {
    sellers,
    loadMoreData: loadMoreSeller,
    hasMoreData: hasMoreSeller,
  } = useGetSellerCreateCart(paramsSeller, 'cache-first');
  const handleChangeInfo = (value, option) => {
    setSellerInfo(option?.seller);
  };
  const onSearchSeller = (value) => {
    setParamsSeller({
      filters: { ...paramsSeller.filters, query: value || null },
      pagination: { offset: 0, limit: 10 },
    });
  };
  const images = data?.fileURLs?.map((url) => {
    return {
      url: url,
    };
  });

  const initialValues = {
    ...data,
    type: data?.type,
    warehouseID: data?.warehouse?.id,
    supplierID: data?.supplier?.fullName,
    items: data?.items?.map((item) => ({
      id: item?.id,
      creditDays: item?.creditDays,
      currency: item?.currency,
      expectedDeliveryTime: item?.expectedDeliveryTime ? moment(item?.expectedDeliveryTime) : null,
      isVat: item?.isVat,
      paymentNote: item?.paymentNote,
      price: item?.price,
      product: {
        id: item?.product?.id,
        code: item?.product?.code,
        name: item?.product?.name,
      },
      importReason: item?.importReason,
      productID: item?.product?.id,
      productName: item?.productName,
      quantity: item?.quantity,
      warranty: item?.warranty,
      warrantyPeriod: item?.warrantyPeriod,
    })),
    note: data?.note,
    fileURLs: images?.length > 0 && images[0].url?.length > 0 ? images : [],
    id: data?.id,
  };
  const handleGetProduct = (data) => {
    const formData = form.getFieldsValue();
    const newData = {
      productID: data?.id,
      productName: data?.name,
      productCode: data?.code,
      price: null,
      currency: CURRENCY_INPUT?.VND,
      warranty: null,
      warrantyPeriod: WARRENTY_PERIOD?.MONTH,
      isVat: false,
      creditDays: null,
      paymentNote: null,
    };
    form.setFieldsValue({
      ...formData,
      items: uniqBy([...formData.items, newData], 'productID'),
    });
    setDataTableProduct(uniqBy([...dataTableProduct, newData], 'productID'));
  };

  const handleAddProduct = (values) => {
    const formData = form.getFieldsValue();
    const idcheck = uuidv4();
    const newData = {
      isAdd: true,
      productID: idcheck,
      productName: values?.name,
      quantity: values?.quantity || null,
      price: values?.price || null,
      currency: values?.currency,
      warranty: values?.warranty || null,
      warrantyPeriod: values?.warrantyPeriod,
      isVat: values?.isVat,
      creditDays: values?.creditDays || null,
      paymentNote: values?.paymentNote,
      expectedDeliveryTime: values?.expectedDeliveryTime,
    };
    form.setFieldsValue({
      ...formData,
      items: [...formData.items, newData],
    });
    setDataTableProduct(
      uniqBy(
        [...dataTableProduct, { ...values, productName: values?.name, productID: idcheck }],
        'productID'
      )
    );
  };
  const idItemsOld = data?.items?.map((item) => item?.id);

  const [deletedIDs, setdeletedIDs] = useState([]);
  const handleDeleteProduct = (productID, id) => {
    if (idItemsOld.includes(id)) {
      let ids = [...deletedIDs, id];
      setdeletedIDs(ids);
    }
    const formData = form.getFieldsValue();
    const dataFormDelete = formData?.items?.filter((item) => item.productID !== productID);
    form.setFieldsValue({
      ...formData,
      items: dataFormDelete,
    });
    const dataTableDelete = dataTableProduct?.filter((item) => item.productID !== productID);
    setDataTableProduct(dataTableDelete);
  };

  const passwordInputRef = useRef(null);
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      passwordInputRef.current.blur();
    }
  };
  const { handleUpdateImportReques } = useUpdateImportRequest();

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const handleSubmitCreate = async () => {
    await form.validateFields().then(async (values) => {
      setLoadingUpdate(true);
      const arrayWithUndefinedId = values?.items?.filter((item) => item.id === undefined);
      const arrayWithNonNullId = values?.items?.filter((item) => item.id !== undefined);
      const params = {
        supplierID: sellerInfo?.id,
        warehouseID: values?.warehouseID,
        type: values?.type,
        paymentNotes: values?.paymentNotes,
        paymentTerms: values?.paymentTerms,
        paymentMethods: values?.paymentMethods,
        note: values?.note,
        fileURLs: values?.fileURLs,
        isResubmit: false,
        deletedIDs: deletedIDs,
        newItems: arrayWithUndefinedId?.map((item, i) => {
          return {
            id: null,
            productID: item?.isAdd ? undefined : item?.productID,
            productName: item?.productName,
            quantity: item?.quantity,
            price: item?.price,
            currency: item?.currency,
            warranty: item?.warranty,
            warrantyPeriod: item?.warrantyPeriod,
            isVat: item?.isVat,
            creditDays: item?.creditDays,
            paymentNote: item?.paymentNote,
            expectedDeliveryTime: item?.expectedDeliveryTime,
          };
        }),
        updatedItems: arrayWithNonNullId?.map((item, i) => {
          return {
            id: item?.id,
            productID: item?.isAdd ? undefined : item?.productID,
            productName: item?.productName,
            quantity: item?.quantity,
            price: item?.price,
            currency: item?.currency,
            warranty: item?.warranty,
            warrantyPeriod: item?.warrantyPeriod,
            isVat: item?.isVat,
            creditDays: item?.creditDays,
            paymentNote: item?.paymentNote,
            expectedDeliveryTime: item?.expectedDeliveryTime,
          };
        }),
      };
      try {
        if (values?.items?.length > 0) {
          await handleUpdateImportReques({ id, request: params })
            .then(() => {
              notify.success({ message: t('warrantyRequest.update.updateSuccess') });
              history.push(`/recommendImport/detail/${id}`);
            })
            .catch((error) => {
              notify.error({
                message: error.message,
              });
            });
        } else {
          notify.error({
            message: t('warehouse.create.PleaseChooseProduct'),
          });
        }
      } catch (error) {
        console.info(error);
      } finally {
        setLoadingUpdate(false);
      }
    });
  };

  const [loadingUpdateAndSave, setLoadingUpdateAndSave] = useState(false);
  const handleSubmitSaveAndSendRequest = async () => {
    await form.validateFields().then(async (values) => {
      setLoadingUpdateAndSave(true);
      const arrayWithUndefinedId = values?.items?.filter((item) => item.id === undefined);
      const arrayWithNonNullId = values?.items?.filter((item) => item.id !== undefined);
      const params = {
        supplierID: sellerInfo?.id,
        warehouseID: values?.warehouseID,
        type: values?.type,
        paymentNotes: values?.paymentNotes,
        paymentTerms: values?.paymentTerms,
        paymentMethods: values?.paymentMethods,
        note: values?.note,
        fileURLs: values?.fileURLs,
        isResubmit: true,
        deletedIDs: deletedIDs,
        newItems: arrayWithUndefinedId?.map((item, i) => {
          return {
            id: null,
            productID: item?.isAdd ? undefined : item?.productID,
            productName: item?.productName,
            quantity: item?.quantity,
            price: item?.price,
            currency: item?.currency,
            warranty: item?.warranty,
            warrantyPeriod: item?.warrantyPeriod,
            isVat: item?.isVat,
            creditDays: item?.creditDays,
            paymentNote: item?.paymentNote,
            expectedDeliveryTime: item?.expectedDeliveryTime,
          };
        }),
        updatedItems: arrayWithNonNullId?.map((item, i) => {
          return {
            id: item?.id,
            productID: item?.isAdd ? undefined : item?.productID,
            productName: item?.productName,
            quantity: item?.quantity,
            price: item?.price,
            currency: item?.currency,
            warranty: item?.warranty,
            warrantyPeriod: item?.warrantyPeriod,
            isVat: item?.isVat,
            creditDays: item?.creditDays,
            paymentNote: item?.paymentNote,
            expectedDeliveryTime: item?.expectedDeliveryTime,
          };
        }),
      };
      try {
        if (values?.items?.length > 0) {
          await handleUpdateImportReques({ id, request: params })
            .then(() => {
              notify.success({ message: t('warrantyRequest.update.updateandesend') });
              history.push('/warehouse-list');
            })
            .catch((error) => {
              notify.error({
                message: error.message,
              });
            });
        } else {
          notify.error({
            message: t('warehouse.create.PleaseChooseProduct'),
          });
        }
      } catch (error) {
        console.info(error);
      } finally {
        setLoadingUpdateAndSave(false);
      }
    });
  };
  useEffect(() => {
    form.setFieldsValue({ ...initialValues });
    setSellerInfo(data?.supplier);
    setDataTableProduct(initialValues?.items);
  }, [data]);
  return {
    loading,
    form,
    initialValues,
    data,
    valueImportWarehouse,
    sellers,
    loadMoreSeller,
    hasMoreSeller,
    handleChangeInfo,
    sellerInfo,
    onSearchSeller,
    handleSubmitCreate,
    formData,
    sumPrice,
    setSumPrice,
    handleKeyPress,
    dataTableProduct,
    setCurrencyFirst,
    currencyFirst,
    handleDeleteProduct,
    handleGetProduct,
    handleAddProduct,
    loadingUpdate,
    handleSubmitSaveAndSendRequest,
    loadingUpdateAndSave,
  };
}

export default useList;
