import React, { useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { SvgIcon } from "assets/icons";
import { useUpload } from "hooks/upload";
import "react-quill/dist/quill.snow.css";
import { notify } from "utils/helperFuncs";
import { t } from "i18next";
import "./index.scss";

const dataURLtoFile = (dataURL, filename) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const QuillEditor = ({
  placeholder,
  onEditorChange,
  onFilesChange,
  value = "", //ant form item
  onChange, //ant form item
  disabledInput,
}) => {
  const reactQuillRef = useRef(false);
  const inputOpenImageRef = useRef(null);
  // const inputOpenVideoRef = useRef();
  // const inputOpenFileRef = useRef();
  const [editorHtml, setEditorHtml] = useState("");
  const { handleUpload } = useUpload();
  const [files, setFiles] = useState([]);

  /*inspired code:
    https://ant.design/components/form/#components-form-demo-customized-form-controls
  */
  const triggerChange = (changedValue) => {
    onChange?.(changedValue);
  };

  const uploadImage = async (values) => {
    const handleImageUpload = async (item) => {
      try {
        const result = await handleUpload({ file: item });
        return result;
      } catch (error) {
        notify.error({
          message: t("uploadDocument.uploadImageError"),
          description: error?.message,
        });
        return null;
      }
    };

    const handleImageArray = async (items) => {
      return await Promise.all(
        items.map(async (item) => {
          try {
            return item?.url || (await handleImageUpload(item));
          } catch (error) {
            notify.error({
              message: t("uploadDocument.uploadImageError"),
              description: error?.message,
            });
            return null;
          }
        })
      );
    };
    const newImages = await handleImageArray(values || []);

    return newImages;
  };

  const handleChange = async (html) => {
    const regex = /<img\s+src="data:image\/png;base64[^"]*"/gi;
    const matches = html?.match(regex);

    if (matches) {
      const srcValues = matches.map((match) => match.match(/src="([^"]*)/)[1]);
      const newFiles = srcValues?.map((item) => dataURLtoFile(item, "img-clipboard.png"));
      const newURLs = await uploadImage(newFiles);
      newURLs.forEach((newURL, index) => {
        html = html.replace(srcValues[index], `${newURL}`);
      });
    }
    if (!value) {
      setEditorHtml(html);
    }
    onEditorChange && onEditorChange(html);
    triggerChange(html);
  };

  const insertImage = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.currentTarget && e.currentTarget.files && e.currentTarget.files.length > 0) {
      const file = e.currentTarget.files[0];
      try {
        await handleUpload({ file: file }).then((res) => {
          if (res) {
            const quill = reactQuillRef.current.getEditor();
            quill.focus();
            let range = quill.getSelection();
            let position = range ? range.index : 0;
            quill.insertEmbed(position, "image", res);
            quill.setSelection(position + 1);
            if (reactQuillRef.current.__isMounted) {
              setFiles([...files, res]);
              onFilesChange && onFilesChange([...files, res]);
            }
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const imageHandler = () => {
    inputOpenImageRef.current.click();
  };

  // const videoHandler = () => {
  //   inputOpenVideoRef.current.click();
  // };

  // const fileHandler = () => {
  //   inputOpenFileRef.current.click();
  // };

  // useEffect(() => {
  //   onEditorChange(editorHtml);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [editorHtml]);

  // useEffect(() => {
  //   onFilesChange(files);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [files]);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "image",
    "link",
    "code-block",
    "video",
    "blockquote",
    "clean",
    "color",
    "align",
    "list",
  ];

  const modules = useMemo(
    () => ({
      syntax: false,
      toolbar: {
        container: "#toolbar",
        //id ="toorbar"는  그 위에 B I U S I V F P 이거 있는 곳이다.
        handlers: {
          image: imageHandler,
          // insertVideo: videoHandler,
          // insertFile: fileHandler,
        },
      },
    }),
    []
  );

  return (
    <div className="custom-markdown-editor">
      <div id="toolbar">
        <select className="ql-header" defaultValue={""} onChange={(e) => e.persist()}>
          <option value="1" />
          <option value="2" />
          <option value="" />
        </select>
        <select className="ql-align"></select>
        <select className="ql-color"></select>

        <span className="ql-formats">
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
        </span>

        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
        <button className="ql-link" />
        <button className="ql-image">
          <SvgIcon.ImageIcon />
        </button>
        <button className="ql-video" />
        <button className="ql-blockquote" />
        <button className="ql-clean" />
      </div>
      <ReactQuill
        ref={reactQuillRef}
        theme={"snow"}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        value={value || editorHtml}
        onChange={handleChange}
        readOnly={disabledInput}
      />
      <input
        type="file"
        accept="image/*"
        ref={inputOpenImageRef}
        style={{ display: "none" }}
        onChange={insertImage}
        disabled={disabledInput}
      />
      <input type="file" accept="image/*" ref={inputOpenImageRef} style={{ display: "none" }} onChange={insertImage} />
    </div>
  );
};

export default QuillEditor;
