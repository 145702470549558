import React from "react";
import { SelectLoadMore } from "components/CustomSelect/index";
import useSelectSeller from "./useSelectSeller";
import { t } from "i18next";
import { Col, Row, Spin, Typography } from "antd";
import { compact } from "lodash";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";

const SelectSeller = ({ handleUpdateSeller, refetchCart, onGetWarehouses, warehouses, isModal, isPadding, style }) => {
  const { options, onLoadMoreData, hasMoreData, onSearch, onChange, warehouseOptions, loading } = useSelectSeller({
    handleUpdateSeller,
    refetchCart,
    onGetWarehouses,
    warehouses,
  });

  const sellerOptions = options?.map((item) => ({
    value: item?.id,
    label: (
      <Row gutter={16}>
        <Col span={3}>
          <Typography.Text ellipsis style={{ color: "#2246DC" }}>
            {item?.code}
          </Typography.Text>
        </Col>
        <Col span={9}>
          <Typography.Text ellipsis>{item?.fullName}</Typography.Text>
        </Col>
        <Col span={3}>
          <Typography.Text ellipsis>{item?.telephone}</Typography.Text>
        </Col>
        <Col span={9}>
          <Typography.Text ellipsis>
            {compact([item?.address, item?.ward?.name, item?.district?.name, item?.city?.name]).join(" ,")}
          </Typography.Text>
        </Col>
      </Row>
    ),
    warehouses: item?.warehouses,
    labelSeller: [item?.code, item?.fullName, item?.telephone].join(" - "),
  }));

  return (
    <>
      <SelectLoadMore
        formItemOptions={{
          label: <Typography.Text strong>{t("cart.create.supplier")}</Typography.Text>,
          name: "sellerID",
          rules: [
            {
              required: true,
              message: t("cart.create.sellerValidate"),
            },
          ],
          labelCol: { span: !isPadding && 6 },
          wrapperCol: { span: 18 },
          style: style,
        }}
        inputOptions={{
          placeholder: t("cart.create.sellerPlaceholder"),
          dropdownMatchSelectWidth: 1100,
          dropdownRender: (originNode) => (
            <Spin spinning={loading}>
              <Row gutter="16" style={{ background: "#F7F8FB" }}>
                <Col
                  span={3}
                  style={{
                    textAlign: "center",
                    padding: "12px 8px",
                    color: "#5B6673",
                    fontWeight: "bold",
                  }}
                >
                  <Typography.Text>{t("cart.agentCode")}</Typography.Text>
                </Col>
                <Col
                  span={9}
                  style={{
                    textAlign: "center",
                    padding: "12px 8px",
                    color: "#5B6673",
                    fontWeight: "bold",
                  }}
                >
                  <Typography.Text>{t("cart.agentName")}</Typography.Text>
                </Col>
                <Col
                  span={3}
                  style={{
                    textAlign: "center",
                    padding: "12px 8px",
                    color: "#5B6673",
                    fontWeight: "bold",
                  }}
                >
                  <Typography.Text>{t("cart.telephone")}</Typography.Text>
                </Col>

                <Col
                  span={9}
                  style={{
                    textAlign: "center",
                    padding: "12px 8px",
                    color: "#5B6673",
                    fontWeight: "bold",
                  }}
                >
                  <Typography.Text ellipsis>ĐỊA CHỈ</Typography.Text>
                </Col>
              </Row>
              {originNode}
            </Spin>
          ),

          optionLabelProp: "labelSeller",
          options: sellerOptions,
          onSelect: (value, options) => onChange(value, options),
        }}
        onLoadMore={onLoadMoreData}
        hasMoreData={hasMoreData}
        onSearch={onSearch}
      />
      {isModal && (
        <FormInput
          type={INPUT_TYPE.SELECT}
          formItemOptions={{
            label: <b>{t("cart.exportWarehouseLabel")}</b>,
            style: { width: "100%" },
            name: "warehouseID",
            rules: [{ required: true, message: t("cart.requiredWarehouse") }],
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
          }}
          inputOptions={{
            options: warehouseOptions,
            placeholder: t("cart.warehousePlaceholder"),
          }}
        />
      )}
    </>
  );
};

export default SelectSeller;
