import { useGetSellerCreateCart } from "hooks/seller";
import { useState } from "react";

const useSelectSeller = ({ handleUpdateSeller, refetchCart, onGetWarehouses, warehouses }) => {
  const [params, setParams] = useState({
    filters: {
      query: undefined,
      isActive: true,
      isSeller: true,
    },
    pagination: { offset: 0, limit: 10 },
  });

  const { onLoadMoreData, hasMoreData, data, loading } = useGetSellerCreateCart({
    ...params,
  });

  const onSearch = (value) =>
    setParams({
      filters: { ...params.filters, query: value || undefined },
      pagination: { offset: 0, limit: 10 },
    });

  const warehouseOptions = warehouses?.map(({ id, name, branch }) => ({
    value: id,
    label: `${branch?.name} - ${name}`,
  }));

  const onChange = (value, options) => {
    onGetWarehouses && onGetWarehouses(options?.warehouses);
    handleUpdateSeller && handleUpdateSeller({ newSellerID: value });
    setParams({
      ...params,
      filters: { ...params.filters, query: undefined },
      pagination: { offset: 0, limit: 10 },
    });
  };

  return { options: data, onLoadMoreData, hasMoreData, onSearch, onChange, warehouseOptions, loading };
};

export default useSelectSeller;
