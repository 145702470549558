import { TabsMenu } from 'components/CustomTabs';
import React from 'react';
import styles from './styles.module.scss';

const TabsCard = ({ tabs, onChange, ...rest }) => {
  return (
    <TabsMenu
      type="card"
      tabs={tabs}
      tabBarStyle={{ borderRadius: '8px' }}
      tabBarGutter={10}
      moreIcon={null}
      className={styles['tabs-card']}
      onChange={onChange}
      {...rest}
    />
  );
};

export default TabsCard;
