import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import Card from "features/Cart/components/Card";
import { ORDER_PRIORITY } from "features/Cart/constants";
import { renderOrderPriority, renderShippingType } from "features/Order/helper";
import { t } from "i18next";
import { compact, isEmpty, join } from "lodash";
import { formatDateTime } from "utils/dateTime";
import AdditionalDeliver from "../AdditionalDeliver";
import InfoReceiverAndSender from "../InfoReceiverAndSender";
import SelectSeller from "../SelectSeller";
import "./index.scss";
import useInfoAgent from "./useInfoAgent";
import moment from "moment";

const InfoAgent = ({
  data,
  handleUpdate,
  handleUpdateSeller,
  loadingUpdateCart,
  refetchCart,
  useInOrder,
  idCart,
  form,
  dataOrder,
  dataShippingProvider,
  loadingShippingProvider,
}) => {
  const { orderTypeOptions, infoReceiverAndSender, shippingTypeOptions, orderPriorityOptions, onChange, onChangeDate } =
    useInfoAgent({ data, handleUpdate, refetchCart });
  const warehouseOptions = data?.seller?.warehouses?.map(({ id, name, branch }) => ({
    value: id,
    label: `${branch?.name} - ${name}`,
  }));

  const { shippingType } = data || {};

  const renderOrderPriorityLabel = {
    [`${ORDER_PRIORITY.LOW}`]: (
      <Typography.Text strong style={{ color: "#052987" }}>
        {renderOrderPriority[data?.orderPriority]}
      </Typography.Text>
    ),
    [`${ORDER_PRIORITY.MEDIUM}`]: (
      <Typography.Text strong style={{ color: "#00AB78" }}>
        {renderOrderPriority[data?.orderPriority]}
      </Typography.Text>
    ),
    [`${ORDER_PRIORITY.HIGH}`]: (
      <Typography.Text strong style={{ color: "#D2434D" }}>
        {renderOrderPriority[data?.orderPriority]}
      </Typography.Text>
    ),
  };

  return (
    <Card
      title={
        <Typography.Text strong style={{ color: "#2246DC" }}>
          {t("cart.infoAgent")}
        </Typography.Text>
      }
    >
      <Row gutter={16} className="cart-form-controller">
        <Col span={12} className="left">
          <Row gutter={16}>
            <Col span={16}>
              {isEmpty(useInOrder) ? (
                <SelectSeller handleUpdateSeller={handleUpdateSeller} refetchCart={refetchCart} isPadding={true} />
              ) : (
                <Typography.Text strong style={{ color: "#052987" }}>
                  {join(compact([data?.seller?.fullName, data?.seller?.telephone]), " | ")}
                </Typography.Text>
              )}
            </Col>

            <Col span={8}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                inputOptions={{
                  options: orderTypeOptions,
                  style: { width: "100%" },
                  onChange: (value) => onChange({ orderType: value }),
                }}
                formItemOptions={{ name: "orderType" }}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[16, 8]}>
                {infoReceiverAndSender.map((item, index) => (
                  <Col span={24} key={index}>
                    <InfoReceiverAndSender
                      infoUser={item}
                      loadingUpdateCart={loadingUpdateCart}
                      refetch={refetchCart}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            {isEmpty(useInOrder) || useInOrder?.changeShippingFee ? (
              <Col span={24}>
                <FormInput
                  type={INPUT_TYPE.RADIO_GROUP}
                  formItemOptions={{
                    label: <b>{t("cart.deliveryMethodLabel")}</b>,
                    style: { width: "100%" },
                    name: "shippingType",
                  }}
                  inputOptions={{
                    options: shippingTypeOptions,
                    onChange: (value) => onChange({ shippingType: value?.target?.value }),
                  }}
                />
              </Col>
            ) : (
              <>
                <Col span={6}>
                  <Typography.Text strong>{t("cart.deliveryMethodLabel")}</Typography.Text>
                </Col>
                <Col span={6}>
                  <Typography.Text strong style={{ color: "#052987" }}>
                    {renderShippingType[data?.shippingType]}
                  </Typography.Text>
                </Col>
                <Col span={12}></Col>
              </>
            )}
            <AdditionalDeliver
              dataOrder={dataOrder}
              shippingType={shippingType}
              handleUpdate={handleUpdate}
              refetchCart={refetchCart}
              useInOrder={useInOrder}
              dataShippingProvider={dataShippingProvider}
              form={form}
              loadingShippingProvider={loadingShippingProvider}
            />
          </Row>
        </Col>

        <Col span={6} className="center">
          {isEmpty(useInOrder) ? (
            <>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <b>{t("cart.exportWarehouseLabel")}</b>,
                  style: { width: "100%" },
                  name: ["exportedWarehouse", "id"],
                  rules: [{ required: true, message: t("cart.requiredWarehouse") }],
                }}
                inputOptions={{
                  options: warehouseOptions,
                  placeholder: t("cart.warehousePlaceholder"),
                  onChange: (value) => onChange({ exportedWarehouseID: value }),
                }}
              />
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  label: <b>{t("cart.orderPriorityLabel")}</b>,
                  style: { width: "100%" },
                  name: "orderPriority",
                }}
                inputOptions={{
                  options: orderPriorityOptions,
                  onChange: (value) => onChange({ orderPriority: value }),
                }}
              />

              <FormInput
                type={INPUT_TYPE.DATE_PICKER}
                formItemOptions={{
                  name: "deliveryDeadline",
                  label: <b>{t("cart.promisedDeliverTimeLabel")}</b>,
                  style: { width: "100%" },
                }}
                inputOptions={{
                  format: "HH:mm DD/MM/YYYY",
                  showTime: true,
                  showNow: false,
                  disabledDate: (current) => {
                    return current && current < moment().startOf("day");
                  },
                  style: { width: "100%" },
                  placeholder: t("cart.promisedDeliverTimePlaceholder"),
                  onChange: onChangeDate,
                }}
              />

              <FormInput
                type={INPUT_TYPE.SWITCH}
                formItemOptions={{
                  label: <b>{t("cart.fullStockRequiredLabel")}</b>,
                  style: { width: "100%" },
                  tooltip: {
                    title: t("cart.fullStockRequiredLabelTooltip"),
                    icon: <SvgIcon.InfoCircleIcon />,
                  },
                  name: "fullStockRequired",
                  valuePropName: "checked",
                }}
                inputOptions={{
                  onChange: (value) => onChange({ fullStockRequired: value }),
                }}
              />
            </>
          ) : (
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Typography.Text strong>{t("cart.exportWarehouseLabel")}</Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text strong style={{ color: "#052987" }}>
                  {data?.exportedWarehouse?.name}
                </Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text strong>{t("cart.orderPriorityLabel")}</Typography.Text>
              </Col>
              <Col span={12}>{renderOrderPriorityLabel[data?.orderPriority]}</Col>
              <Col span={12}>
                <Typography.Text strong>{t("cart.promisedDeliverTimeLabel")}</Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text strong style={{ color: "#052987" }}>
                  {data?.deliveryDeadline && formatDateTime(data?.deliveryDeadline)}
                </Typography.Text>
              </Col>
              <Col span={24}>
                {data?.fullStockRequired ? (
                  <CheckCircleFilled style={{ color: "#00AB78" }} />
                ) : (
                  <CloseCircleFilled style={{ color: "#D2434D" }} />
                )}
                <Typography.Text strong style={{ marginLeft: "8px" }}>
                  {t("cart.fullStockRequiredLabel")}
                </Typography.Text>
              </Col>
            </Row>
          )}
        </Col>
        <Col span={6} className="right">
          <FormInput
            type={INPUT_TYPE.SWITCH}
            formItemOptions={{
              label: <b>{t("cart.vatAllOrderLabel")}</b>,
              style: { width: "100%" },
              name: "vat",
              valuePropName: "checked",
            }}
            inputOptions={{
              disabled: true,
              onBlur: () => handleUpdate(),
            }}
          />
          <FormInput
            inputOptions={{
              placeholder: t("cart.vatTaxIDNumberPlaceholder"),
              disabled: true,
              onBlur: () => handleUpdate(),
            }}
            formItemOptions={{
              name: "vatTaxIDNumber",
            }}
          />

          <FormInput
            inputOptions={{
              placeholder: t("cart.vatCompanyNamePlaceholder"),
              disabled: true,
              onBlur: () => handleUpdate(),
            }}
            formItemOptions={{
              name: "vatCompanyName",
            }}
          />

          <FormInput
            inputOptions={{
              placeholder: t("cart.vatEmailPlaceholder"),
              disabled: true,
              onBlur: () => handleUpdate(),
            }}
            formItemOptions={{
              name: "vatEmail",
              rules: [{ type: "email", message: t("common.wrongFormatEmail") }],
            }}
          />

          <FormInput
            inputOptions={{
              placeholder: t("cart.vatAddressPlaceholder"),
              disabled: true,
              onBlur: () => handleUpdate(),
            }}
            formItemOptions={{
              name: "vatAddress",
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default InfoAgent;
