import { Card, Col, Form, Popconfirm, Row, Spin, Tooltip, Typography } from "antd";
import CustomButton from "components/CustomButton";
import CustomInfoAdress from "components/CustomInfoAdress";
import CustomModal from "components/CustomModal";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import { EXPORT_TYPE, optionExportRadio } from "features/Serial/constant";
import { t } from "i18next";
import AddInfo from "./components/AddInfo";
import TableSerialExport from "./components/TableSerialExport";
import { SubTitleCard, TitleCard } from "./components/TitleCard";
import OrderInfo from "./components/TypeOrder/OrderInfo";
import { default as SupplierInfo } from "./components/TypeReturnSupplier/SupplierInfo";
import WarehouseExport from "./components/TypeTransferSerial/WarehouseExport";
import SellerInfo from "./components/TypeWarranty/SellerInfo";
import "./index.scss";
import useCreate from "./useCreate";

function CreateExportSerial() {
  const {
    visibleCreateModal,
    form,
    warehouseOptions,
    onSearch,
    hasMoreOrder,
    loadMoreOrder,
    loadingOrders,
    orders,
    initialValues,
    handleValuesChange,
    handleChangeInfo,
    sellerInfo,
    exportType,
    orderInfo,
    dataTableOrder,
    totalSerialByOrder,
    handleGetTags,
    tags,
    onCancelCreate,
    handleChange,
    showOffModalConfirmCreate,
    sellers,
    handleGetTableOrder,
    sumProductByOrder,
    sumSerialByOrder,
    sumSerialScanned,
    handleSubmitCreateExportNotes,
    handleFinish,
    setSerials,
    warrantyNotes,
    handleUploadSuccess,
    loadingCreate,
    isStateLocation,
    loadingOrder,
    checkTypeTitleInfo,
    dataSupplier,
    onLoadMoreDataSupplier,
    hasMoreDataSupplier,
    loadingSupplier,
    supplierInfo,
    dataTableSupplier,
    handleSetFieldSupplier,
    handleRemoveItemReturnSupplier,
    formData,
    handleChangeWarehouse,
    warehouse,
    visiblePopconfirmWarehouse,
    handleCancelPopconfirmWarehouse,
    handleConfirmPopconfirmWarehouse,
    visiblePopconfirmProvider,
    handleCancelPopconfirmProvider,
    handleConfirmPopconfirmProvider,
    loadingSellers,
    handleSelectWarehouse,
    warehouseInfo,
    loadingWarehouse,
    handleSetFieldTransferSerial,
    dataTableTransferSerial,
    handleRemoveItemTransferSerial,
    handleDeleteAllSerial,
  } = useCreate();

  const orderOptions = orders?.map((item) => ({
    value: item?.id,
    label: (
      <Row gutter={16} style={{ fontSize: "13px" }}>
        <Col span={6}>
          <Typography.Link strong>{item?.number}</Typography.Link>
        </Col>
        <Col span={10}>
          <Typography.Text ellipsis>{item?.seller?.fullName}</Typography.Text>
        </Col>
        <Col span={5}>
          <Typography.Text ellipsis>{item?.exportedWarehouse?.name}</Typography.Text>
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <Typography.Text ellipsis>
            {item.items.reduce((acc, currentItem) => {
              return acc + currentItem.quantity;
            }, 0)}
          </Typography.Text>
        </Col>
      </Row>
    ),
    order: item,
    labelOrder: item?.number,
  }));

  const sellerOptions = sellers?.map((item) => ({
    value: item?.id,
    label: item?.fullName,
    seller: item,
  }));

  const supplierOptions = dataSupplier?.map((item) => ({
    value: item?.id,
    label: (
      <Row gutter={16} style={{ fontSize: "13px" }}>
        <Col span={5}>
          <Tooltip title={item?.code}>
            <Typography.Link ellipsis strong>
              {item?.code}{" "}
            </Typography.Link>
          </Tooltip>
        </Col>
        <Col span={7}>
          <Typography.Text ellipsis>{item?.fullName}</Typography.Text>
        </Col>
        <Col span={3}>
          <CustomTextHiddenLine line={1} text={item?.telephone} />
        </Col>
        <Col span={9}>
          <Tooltip
            title={`${item?.address || "---"} - ${item?.ward?.name || "---"} - ${item?.district?.name || "---"} - ${
              item?.city?.name || "---"
            }`}
          >
            <Typography.Text ellipsis>
              <CustomInfoAdress value={item}></CustomInfoAdress>
            </Typography.Text>
          </Tooltip>
        </Col>
      </Row>
    ),
    supplier: item,
    labelSupplier: item?.fullName,
  }));

  const checkTypeInfo = () => {
    switch (exportType) {
      case EXPORT_TYPE.ORDER:
        return (
          <OrderInfo
            orderOptions={orderOptions}
            loadMoreData={loadMoreOrder}
            hasMoreData={hasMoreOrder}
            onSearch={onSearch}
            loading={loadingOrders}
            handleChangeInfo={handleChangeInfo}
            orderInfo={orderInfo}
            handleChange={handleChange}
            isStateLocation={isStateLocation}
          />
        );
      case EXPORT_TYPE.WARRANTY:
        return (
          <SellerInfo
            sellerOptions={sellerOptions}
            loadMoreData={loadMoreOrder}
            hasMoreData={hasMoreOrder}
            onSearch={onSearch}
            handleChangeInfo={handleChangeInfo}
            sellerInfo={sellerInfo}
            loadingSellers={loadingSellers}
          />
        );
      case EXPORT_TYPE.RETURN_SUPPLIER:
        return (
          <SupplierInfo
            supplierOptions={supplierOptions}
            dataSupplier={dataSupplier}
            onLoadMoreDataSupplier={onLoadMoreDataSupplier}
            hasMoreDataSupplier={hasMoreDataSupplier}
            onSearch={onSearch}
            loadingSupplier={loadingSupplier}
            handleChangeInfo={handleChangeInfo}
            supplierInfo={supplierInfo}
            visiblePopconfirmProvider={visiblePopconfirmProvider}
            handleCancelPopconfirmProvider={handleCancelPopconfirmProvider}
            handleConfirmPopconfirmProvider={handleConfirmPopconfirmProvider}
          />
        );
      case EXPORT_TYPE.TRANSFER_SERIAL:
        return (
          <WarehouseExport
            warehouseOptions={warehouseOptions}
            handleSelectWarehouse={handleSelectWarehouse}
            warehouseInfo={warehouseInfo}
            loadingWarehouse={loadingWarehouse}
          />
        );
      case EXPORT_TYPE.OTHER:
        return (
          <WarehouseExport
            warehouseOptions={warehouseOptions}
            handleSelectWarehouse={handleSelectWarehouse}
            warehouseInfo={warehouseInfo}
            loadingWarehouse={loadingWarehouse}
          />
        );
      default:
        return "";
    }
  };
  return (
    <div className="serial-export-create-page">
      <Spin spinning={loadingOrder}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <PageHeader
              pageTitle={t("serialIE.CreateExportSerial")}
              routes={[
                { path: "/", name: t("serialIE.serial") },
                { path: "/export-serial", name: t("serialIE.exportBySerial") },
                { path: "#", name: t("serialIE.CreateExportSerial") },
              ]}
            />
          </Col>
          <Col span={24}>
            <Form
              layout="horizontal"
              initialValues={initialValues}
              form={form}
              onValuesChange={handleValuesChange}
              onFinish={handleFinish}
            >
              <Row gutter={[16, 8]}>
                <Col span={24} className="col-export-serial-header">
                  <Row gutter={[16, 8]}>
                    <Col span={7} className="form-control-radio-group">
                      <FormInput
                        type={INPUT_TYPE.SELECT}
                        formItemOptions={{
                          name: "exportType",
                          label: <SubTitleCard subTitle={t("serialIE.TypeExport")} style={{ fontSize: "13px" }} />,
                        }}
                        inputOptions={{
                          options: optionExportRadio,
                          disabled: isStateLocation,
                        }}
                      />
                    </Col>
                    <Col span={12}></Col>
                    {exportType === EXPORT_TYPE.RETURN_SUPPLIER && (
                      <Col span={5} className="form-control-radio-group">
                        <Popconfirm
                          title={t("serialExportCreate.confirmChangeWarehouse")}
                          onConfirm={handleConfirmPopconfirmWarehouse}
                          onCancel={handleCancelPopconfirmWarehouse}
                          visible={visiblePopconfirmWarehouse}
                          placement="bottomRight"
                          okText={t("common.confirm")}
                          cancelText={t("common.cancel")}
                        >
                          <FormInput
                            type={INPUT_TYPE.SELECT}
                            formItemOptions={{
                              name: "warehouseID",
                              label: (
                                <Typography.Text strong style={{ fontSize: "13px" }}>
                                  {t("order.orderTag.exportWarehouse")}
                                </Typography.Text>
                              ),
                              rules: [{ required: true, message: t("serialIE.RequiredChooseWarehouseExport") }],
                            }}
                            inputOptions={{
                              placeholder: t("serialIE.ChooseWarehouseExport"),
                              options: warehouseOptions,
                              onChange: handleChangeWarehouse,
                              value: warehouse,
                            }}
                          />
                        </Popconfirm>
                      </Col>
                    )}
                  </Row>

                  <Row gutter={[16, 8]} style={{ flexGrow: 1 }}>
                    <Col span={14} className="card-container-info">
                      <Card title={<TitleCard title={checkTypeTitleInfo()} />} style={{ flexGrow: 1 }}>
                        {checkTypeInfo()}
                      </Card>
                    </Col>
                    <Col span={10} className="card-container-info">
                      <Card title={<TitleCard title={t("serialIE.addInfo.title")} />} style={{ flexGrow: 1 }}>
                        <AddInfo handleUploadSuccess={handleUploadSuccess} exportType={exportType} />
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col style={{ marginTop: "16px" }} span={24} className="col-export-serial-footer">
                  <Card
                    title={
                      <div>
                        <TitleCard title={t("serialIE.productInfo.title")} />
                      </div>
                    }
                  >
                    <Row gutter={[16, 8]}>
                      <Col span={24}>
                        <Form.List name="items">
                          {(items, remove) => {
                            return (
                              <TableSerialExport
                                dataTableOrder={dataTableOrder}
                                exportType={exportType}
                                warehouseOptions={warehouseOptions}
                                handleValuesChange={handleValuesChange}
                                form={form}
                                totalSerialByOrder={totalSerialByOrder}
                                handleGetTags={handleGetTags}
                                tags={tags}
                                warehouseInfo={warehouseInfo}
                                handleGetTableOrder={handleGetTableOrder}
                                sumProductByOrder={sumProductByOrder}
                                sumSerialByOrder={sumSerialByOrder}
                                sumSerialScanned={sumSerialScanned}
                                setSerials={setSerials}
                                warrantyNotes={warrantyNotes}
                                dataTableSupplier={dataTableSupplier}
                                handleSetFieldSupplier={handleSetFieldSupplier}
                                handleRemoveItemReturnSupplier={handleRemoveItemReturnSupplier}
                                formData={formData}
                                handleSetFieldTransferSerial={handleSetFieldTransferSerial}
                                dataTableTransferSerial={dataTableTransferSerial}
                                handleRemoveItemTransferSerial={handleRemoveItemTransferSerial}
                                handleDeleteAllSerial={handleDeleteAllSerial}
                              />
                            );
                          }}
                        </Form.List>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card className="form-footer">
                    <CustomModal
                      {...{
                        customComponent: <CustomButton title={t("common.cancel")} />,
                        footer: false,
                        message: t("serialIE.cancelExportSerial"),
                        centered: true,
                        onOke: onCancelCreate,
                      }}
                    />
                    <CustomModal
                      customComponent={
                        <CustomButton
                          type={"primary"}
                          title={t("serialIE.exportwarehouse")}
                          htmlType={"submit"}
                          loading={loadingCreate}
                        />
                      }
                      footer={false}
                      message={t("serialIE.warningExportCreate1")}
                      centered={true}
                      onCancel={showOffModalConfirmCreate}
                      buttonLoading={loadingCreate}
                      visible={visibleCreateModal}
                      onOke={handleSubmitCreateExportNotes}
                      loading={loadingCreate}
                    />
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
export default CreateExportSerial;
