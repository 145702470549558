import { PRODUCT_TYPE } from "features/Products/constants";
import { useState, useEffect } from "react";
import { omit } from "lodash";
import { t } from "i18next";

const useModalProducts = ({ handleAddNewItems, warehouseID, sellerID }) => {
  const [params, setParams] = useState({
    filters: {
      productType: PRODUCT_TYPE.MATERIAL,
      isActive: true,
    },
    pagination: { offset: 0, limit: 10 },
    withCart: true,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(PRODUCT_TYPE.MATERIAL);
  const tabs = [
    { title: t("cart.listMaterial"), key: PRODUCT_TYPE.MATERIAL },
    { title: t("cart.listService"), key: PRODUCT_TYPE.SERVICE },
  ];

  const handleChangeTab = (activeKey) => {
    if (activeKey === PRODUCT_TYPE.MATERIAL) {
      setParams({
        filters: {
          productType: activeKey,
          isActive: true,
        },
        pagination: { offset: 0, limit: 10 },
        warehouseIDs: warehouseID,
        sellerID: sellerID,
      });
    } else {
      setParams({
        filters: {
          productType: activeKey,
          isActive: true,
        },
        pagination: { offset: 0, limit: 10 },
        warehouseIDs: warehouseID,
        sellerID: sellerID,
      });
    }

    setActiveTabKey(activeKey);
  };

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    const newParams = { ...params, pagination: { offset: (current - 1) * pageSize, limit: 10 } };
    setParams(newParams);
  };

  const handleSelected = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleChangeFilter = (value) => {
    setIsFilterSelected(value);
  };

  const onSearch = (value) => {
    const newParams = {
      ...params,
      filters: { ...params?.filters, query: value, isActive: true },
      pagination: { offset: 0, limit: 10 },
    };
    setParams(newParams);
  };

  const onSubmit = () => {
    handleAddNewItems(selectedRows);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const filterProductsByProductType = selectedRows?.filter((item) => item?.productType === activeTabKey);

  const handleFilter = (key, value) => {
    setParams({
      ...params,
      filters: { ...params?.filters, [`${key}`]: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const handleRemoveFilterTag = (key) => {
    setParams({
      ...params,
      filters: omit({ ...params?.filters }, [`${key}`]),
      pagination: { offset: 0, limit: 10 },
    });
  };

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params?.filters,
        isActive: true,
      },
      warehouseIDs: warehouseID,
      sellerID: sellerID,
    });
  }, [warehouseID, sellerID]);

  return {
    tabs,
    handleChangeTab,
    params,
    handleTableChange,
    handleSelected,
    selectedRowKeys,
    selectedRows: filterProductsByProductType,
    handleChangeFilter,
    isFilterSelected,
    onSearch,
    onSubmit,
    handleFilter,
    handleRemoveFilterTag,
    countSelectedRowKeys: selectedRowKeys?.length ? selectedRowKeys?.length : 0,
  };
};

export default useModalProducts;
