import { gql } from "@apollo/client";

export const GET_SELLERS = gql`
  query GetSellers($filters: SellersFilters, $pagination: PaginationRequest) {
    seller {
      pagination(filters: $filters, pagination: $pagination) {
        sellers {
          id
          code
          email
          idNumber
          shortName
          type
          fullName
          telephone
          note
          sellerLevel {
            level
            name
          }
          debtLimit
          debtAge
          vatInfo {
            taxIDNumber
            businessName
            representative
            telephone
            address
            email
            bankID
            bankAccountNumber
            bankBranch
          }
          isActive
          address
          city {
            id
            name
          }
          district {
            id
            name
          }
          ward {
            id
            name
          }
          isSeller
          isSupplier
          warehouses {
            name
            id
            code
          }
          paymentMethod
          countInvite
          isLoggedIn
          sellerGroup {
            id
            name
          }
          isSupplier
          rejectedReason
          createdAt
          saleCategories {
            categoryID
            level
          }
        }
        paginationData {
          total
          limit
          offset
        }
      }
    }
  }
`;

export const GET_SELLERS_WITH_WALLET = gql`
  query GetSellers($filters: SellersFilters, $pagination: PaginationRequest) {
    seller {
      pagination(filters: $filters, pagination: $pagination) {
        sellers {
          sellerGroupID
          sellerGroup {
            id
            name
          }
          id
          code
          fullName
          shortName
          sellerLevel {
            id
            level
          }
          isActive
          companyWallet {
            id
            balance
          }
          paymentMethod
          activeWallets {
            id
            type
            balance
          }
          isActive
          debtLimit
          currentDebt
        }
        paginationData {
          total
        }
      }
    }
  }
`;

export const GET_SELLER_DETAIL = gql`
  query GET_SELLER_DETAIL($id: ID!) {
    seller {
      get(id: $id) {
        id
        code
        logoUrl
        type
        idNumber
        shortName
        sellerLevelID
        debtLimit
        debtAge
        isActive
        fullName
        email
        telephone
        address
        cityID
        districtID
        wardID
        city {
          name
        }
        district {
          name
        }
        ward {
          name
        }
        paymentMethod
        representName
        representEmail
        representPhone
        isSeller
        isSupplier
        vatInfo {
          id
          taxIDNumber
          businessName
          representative
          telephone
          address
          email
          bankID
          bankAccountNumber
          bankBranch
          defaultExportVat
          allowEditVat
        }
        warehouses {
          id
          name
          branch {
            name
          }
        }
        fullSaleCategories {
          checked
          level
          category {
            name
            code
            id
          }
        }
        sellerGroupID
        sellerGroup {
          name
          id
          code
        }
        availabilityLimit
        users {
          id
          email
          fullname
          telephone
        }
      }
    }
  }
`;

export const SELLER_LEVERS = gql`
  query {
    sellerLevel {
      list {
        id
        name
        level
      }
    }
  }
`;

export const GET_SELLER_CONTACT = gql`
  query GetSellerAddress($sellerID: ID!, $query: String) {
    contact {
      list(sellerID: $sellerID, query: $query) {
        id
        fullName
        address
        city {
          name
        }
        district {
          name
        }
        ward {
          name
        }
        telephone
        wardID
        districtID
        cityID
        isDefault
        email
      }
    }
  }
`;

export const GET_CREATE_CART_SELLERS = gql`
  query GetSellers($filters: SellersFilters, $pagination: PaginationRequest) {
    seller {
      pagination(filters: $filters, pagination: $pagination) {
        sellers {
          id
          code
          fullName
          telephone
          address
          city {
            name
          }
          district {
            name
          }
          ward {
            name
          }
          email
          ward {
            name
          }
          district {
            name
          }
          city {
            name
          }
          bankAccounts {
            name
            bankBranch
            bankAccountNumber
            bank {
              name
              fullName
            }
          }
          warehouses {
            id
            code
            name
            branch {
              name
              id
            }
          }
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

export const GET_SELLERS_FOR_CUSTOMER = gql`
  query GetSellersForCustomer($filters: SellersFilters, $pagination: PaginationRequest) {
    seller {
      pagination(filters: $filters, pagination: $pagination) {
        sellers {
          id
          code
          idNumber
          shortName
          fullName
          debtLimit
          debtAge
          isActive
          address
          paymentMethod
          sellerGroupID
        }
        paginationData {
          total
          limit
          offset
        }
      }
    }
  }
`;

export const GET_SELLER_CUSTOMER = gql`
  query GetSellerCustomer($filters: SellerGroupFilters, $pagination: PaginationRequest) {
    sellerGroup {
      pagination(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        sellerGroups {
          id
          code
          name
          description
          sellerQuantity
          isActive
          manager {
            id
            fullname
          }
          managerID
        }
      }
    }
  }
`;

export const GET_DETAIL_SELLER_CUSTOMER = gql`
  query GetDetailSellerCustomer($id: ID!) {
    sellerGroup {
      get(id: $id) {
        id
        code
        name
        managerID
        description
        manager {
          id
          fullname
        }
        isActive
      }
    }
  }
`;

export const GET_ACCOUNTANT_CUSTOMER = gql`
  query GetAccountantCustomer($filters: UsersFilters, $pagination: PaginationRequest) {
    user {
      pagination(filters: $filters, pagination: $pagination) {
        users {
          id
          fullname
          roles {
            name
            id
          }
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

export const GET_CUSTOMER_LOADMORE = gql`
  query GetCustomerLoadmore($filters: SellerGroupFilters, $pagination: PaginationRequest) {
    sellerGroup {
      pagination(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        sellerGroups {
          id
          code
          name
          managerID
        }
      }
    }
  }
`;

export const GET_ORDERS_FOR_ACTIVITY_LOGS = gql`
  query GetOrdersForActivityLogs($filters: OrdersFilters!, $pagination: PaginationRequest!, $sort: [OrderSort!]) {
    order {
      pagination(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        orders {
          id
          createdAt
          number
          sellerID
          createdBy {
            id
            fullname
          }
          total
          paymentMethod
          status
        }
      }
    }
  }
`;

export const GET_SUMMARY_SELLER = gql`
  query GetSummaryTabs($sellerIDs: [ID!]) {
    all: order {
      pagination(filters: { sellerIDs: $sellerIDs }) {
        paginationData {
          total
        }
      }
    }
    completed: order {
      pagination(filters: { statuses: [COMPLETED], sellerIDs: $sellerIDs }) {
        paginationData {
          total
        }
      }
    }
    processing: order {
      pagination(
        filters: {
          statuses: [
            WAITING_FOR_APPROVAL_STOCK
            WAITING_FOR_ENOUGH_STOCK
            WAITING_FOR_PAYMENT
            WAITING_FOR_PACKAGING
            WAITING_FOR_EXPORTING
            WAITING_FOR_ASSEMBLY
            WAITING_FOR_SHIPPING
            DELIVERING
          ]
          sellerIDs: $sellerIDs
        }
      ) {
        paginationData {
          total
        }
      }
    }
    cancel: order {
      pagination(filters: { statuses: [CANCELED], sellerIDs: $sellerIDs }) {
        paginationData {
          total
        }
      }
    }
  }
`;

export const GET_CATEGORIES_SELLER = gql`
  query GetCategoriesSeller($sellerIDs: ID!) {
    seller {
      get(id: $sellerIDs) {
        saleCategories {
          categoryID
          category {
            id
            name
            code
            level
            parent {
              code
              id
              name
              level
              parent {
                id
                code
                name
                level
                parent {
                  id
                  code
                  name
                  level
                }
              }
            }
          }
          checked
          level
        }
      }
    }
  }
`;

export const GET_ACTIVITY_LOGS = gql`
  query GetActivityLogs($sellerIDs: ID!, $filters: SellerActivityLogFilters, $pagination: PaginationRequest) {
    seller {
      getActivityLogs(sellerID: $sellerIDs, filters: $filters, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        sellerActivityLogs {
          updatedAt
          status
          action
          detail
          updatedByID
          updatedBy {
            id
            fullname
          }
        }
      }
    }
  }
`;

export const GET_SELLERS_CREATE_EXPORT_SERIAL = gql`
  query GetSellersCreateExportSerial($filters: SellersFilters, $pagination: PaginationRequest) {
    seller {
      pagination(filters: $filters, pagination: $pagination) {
        sellers {
          id
          code
          fullName
          telephone
          address
          email
          ward {
            name
          }
          city {
            name
          }
          district {
            name
          }
        }
        paginationData {
          total
        }
      }
    }
  }
`;

export const GET_TOTAL_BY_STATUS = gql`
  query GetTotalByStatus {
    seller {
      getTotalByStatus {
        waitingApproval
        processing
        approval
        rejected
      }
    }
  }
`;

export const GET_SELLER_WITH_IDS = gql`
  query GetSellerWithIds($filters: SellersFilters) {
    seller {
      pagination(filters: $filters) {
        sellers {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_SELLER_GROUP_WITH_IDS = gql`
  query GetSellerGroupWithIds($filters: SellerGroupFilters) {
    sellerGroup {
      pagination(filters: $filters) {
        sellerGroups {
          id
          name
        }
      }
    }
  }
`;

export const GET_SELLERS_WITH_IDS = gql`
  query GetSellerWithIds($filters: SellersFilters) {
    seller {
      pagination(filters: $filters) {
        sellers {
          id
          fullName
        }
      }
    }
  }
`;
