import React, { useEffect, useState } from "react";
import { Button, Form, Space, Tag, Tooltip } from "antd";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { ACTION_FORM_TYPE, INPUT_TYPE, REGEX } from "config/constants";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "assets/icons";
import { useHistory } from "react-router-dom";
import CategoryListModal from "./components/CategoryListModal";
import "./index.scss";
import { useCreateAttribute, useGetAttribute, useUpdateAttribute, useUpdateAttributeStatus } from "hooks/attribute";
import { notify } from "utils/helperFuncs";
import CustomModal from "components/CustomModal";
import CreateButton from "components/CreateButton";
import { useGetCategoriesByAtt } from "hooks/category/category";
import Spinner from "components/Spinner";

const FIELD_NAME = {
  ATTRIBUTE_NAME: "name",
  ATTRIBUTE_TYPE: "attributeType",
  ATTRIBUTE_VALUE: "attributeValue",
  APPLY_ALL_CATEGORIES: "applyAllCategory",
  STATUS: "status",
};

const ATTRIBUTE_TYPE = {
  PREDEFINED: "PREDEFINED",
  FREE_TEXT: "FREE_TEXT",
};

const FORM_LAYOUT = {
  layout: "horizontal",
  labelCol: {
    lg: {
      span: 4,
    },
    xxl: {
      span: 3,
    },
  },
  wrapperCol: {
    xl: {
      span: 15,
    },
    xxl: {
      span: 12,
    },
  },
  labelAlign: "left",
  colon: false,
};

const FormLabel = ({ titleString, titleI18nKey }) => {
  const { t } = useTranslation();
  return (
    <span className="form-label">
      {titleString}
      {titleI18nKey ? t(titleI18nKey) : ""}
    </span>
  );
};

const ActionLayout = ({ type = ACTION_FORM_TYPE.CREATE }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [toggleCancelWarning, setToggleCancelWarning] = useState(false);
  const [toggleCategoryList, setToggleCategoryList] = useState(false);
  const [form] = Form.useForm();
  const isEditing = type === ACTION_FORM_TYPE.EDIT;
  const [valueList, setValueList] = useState([]);
  const [attributeType, setAttributeType] = useState("");

  const attributeId = isEditing ? history.location.state.id : undefined;
  const { loading: loadingAtt, data: attribute } = useGetAttribute({ id: attributeId });

  const initialValues = isEditing
    ? {
        name: attribute?.name,
        attributeType: attribute?.attributeType,
        attributeValue: "",
        totalUsedCategory: attribute?.totalUsedCategory || 0,
        status: attribute?.isActive || false,
        applyAllCategory: attribute?.isCommon || false,
      }
    : {
        totalUsedCategory: 0,
        applyAllCategory: false,
      };

  const initForm = () => {
    form.setFieldsValue(initialValues);
    setAttributeType(attribute?.attributeType || "");
    setValueList(attribute?.values || []);
  };

  //hooks
  const { handleCreateAttribute } = useCreateAttribute();
  const { handleUpdateAttribute } = useUpdateAttribute();
  const { handleUpdateAttributeStatus } = useUpdateAttributeStatus();
  const { data: categoriesByAtt } = useGetCategoriesByAtt({
    levels: [3],
    attributeIDs: attributeId ? [attributeId] : undefined,
    isActive: true,
  });

  //validate attributeType
  const checkValueList = () => {
    if (valueList?.length <= 1 && attributeType === ATTRIBUTE_TYPE.PREDEFINED) {
      return Promise.reject(new Error(t("product.categoryAttribute.attValueValidateEmpty")));
    }
    return Promise.resolve();
  };

  //function
  const handleCloseTag = (index) => {
    const cloneValueList = [...valueList];
    cloneValueList.splice(index, 1);
    setValueList(cloneValueList);
  };

  const handleAddTag = () => {
    const attributeValue = form.getFieldValue(FIELD_NAME.ATTRIBUTE_VALUE)?.trim();
    if (attributeValue) setValueList([...valueList, { id: undefined, value: attributeValue }]);
    form.setFieldsValue({ [`${FIELD_NAME.ATTRIBUTE_VALUE}`]: "" });
  };

  const handleCancel = () => {
    history.push("/product-attribute");
  };

  const handleSave = async (isContinue) => {
    await form.validateFields();
    const formValues = form.getFieldsValue();
    const values = { ...formValues, valueList: valueList?.length ? valueList : undefined };

    if (isEditing) {
      handleUpdateAttribute({
        id: attributeId,
        name: values.name,
        attributeType: values.attributeType,
        isCommon: values.applyAllCategory,
        isActive: values.status,
        values: values.valueList,
      })
        .then(() => {
          // handleUpdateAttributeStatus({ ids: [attributeId], isActive: values.status });
          form.resetFields();
          setValueList([]);
          history.push("/product-attribute");
          notify.success({
            message: t("product.categoryAttribute.updateAttributeSuccess"),
          });
        })
        .catch((error) => {
          notify.error({
            message: t("product.categoryAttribute.updateAttributeError"),
            description: error.message,
          });
        });
    } else {
      handleCreateAttribute({
        name: values.name,
        attributeType: values.attributeType,
        isCommon: values.applyAllCategory,
        values: values.valueList,
      })
        .then(() => {
          form.resetFields();
          setValueList([]);
          setAttributeType("");
          isContinue && history.push("/product-attribute");
          notify.success({
            message: t("product.categoryAttribute.createAttributeSuccess"),
          });
        })
        .catch((error) => {
          notify.error({
            message: t("product.categoryAttribute.createAttributeError"),
            description: error.message,
          });
        });
    }
  };

  useEffect(() => {
    initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAtt]);

  return (
    <>
      {isEditing && <Spinner loading={loadingAtt} />}
      <PageHeader
        pageTitle={
          isEditing ? t("product.categoryAttribute.editAttribute") : t("product.categoryAttribute.createAttribute")
        }
        routes={[
          {
            path: "/product",
            name: t("common.product"),
          },
          {
            path: "/product-attribute",
            name: t("common.categoryAttribute"),
          },
          {
            path: "",
            name: isEditing
              ? t("product.categoryAttribute.editAttribute")
              : t("product.categoryAttribute.createAttribute"),
          },
        ]}
      />

      <div className="form-container">
        <Form form={form} {...FORM_LAYOUT} initialValues={initialValues}>
          <div className="button-group">
            <Form.Item>
              <CustomModal
                width={500}
                closable={false}
                centered={true}
                footer={false}
                visible={toggleCancelWarning}
                onOpen={() => {
                  setToggleCancelWarning(true);
                }}
                onCancel={() => {
                  setToggleCancelWarning(false);
                }}
                onOke={handleCancel}
                customComponent={<Button>{t("common.cancel")}</Button>}
              >
                <h3>{t("product.categoryAttribute.cancelWarning")}</h3>
                <br />
              </CustomModal>
            </Form.Item>
            <Form.Item>
              <CreateButton title={t("common.save")} onClick={() => handleSave(true)} />
            </Form.Item>
            {!isEditing && (
              <Form.Item>
                <CreateButton title={t("common.saveAndAdd")} onClick={() => handleSave(false)} />
              </Form.Item>
            )}
          </div>

          <div className="form-group">
            <FormInput
              formItemOptions={{
                label: <FormLabel titleI18nKey="product.categoryAttribute.attNameLabel" />,
                name: FIELD_NAME.ATTRIBUTE_NAME,
                rules: [
                  { required: true, message: t("common.validateRequired") },
                  {
                    type: "string",
                    max: 50,
                    message: t("product.categoryAttribute.attNameValidateOverLimit"),
                  },
                  {
                    pattern: REGEX.TEXT_TRIM_SPACE,
                    message: t("supplier.placeholderInput.whiteSpace"),
                  },
                ],
                extra: <i>{t("product.categoryAttribute.attNameExtra")}</i>,
              }}
              inputOptions={{
                placeholder: t("product.categoryAttribute.attNamePlaceholder"),
              }}
            />

            <FormInput
              type={INPUT_TYPE.SELECT}
              formItemOptions={{
                label: <FormLabel titleI18nKey="product.categoryAttribute.attTypeLabel" />,
                name: FIELD_NAME.ATTRIBUTE_TYPE,
                rules: [{ required: true, message: t("common.validateRequired") }],
              }}
              inputOptions={{
                disabled: isEditing,
                placeholder: t("product.categoryAttribute.attTypePlaceholder"),
                options: [
                  {
                    label: t("product.categoryAttribute.attTypePredefined"),
                    value: ATTRIBUTE_TYPE.PREDEFINED,
                  },
                  {
                    label: t("product.categoryAttribute.attTypeFreeText"),
                    value: ATTRIBUTE_TYPE.FREE_TEXT,
                  },
                ],
                onChange: (value) => {
                  setAttributeType(value);
                },
              }}
            />

            {attributeType === ATTRIBUTE_TYPE.PREDEFINED && (
              <div className="attribute-value-list">
                <FormInput
                  formItemOptions={{
                    label: <FormLabel titleI18nKey="product.categoryAttribute.attValueLabel" />,
                    name: FIELD_NAME.ATTRIBUTE_VALUE,
                    required: true,
                    rules: [{ validator: checkValueList }],
                    extra: (
                      <span className="attribute-value-list-extra">
                        <i>{t("product.categoryAttribute.attValueExtra")}</i>
                        <div className="tag-list">
                          <Space wrap size={[8, 16]}>
                            {valueList?.map((item, index) => (
                              <Tag
                                className="value-tag"
                                key={index}
                                closable
                                onClose={(e) => {
                                  e.preventDefault();
                                  handleCloseTag(index);
                                }}
                              >
                                {item?.value}
                              </Tag>
                            ))}
                          </Space>
                        </div>
                      </span>
                    ),
                  }}
                  inputOptions={{
                    placeholder: t("product.categoryAttribute.attValuePlaceholder"),
                    onPressEnter: handleAddTag,
                    onBlur: handleAddTag,
                  }}
                />
              </div>
            )}

            {isEditing && (
              <>
                <Form.Item label={<FormLabel titleI18nKey="product.categoryAttribute.inUsedCategories" />}>
                  <>
                    <span className="in-used-category">{initialValues.totalUsedCategory}</span>

                    <CategoryListModal
                      totalUsedCategory={initialValues.totalUsedCategory}
                      toggleCategoryList={toggleCategoryList}
                      setToggleCategoryList={setToggleCategoryList}
                      dataSource={categoriesByAtt}
                      attributeName={initialValues.name}
                    />
                  </>
                </Form.Item>

                <FormInput
                  type={INPUT_TYPE.SWITCH}
                  formItemOptions={{
                    name: FIELD_NAME.STATUS,
                    label: <FormLabel titleI18nKey="common.status" />,
                    valuePropName: "checked",
                    required: true,
                  }}
                  inputOptions={{
                    disabled: initialValues.totalUsedCategory !== 0,
                  }}
                />
              </>
            )}

            <div className="apply-all-category">
              <FormInput
                type={INPUT_TYPE.SWITCH}
                formItemOptions={{
                  name: FIELD_NAME.APPLY_ALL_CATEGORIES,
                  valuePropName: "checked",
                }}
              />
              <div>{t("product.categoryAttribute.applyAllCategoryLabel")}</div>
              <Tooltip title={t("product.categoryAttribute.applyAllCategoryTip")} color="#5B6673" placement="bottom">
                <SvgIcon.InfoCircleIcon />
              </Tooltip>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ActionLayout;
