import React from 'react';
import { Tabs } from 'antd';

export function TabsMenu({ tabs, onChange, defaultActiveKey, ...rest }) {
  const handleChange = (activeKey) => {
    onChange && onChange(activeKey);
  };

  return (
    <Tabs defaultActiveKey={defaultActiveKey} onChange={handleChange} {...rest}>
      {tabs.map((tab) => (
        <Tabs.TabPane active tab={tab.title} key={tab.key}>
          {tab.children}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
}

export default TabsMenu;
