import React from 'react';
import CustomTags from 'components/CustomTags';
import { formatDateTime } from 'utils/dateTime';
import { DATE_FORMAT } from 'config/constants';
import { t } from 'i18next';
import { formatDateTimeFilterTag } from 'utils/helperFuncs';
import { optionSalesReturnStatus, optionSalesReturnType } from 'features/Warehouse/constant';

const useFiltersTag = ({
  params,
  handleRemoveFilterTag,
  rangeDateTimeFilter,
  warehouseOptions,
  optionImportRequestType,
  staffs,
  sellersOptions,
}) => {
  const renderTag = (arrTags = [], arrValue, title) => {
    let result = [];
    if (
      typeof arrValue !== 'undefined' &&
      arrValue !== null &&
      typeof arrValue[Symbol.iterator] === 'function'
    ) {
      for (const element of arrValue) {
        const item = arrTags?.find(({ value }) => element === value);
        result.push(item);
      }
    }
    return (
      <div>
        <span className="label">{title}</span>: {result.map((item) => item?.label).join(', ')}
      </div>
    );
  };

  const tagsTimeRange = [
    {
      title: t('product.priceBid.Time'),
      selected: rangeDateTimeFilter,
      key: 'timeRange',
    },
  ];
  const tagsStatus = [
    {
      title: t('product.priceBid.status'),
      selected: params?.filters?.statuses,
      options: optionSalesReturnStatus,
      key: 'statuses',
    },
  ];
  const tagsWarehouse = [
    {
      title: t('warehouse.recommendImport.WAREHOUSE'),
      selected: params?.filters?.warehouseIDs,
      options: warehouseOptions,
      key: 'warehouseIDs',
    },
  ];
  const tagsTypes = [
    {
      title: t('warehouse.recommendImport.TYPERECOMMEND'),
      selected: params?.filters?.types,
      options: optionSalesReturnType,
      key: 'types',
    },
  ];
  const tagsCreatedByIDs = [
    {
      title: t('warehouse.recommendImport.CREATEDBY'),
      selected: params?.filters?.createdByIDs,
      options: staffs,
      key: 'createdByIDs',
    },
  ];
  const tagsSupplierIDs = [
    {
      title: t('warehouse.salesReturn.SELLER'),
      selected: params?.filters?.supplierIDs,
      options: sellersOptions,
      key: 'supplierIDs',
    },
  ];

  const tagsupdatedByIDs = [
    {
      title: t('warehouse.recommendImport.APPROVEBY'),
      selected: params?.filters?.updatedByIDs,
      options: staffs,
      key: 'updatedByIDs',
    },
  ];
  const renderTagTimeRangeFilter = tagsTimeRange.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={`${title} : ${t('order.orderTag.from')} ${formatDateTime(
          selected?.from,
          DATE_FORMAT
        )} ${t('order.orderTag.to')} ${formatDateTimeFilterTag(selected?.to)}`}
      />
    );
  });

  const renderTagsStatusRequestImport = tagsStatus.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsWarehouseIDs = tagsWarehouse.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsTypes = tagsTypes.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsCreatedByIDs = tagsCreatedByIDs.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsSupplierIDs = tagsSupplierIDs.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  const renderTagsupdatedByIDs = tagsupdatedByIDs.map(({ options, selected, title, key }) => {
    return (
      <CustomTags
        handleOnClose={() => handleRemoveFilterTag(key)}
        key={key}
        label={renderTag(options, selected, title)}
      />
    );
  });
  return {
    renderTagTimeRangeFilter,
    renderTagsStatusRequestImport,
    renderTagsWarehouseIDs,
    renderTagsTypes,
    renderTagsCreatedByIDs,
    renderTagsSupplierIDs,
    renderTagsupdatedByIDs,
  };
};

export default useFiltersTag;
