import { Form } from "antd";
import { isNil } from "lodash";
import { useEffect, useState } from "react";

import { useGetSupplierList } from "hooks/supplier";
import useDebounce from "hooks/useDebounce";
import { omit } from "lodash";
import { buildQueryString, getQuery, getTimeStamp } from "utils/helperFuncs";
import { TOGGLE } from "../constant";

function useSupplierList() {
  const GET_QUERY = getQuery();
  const [form] = Form.useForm();

  const [searchTerm, setSearchTerm] = useState(null);
  const [reason, setReason] = useState(TOGGLE.OVERDUE_DEBT);
  const [freetext, setFreetext] = useState(null);

  const [selectedSwitch, setSelectedSwitch] = useState({
    id: null,
    isActiveSupplier: null,
  });

  const [params, setParams] = useState({
    filters: {
      isSupplier: true,
      timeRange:
        {
          from: GET_QUERY.from || undefined,
          to: GET_QUERY.to || undefined,
        } || undefined,
      query: GET_QUERY.query || undefined,
      sellerGroupIDs: GET_QUERY.sellerGroupIDs || undefined,
      types: GET_QUERY.types || undefined,
      isSeller: GET_QUERY.isSeller || undefined,
      isActiveSupplier: GET_QUERY.isActiveSupplier || undefined,
    },
    pagination: {
      offset: GET_QUERY.offset || 0,
      limit: GET_QUERY.limit || 10,
    },
  });

  const debouncedValue = useDebounce(searchTerm);
  const { data, paginationData, loading } = useGetSupplierList(params);

  const initialValue = {
    defaultReason: reason,
    freeText: freetext,
  };

  const handleValuesChange = (valueChange, allValues) => {
    setReason(valueChange.defaultReason);
    setFreetext(valueChange.freetext);
  };

  const onChangeParams = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    };
    setParams(newParams);
    const paramQueryString = omit(newParams?.filters, "timeRange");
    buildQueryString({
      params: { ...paramQueryString, ...newParams?.pagination },
    });
  };
  function filterSellerByTimeRange(value) {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        timeRange: {
          from: getTimeStamp(value)?.from || undefined,
          to: getTimeStamp(value)?.to || undefined,
        },
      },
    });
  }

  const onFilter = (key, value) => {
    const newParams = {
      ...params,
      filters: { ...params?.filters, [`${key}`]: value },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    onChangeParams && onChangeParams({ [`${key}`]: value });
    setParams(newParams);
    const paramQueryString = omit(newParams?.filters, "timeRange");

    buildQueryString({
      params: { ...paramQueryString, ...newParams?.pagination },
    });
  };

  const onTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      pagination: { ...params.pagination, offset: (current - 1) * pageSize },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
      },
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const onChangeStatus = (record, value) => {
    setSelectedSwitch({
      id: record?.id,
      isActiveSupplier: value,
    });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        filters: { ...params?.filters, query: debouncedValue || " " },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParams(newParams);
      const paramQueryString = omit(newParams?.filters, "timeRange");

      buildQueryString({
        params: { ...paramQueryString, ...newParams?.pagination },
      });
    }
  }, [debouncedValue]);

  useEffect(() => {
    const values = form.getFieldsValue();
    if (values?.defaultReason !== null) {
      form.setFieldsValue({
        ...values,
        freeText: null,
      });
    }
  }, [reason]);

  useEffect(() => {
    const newParams = {
      ...params,
      filters: {
        ...params?.filters,
        isActiveSupplier: GET_QUERY.isActiveSupplier,
        isSeller: GET_QUERY.isSeller,
      },
    };
    setParams(newParams);
    const paramQueryString = omit(newParams?.filters, "timeRange");
    buildQueryString({
      params: { ...paramQueryString, ...newParams?.pagination },
    });
  }, []);

  return {
    params,
    supplierList: data,
    paginationData,
    loading,
    form,
    initialValue,
    reason,
    selectedSwitch,
    isActiveSupplier: selectedSwitch?.isActiveSupplier,
    onFilter,
    onTableChange,
    onChangeParams,
    onChangeStatus,
    handleSearch,
    handleValuesChange,
    filterSellerByTimeRange,
  };
}

export default useSupplierList;
