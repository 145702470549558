import { Popconfirm, Typography } from "antd";
import CustomTable from "components/CustomTable";
import { DATE_TIME_FORMAT2, INPUT_TYPE, REGEX } from "config/constants";
import { t } from "i18next";
import { useEffect, useState } from "react";

import FormInput from "components/FormInput";

import { SvgIcon } from "assets/icons";
import { CURRENCY_INPUT, optionCurrency, optionwarrantyPeriod } from "features/Warehouse/constant";
import { Link } from "react-router-dom";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";
import styles from "./index.module.scss";

function TableProduct({
  pendingWarrantyNoteLoading,
  form,
  handleGetTableOrder,
  dataTableProduct,
  handleDeleteProduct,
  setSumPrice,
  setCurrencyFirst,
  handleKeyPress,
}) {
  const [quantity, setQuantity] = useState([]);
  const [price, setPrice] = useState([]);
  const [currency, setCurrency] = useState([]);

  const handleQuantity = (index, value) => {
    const updatedQuantity = [...quantity];
    updatedQuantity[index] = parseFloat(value);
    setQuantity(updatedQuantity);
  };

  const handlePrice = (index, value) => {
    const updatedPrice = [...price];
    updatedPrice[index] = parseFloat(value);
    setPrice(updatedPrice);
  };

  const handleCurrency = (index, value) => {
    const updatedCurrency = [...currency];
    updatedCurrency[index] = value;
    setCurrency(updatedCurrency);
  };

  const formData = form?.getFieldsValue();

  useEffect(() => {
    setPrice(formData?.items?.map((item) => item.price));
    setQuantity(formData?.items?.map((item) => item.quantity));
    setCurrency(formData?.items?.map((item) => item.currency));
  }, [JSON.stringify(formData)]);

  useEffect(() => {
    let arrPrice = price?.map((item) => (isNaN(item) || item === null || item === undefined ? 0 : item));
    let arrQuanlity = quantity?.map((item) => (isNaN(item) || item === null || item === undefined ? 0 : item));
    let sum = 0;
    for (let i = 0; i < arrPrice?.length; i++) {
      if (arrPrice[i] !== 0 && arrQuanlity[i] !== 0) {
        sum += arrPrice[i] * arrQuanlity[i];
      }
    }
    setSumPrice(arrQuanlity && arrPrice ? sum : 0);
    setCurrencyFirst(currency?.length > 0 && currency[0]);
  }, [JSON.stringify(price), JSON.stringify(quantity), JSON.stringify(currency)]);

  const columns = [
    {
      align: "center",
      title: "STT",
      render: (_, record, index) => index + 1,
      width: 50,
    },
    {
      title: t("warehouse.recommendImport.PARAMETER"),
      align: "center",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Link to={`/product/detail/${record?.id}`} style={{ textAlign: "start", margin: 0 }}>
              <Typography.Link strong>{record?.code}</Typography.Link>
            </Link>
            <Typography.Text ellipsis={{ tooltip: true }} style={{ textAlign: "start", margin: 0 }} strong>
              {record?.name}
            </Typography.Text>
          </div>
        );
      },
      isHidden: false,
      width: 240,
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.quantity"),
      dataIndex: "quantity",
      render: (_, record, index) => (
        <FormInput
          style={{ width: "70px" }}
          {...{
            type: INPUT_TYPE.CURRENCY_INPUT,
            formItemOptions: {
              name: [index, "quantity"],
              rules: [
                {
                  required: true,
                  message: t("warehouse.create.pleaseEnter"),
                },
                {
                  pattern: REGEX.NUMBER,
                },
              ],
            },
            inputOptions: {
              style: { width: "70px" },
              maxLength: 5,
              min: 0,
              className: "form-text-center",
              onChange: (e) => handleQuantity(index, e),
              onKeyPress: handleKeyPress,
              placeholder: "0",
            },
          }}
        />
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.UNITPRICE"),
      dataIndex: "price",
      render: (_, record, index) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormInput
            {...{
              type: INPUT_TYPE.CURRENCY_INPUT,
              formItemOptions: {
                name: [index, "price"],
                rules: [
                  {
                    pattern: REGEX.NUMBER,
                    message: t("serialIE.error.serialItems"),
                  },
                ],
              },
              inputOptions: {
                maxLength: 11,
                min: 0,
                style: { width: "110px" },
                className: "form-text-center form-border-radius-right",
                onChange: (e) => handlePrice(index, e),
                onKeyPress: handleKeyPress,
                placeholder: "0",
              },
            }}
          />
          <FormInput
            {...{
              type: INPUT_TYPE.SELECT,
              formItemOptions: {
                name: [index, "currency"],
              },
              inputOptions: {
                options: optionCurrency,
                onChange: (e) => handleCurrency(index, e),
                className: "form-border-radius-left",
              },
            }}
          />
        </div>
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.money"),
      render: (_, __, index) => {
        const currentQuantity = (quantity && quantity[index]) || 0;
        const currentPrice = (price && price[index]) || 0;
        const currentCurrency = currency[index] || CURRENCY_INPUT?.VND;
        const tongthanhtien = currentQuantity && currentPrice ? currentQuantity * currentPrice : 0;
        return currentCurrency === CURRENCY_INPUT?.VND
          ? formatCurrency(tongthanhtien)
          : formatCurrencyDollar(tongthanhtien);
      },
    },
    {
      align: "center",
      title: "VAT",
      render: (_, record, index) => (
        <FormInput
          style={{ width: "50px" }}
          {...{
            type: INPUT_TYPE.CHECK_BOX,
            formItemOptions: {
              name: [index, "isVat"],
              valuePropName: "checked",
            },
            inputOptions: {},
          }}
        />
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.timewarranty"),
      render: (_, record, index) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormInput
            {...{
              type: INPUT_TYPE.NUMBER,
              formItemOptions: {
                name: [index, "warranty"],
                rules: [
                  {
                    pattern: REGEX.NUMBER,
                    message: t("serialIE.error.serialItems"),
                  },
                ],
              },
              inputOptions: {
                maxLength: 3,
                min: 0,
                style: { width: "60px" },
                className: "form-text-center form-border-radius-right",
                onKeyPress: handleKeyPress,
                placeholder: "0",
              },
            }}
          />
          <FormInput
            {...{
              type: INPUT_TYPE.SELECT,
              formItemOptions: {
                name: [index, "warrantyPeriod"],
              },
              inputOptions: {
                options: optionwarrantyPeriod,
                className: "form-border-radius-left",
              },
            }}
          />
        </div>
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.numberofdays"),
      render: (_, record, index) => (
        <FormInput
          {...{
            type: INPUT_TYPE.NUMBER,
            formItemOptions: {
              name: [index, "creditDays"],
              rules: [
                {
                  pattern: REGEX.NUMBER,
                },
              ],
            },
            inputOptions: {
              style: { width: "60px" },
              maxLength: 3,
              min: 0,
              className: "form-text-center",
              onKeyPress: handleKeyPress,
              placeholder: "0",
            },
          }}
        />
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.EstimatedDelivery"),
      render: (_, record, index) => (
        <FormInput
          {...{
            type: INPUT_TYPE.DATE_PICKER,
            formItemOptions: {
              name: [index, "expectedDeliveryTime"],
            },
            inputOptions: {
              format: DATE_TIME_FORMAT2,
              showTime: true,
              placeholder: t("warrantyRequest.create.placeHolder.date"),
            },
          }}
        />
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.COUNTPAYMENT"),
      render: (_, record, index) => (
        <FormInput
          {...{
            type: INPUT_TYPE.TEXT_AREA,
            formItemOptions: {
              name: [index, "paymentNote"],
            },
            inputOptions: {
              placeholder: t("warehouse.create.detail"),
            },
          }}
        />
      ),
    },
    {
      align: "center",
      render: (_, record, index) => (
        <Popconfirm
          title={t("warehouse.create.messageConfirmDelete")}
          cancelText={t("common.cancel")}
          okText={t("serialIE.Comfirm")}
          onConfirm={() => handleDeleteProduct(record.id, index)}
        >
          <SvgIcon.TrashFullCartIcon style={{ fontSize: "16px", color: "#5B6673" }} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className={styles["custom-table"]}>
      <CustomTable
        columns={columns}
        dataSource={dataTableProduct}
        rowKey={(record) => record?.id}
        scroll={{
          x: 900,
        }}
        loading={pendingWarrantyNoteLoading}
      />
    </div>
  );
}

export default TableProduct;
