import { Col, Row, Space, Typography, message } from "antd";
import { SvgIcon } from "assets/icons";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { shippingProviderLabel } from "features/Order/constants";
import {
  isShippingTypeBus,
  isShippingTypeDeliver3Party,
  isTabOrderCancel,
  isTabOrderComplete,
  isTabOrderDelivering,
  isTabOrderWaitingForShippingWaiting,
  renderLabelStatus,
} from "features/Order/helper";
import { t } from "i18next";
import { compact } from "lodash";
import ModalTrackingOrder from "../ModalTrackingOrder";
import "./index.scss";
import DividerCustom from "components/_storybooks/DividerCustom";
import SpaceCustom from "components/_storybooks/SpaceCustom";

const OrderCodeAndOrderStatus = ({ dataOrderCodeAndOrderStatus }) => {
  const {
    number,
    status,
    shipperName,
    shipperTelephone,
    shippingType,
    preOrder,
    shippingStatus,
    orderParcel,
    accountingNumber,
    saleChannel,
  } = dataOrderCodeAndOrderStatus;

  const copyToClipboard = (valueCopy) => {
    if (valueCopy) {
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = valueCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppyOrderPacel"));
    } else {
      message.error(t("serialIE.dontCopy"));
    }
  };

  return (
    <SpaceCustom direction="horizontal" gap={12} className="order-code-and-order-status">
      {/* Mã đơn hàng */}
      <SpaceCustom direction="horizontal" style={{ flexWrap: "wrap" }} gap={8}>
        <div>
          <Typography.Text strong>{t("order.orderDetail.orderCode")}</Typography.Text>
        </div>
        <div>
          <Typography.Text>{number}</Typography.Text>
          {accountingNumber && <Typography.Text>(AMIS: {accountingNumber})</Typography.Text>}
        </div>
      </SpaceCustom>
      <DividerCustom type="vertical" style={{ height: 18, borderColor: "#E4E4EB", margin: "auto 0" }} />
      {/* Loại đơn hàng */}
      <SpaceCustom direction="horizontal" style={{ flexWrap: "wrap" }} gap={8}>
        <Typography.Text strong>{t("order.orderList.orderType")}</Typography.Text>
        <Typography.Text>
          {preOrder ? t("order.approvalOrder.preOrder") : t("order.approvalOrder.regularOrder")}
        </Typography.Text>
      </SpaceCustom>
      <DividerCustom type="vertical" style={{ height: 18, borderColor: "#E4E4EB", margin: "auto 0" }} />
      {/* Kênh bán */}
      <SpaceCustom direction="horizontal" style={{ flexWrap: "wrap" }} gap={8}>
        <Typography.Text strong>{t("order.orderDetail.saleChannel")}</Typography.Text>
        <Typography.Text>{saleChannel?.name}</Typography.Text>
      </SpaceCustom>
      <DividerCustom type="vertical" style={{ height: 18, borderColor: "#E4E4EB", margin: "auto 0" }} />
      {/* Trạng thái đơn hàng */}
      <SpaceCustom direction="horizontal" style={{ flexWrap: "wrap" }} gap={8}>
        <div>
          <Typography.Text strong>{t("order.orderDetail.orderStatus")}</Typography.Text>
        </div>
        <div>
          <div>{renderLabelStatus[status]}</div>
        </div>
        <div>
          <ModalTrackingOrder data={dataOrderCodeAndOrderStatus} />
        </div>
      </SpaceCustom>
      {false && ( // TODO: namtc07 check display
        <>
          <DividerCustom type="vertical" style={{ height: 18, borderColor: "#E4E4EB", margin: "auto 0" }} />
          <SpaceCustom direction="horizontal" style={{ flexWrap: "wrap" }} gap={8}>
            <div>
              <Typography.Text strong>Trạng thái đối soát lợi nhuận</Typography.Text>
            </div>
            <div>Chờ thanh toán</div>
          </SpaceCustom>
        </>
      )}
      {(isTabOrderWaitingForShippingWaiting(status, shippingStatus) ||
        isTabOrderDelivering(status) ||
        isTabOrderComplete(status) ||
        isTabOrderCancel(status)) &&
        isShippingTypeDeliver3Party(shippingType) &&
        orderParcel?.trackingCode?.length > 0 && (
          <>
            <Col span={2}></Col>
            <Col span={7}>
              <div className="info-order-waiting-for-shipping">
                <Row className="row-item">
                  <Col span={7}>
                    <Typography.Text className="title-info">{t("order.codeOrderParcel")}</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Typography.Text className="title-content">{orderParcel?.trackingCode}</Typography.Text>
                  </Col>
                  <Col span={1}></Col>

                  <Col span={4}>
                    <Typography.Text className="icon">
                      <SvgIcon.CopyIcon
                        style={{ fontSize: "30px", cursor: "pointer", marginTop: "6px" }}
                        width="18px"
                        height="18px"
                        onClick={() => copyToClipboard(orderParcel?.trackingCode)}
                      />
                    </Typography.Text>
                  </Col>
                </Row>
                <Row className="row-item">
                  <Col span={7}>
                    <Typography.Text className="title-info">{t("order.statusOrderParcel")}</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <CustomTextHiddenLine
                      className="title-content"
                      text={shippingProviderLabel[orderParcel?.shippingProviderStatus?.toUpperCase()]}
                      line={1}
                    />
                  </Col>
                  <Col span={1}></Col>
                  <Col span={4}>
                    <a href={`${orderParcel?.trackingUrl}`} target="_blank" rel="noreferrer">
                      <Typography.Text className="view-detail">{t("order.orderDetail.seeDetails")}</Typography.Text>
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
          </>
        )}
      {(isTabOrderComplete(status) ||
        isTabOrderDelivering(status) ||
        isTabOrderWaitingForShippingWaiting(status, shippingStatus)) &&
        (isShippingTypeBus(shippingType) || isShippingTypeDeliver3Party(shippingType)) &&
        orderParcel?.trackingCode?.length === 0 &&
        shipperName && (
          <>
            <Col span={3}></Col>
            <Col span={6}>
              <div className="info-order-waiting-for-shipping">
                <Row className="row-item">
                  <Col span={9}>
                    <Typography.Text className="title-info">Shipper&nbsp;</Typography.Text>
                  </Col>
                  <Col span={15}>
                    <Typography.Text className="title-content">
                      {compact([shipperName, shipperTelephone]).join(" | ")}
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
            </Col>
          </>
        )}
    </SpaceCustom>
  );
};

export default OrderCodeAndOrderStatus;
