import { CloseOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Alert, Form, Input, Select, Typography, Upload } from "antd";
import QuillEditor from "components/Editor";
import FormInput from "components/FormInput";
import { INPUT_TYPE, REGEX } from "config/constants";
import Card from "features/Cart/components/Card";
import SizeInput from "features/Products/Form/components/SizeInput";
import WeightInput from "features/Products/Form/components/WeightInput";
import { UNIT_DATE } from "pages/App/Product/constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { dummyRequest } from "utils/helperFuncs";
import { PRODUCT_TYPE } from "../../../../conts";
import styles from "./index.module.scss";
const { Option } = Select;

const titleCard = {
  level: 5,
  style: {
    color: "#052987",
  },
};

const styleAlert = {
  zIndex: 2,
  top: "70px",
  position: "absolute",
  width: "295px",
  padding: "6px 12px 10px 12px",
  background: "rgba(255, 244, 236, 1)",
  border: "1px solid rgba(255, 244, 236, 1)",
  boxShadow: "0px 6px 16px 0px rgba(0, 0, 0, 0.08)",
};

const InfoProduct = ({
  isDisableUpload,
  onFilesChange,
  dataAllCategories,
  dataUom,
  onSearchSelect,
  onSearchTreeSelect,
  dataBrand,
  productType,
  isAlertProductName,
  setIsAlertProductName,
  setIsAlertCategory,
  isAlertCategory,
  isForever,
  disabledInput,
  form,
}) => {
  const { t } = useTranslation();
  const formData = form.getFieldsValue();

  const uploadButton = (
    <div className={styles.uploadButton}>
      <PlusOutlined />
    </div>
  );

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const checkSize = (_, value) => {
    if (value?.length && value?.width && value?.height) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("product.productForm.sizeNotBlank")));
  };

  const validateWeight = (_, value) => {
    if (value?.weight && value?.unit) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("product.productForm.weightNotBlank")));
  };

  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(dataBrand);

  const handleSearch = (value) => {
    setSearchValue(value);
    // Lọc danh sách tùy chọn dựa trên giá trị tìm kiếm
    const filteredOptions = dataBrand.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()));
    setFilteredOptions(filteredOptions);
  };

  const renderBrandOptions = () => {
    return filteredOptions?.map((brand) => (
      <Option key={brand.value} value={brand.value} disabled={!brand.isActive}>
        {brand.label}
      </Option>
    ));
  };

  return (
    <Card title={<Typography.Title {...titleCard}>{t("product.productForm.title")}</Typography.Title>}>
      <div className={styles.container}>
        <div className={styles["upload-image"]}>
          <Form.Item
            getValueFromEvent={getFile}
            name="imageURLs"
            valuePropName="fileList"
            label={<span className={styles.title}>{t("product.productForm.imageProduct")}</span>}
          >
            <Upload
              listType="picture-card"
              customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
              className={styles.upload}
              disabled={disabledInput}
            >
              {isDisableUpload ? null : uploadButton}
            </Upload>
          </Form.Item>
        </div>
        <div className={styles["info-product"]}>
          <FormInput
            formItemOptions={{
              label: <span className={styles.title}>{t("product.productForm.productCode")}</span>,
              className: styles["product-code"],
              rules: [
                {
                  required: true,
                  message: t("product.productForm.blankProductCode"),
                },
                {
                  pattern: REGEX.CODE,
                  message: t("product.productForm.formatProductCode"),
                },
                {
                  max: 50,
                  message: t("product.productForm.maximumProductCode"),
                },
              ],
              name: "code",
            }}
            inputOptions={{
              placeholder: t("product.productForm.placeholderProductCode"),
            }}
          />

          <div style={{ position: "relative" }}>
            <FormInput
              formItemOptions={{
                label: (
                  <>
                    <Typography.Text strong style={{ width: "100%" }}>
                      {t("product.productForm.productName")}
                    </Typography.Text>
                    {productType === PRODUCT_TYPE.KEY_LICENSE && (
                      <QuestionCircleOutlined
                        style={{ color: "rgba(5, 41, 135, 1)" }}
                        onClick={() => setIsAlertProductName(!isAlertProductName)}
                      />
                    )}
                  </>
                ),
                className: "label-full-width",
                rules: [
                  {
                    required: true,
                    message: t("product.productForm.blankProductName"),
                  },

                  {
                    max: 255,
                    message: t("product.productForm.maximumProductName"),
                  },
                ],
                name: "name",
              }}
              inputOptions={{
                placeholder: t("product.productForm.placeholderProductName"),
              }}
            />
            {isAlertProductName && (
              <Alert
                description={
                  <Typography.Text style={{ color: "rgba(239, 147, 81, 1)" }}>
                    Với sản phẩm key license/ account, vui lòng đặt tên theo cú pháp: “[Mã online] tên sản phẩm"
                  </Typography.Text>
                }
                closable
                closeText={<CloseOutlined style={{ color: "rgba(239, 147, 81, 1)" }} />}
                onClose={() => setIsAlertProductName(!isAlertProductName)}
                style={styleAlert}
              />
            )}
          </div>

          <FormInput
            type={INPUT_TYPE.SELECT}
            formItemOptions={{
              label: <span className={styles.title}>{t("product.productForm.brand")}</span>,
              className: styles["brand"],
              rules: [
                {
                  required: true,
                  message: t("product.productForm.blankBrand"),
                },
              ],
              name: "brandID",
            }}
            inputOptions={{
              showSearch: true,
              placeholder: t("product.productForm.placeholderBrand"),
              options: dataBrand,
              filterOption: (search, option) => onSearchSelect(search, option),
              disabled: disabledInput,
            }}
          />
          <Form.Item
            label={<span className={styles.title}>{t("product.productForm.brand")}</span>}
            className={styles.brand}
            rules={[
              {
                required: true,
                message: t("product.productForm.blankBrand"),
              },
            ]}
            name="brandID"
          >
            <Select
              showSearch
              placeholder={t("product.productForm.placeholderBrand")}
              onSearch={handleSearch}
              filterOption={false}
              value={searchValue}
              disabled={disabledInput}
            >
              {renderBrandOptions()}
            </Select>
          </Form.Item>

          <div style={{ position: "relative" }}>
            <FormInput
              type={INPUT_TYPE.TREE_SELECT}
              formItemOptions={{
                placeholder: t("product.productForm.placeholderCategory"),
                label: (
                  <>
                    <Typography.Text strong style={{ width: "100%" }}>
                      {t("product.productForm.category")}
                    </Typography.Text>
                    <QuestionCircleOutlined
                      style={{ color: "rgba(5, 41, 135, 1)" }}
                      onClick={() => setIsAlertCategory(!isAlertCategory)}
                    />
                  </>
                ),
                className: "label-full-width",
                rules: [
                  {
                    required: true,
                    message: t("product.productForm.blankCategory"),
                  },
                ],
                name: "categoryID",
                // extra: <i>{t("product.productForm.extraCategory")}</i>,
              }}
              inputOptions={{
                placeholder: t("product.productForm.placeholderCategory"),
                treeData: dataAllCategories,
                showSearch: true,
                filterTreeNode: (search, item) => onSearchTreeSelect(search, item),
              }}
            />
            {isAlertCategory && (
              <Alert
                description={
                  <Typography.Text style={{ color: "rgba(239, 147, 81, 1)" }}>
                    {t("product.productForm.extraCategory")}
                  </Typography.Text>
                }
                closable
                closeText={<CloseOutlined style={{ color: "rgba(239, 147, 81, 1)" }} />}
                onClose={() => setIsAlertCategory(!isAlertCategory)}
                style={styleAlert}
              />
            )}
          </div>
          <Form.Item style={{ marginBottom: 0 }}>
            <Input.Group compact style={{ display: "flex" }}>
              <FormInput
                type={INPUT_TYPE.NUMBER}
                formItemOptions={{
                  label: <span className={styles.title}>{t("product.productForm.warrantyPeriod")}</span>,
                  className: styles["warranty"],
                  rules: [
                    {
                      required: true,
                      message: t("product.productForm.blankWarrantyPeriod"),
                    },
                  ],
                  name: "warranty",
                }}
                inputOptions={{
                  placeholder: t("product.productForm.warrantyPlaceholder"),
                  maxLength: 3,
                }}
              />
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  name: "warrantyUnit",
                  label: " ",
                  style: { marginLeft: 8 },
                }}
                inputOptions={{
                  options: [
                    {
                      value: "DAY",
                      label: "ngày",
                    },
                    {
                      value: "MONTH",
                      label: "tháng",
                    },
                    {
                      value: "YEAR",
                      label: "năm",
                    },
                  ],
                }}
              />
            </Input.Group>
          </Form.Item>

          <FormInput
            type={INPUT_TYPE.SELECT}
            formItemOptions={{
              label: <span className={styles.title}>{t("product.productForm.unit")}</span>,
              name: "uomID",
              className: styles["uom"],
              rules: [
                {
                  required: true,
                  message: t("product.productForm.blankUnit"),
                },
              ],
            }}
            inputOptions={{
              showSearch: true,
              placeholder: t("product.productForm.placeholderUnit"),
              options: dataUom,
              filterOption: (search, option) => onSearchSelect(search, option),
              disabled: disabledInput,
            }}
          />

          {productType === PRODUCT_TYPE.KEY_LICENSE ? (
            <Form.Item>
              <Input.Group compact style={{ display: "flex" }}>
                <FormInput
                  type={INPUT_TYPE.NUMBER}
                  formItemOptions={{
                    label: <span className={styles.title}>{"Thời hạn bản quyền"}</span>,
                    className: styles["warranty"],
                    rules: [
                      {
                        required: !(formData?.copyrightUnit === UNIT_DATE.FOREVER),
                        message: "Vui lòng nhập thời hạn bản quyền",
                      },
                    ],
                    name: "copyrightTerm",
                    style: { marginBottom: "0px" },
                  }}
                  inputOptions={{
                    placeholder: t("product.productForm.warrantyPlaceholder"),
                    maxLength: 3,
                    disabled: isForever,
                  }}
                />
                <FormInput
                  type={INPUT_TYPE.SELECT}
                  formItemOptions={{
                    name: "copyrightUnit",
                    label: " ",
                    style: { marginLeft: 8, marginBottom: 0 },
                  }}
                  inputOptions={{
                    options: [
                      {
                        value: "DAY",
                        label: "ngày",
                      },
                      {
                        value: "MONTH",
                        label: "tháng",
                      },
                      {
                        value: "YEAR",
                        label: "năm",
                      },
                      {
                        value: "FOREVER",
                        label: "Vĩnh viễn",
                      },
                    ],
                  }}
                />
              </Input.Group>
            </Form.Item>
          ) : (
            <Form.Item
              className={styles["product-size"]}
              label={<span className={styles.title}>{t("product.productForm.sizeLabel")}</span>}
              name="dimension"
              required
              rules={[
                {
                  validator: checkSize,
                },
              ]}
            >
              <SizeInput />
            </Form.Item>
          )}

          {productType === PRODUCT_TYPE.KEY_LICENSE ? (
            <FormInput
              type={INPUT_TYPE.SELECT}
              formItemOptions={{
                label: <span className={styles.title}>{"Loại mã online"}</span>,
                name: "productType",
                rules: [
                  {
                    required: true,
                    message: "Vui lòng chọn loại mã online",
                  },
                ],
              }}
              inputOptions={{
                showSearch: true,
                placeholder: "Chọn loại mã online",
                options: [
                  {
                    label: "Key license",
                    value: "KEY_LICENSE",
                  },
                  {
                    label: "Account",
                    value: "ACCOUNT",
                  },
                ],
              }}
            />
          ) : (
            <Form.Item
              className={styles["product-weight"]}
              label={<span className={styles.title}>{t("product.productForm.weightLabel")}</span>}
              name="weight"
              required
              rules={[
                {
                  validator: validateWeight,
                },
              ]}
            >
              <WeightInput />
            </Form.Item>
          )}
          <div className={styles.editor}>
            <Form.Item
              label={<span className={styles.title}>{t("product.productForm.description")}</span>}
              name="description"
            >
              <QuillEditor onFilesChange={onFilesChange} disabledInput={disabledInput} />
            </Form.Item>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default InfoProduct;
