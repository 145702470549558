import React from 'react';
import { Col, Row, Typography } from 'antd';
import { INPUT_TYPE } from 'config/constants';
import FormInput from 'components/FormInput';
import { t } from 'i18next';
import { optionPaymentRequest, optionPaymentTerms } from 'features/Warehouse/constant';

function PaymentInfo() {
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Typography.Text className="sub-title">{t('warehouse.create.Payment')}</Typography.Text>
          </Col>
          <Col span={24} className="form-tags">
            <FormInput
              type={INPUT_TYPE.SELECT}
              {...{
                formItemOptions: {
                  name: 'paymentMethods',
                },
                inputOptions: {
                  placeholder: t('warehouse.create.ChoosePayment'),
                  mode: 'multiple',
                  allowClear: true,
                  showSearch: true,
                  filterOption: false,
                  optionFilterProp: 'label',
                  placement: 'topLeft',
                  options: optionPaymentRequest,
                },
              }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Typography.Text className="sub-title">{t('warehouse.create.Rules')}</Typography.Text>
          </Col>
          <Col span={24} className="form-tags">
            <FormInput
              type={INPUT_TYPE.SELECT}
              {...{
                formItemOptions: {
                  name: 'paymentTerms',
                },
                inputOptions: {
                  placeholder: t('warehouse.create.ChooseRules'),
                  mode: 'multiple',
                  allowClear: true,
                  showSearch: true,
                  filterOption: false,
                  optionFilterProp: 'label',
                  placement: 'topLeft',
                  options: optionPaymentTerms,
                },
              }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Typography.Text className="sub-title">
              {t('warehouse.create.transfercontent')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <FormInput
              type={INPUT_TYPE.TEXT_AREA}
              {...{
                formItemOptions: {
                  name: 'paymentNotes',
                },
                inputOptions: {
                  placeholder: t('warehouse.create.Entertransfercontent'),
                },
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PaymentInfo;
