import CustomTable from "components/CustomTable";
import { Alert, Card, Checkbox, Col, Row, Typography } from "antd";
import { TitleCard } from "../TitleCard";
import { t } from "i18next";
import { formatCurrency, formatCurrencyDollar } from "utils/helperFuncs";
import { formatDateTime } from "utils/dateTime";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { RECOMMEND_IMPORT } from "features/Warehouse/constant";
import { convertUnit } from "hooks/warranty/converter";
import { Link } from "react-router-dom";

function TableProductList({ selectedRow, GET_QUERY }) {
  const columns = [
    {
      align: "center",
      title: "STT",
      render: (_, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      title: t("warehouse.recommendImport.PARAMETER"),
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Link style={{ textAlign: "start", margin: 0 }} strong>
            <Link to={`/product/detail/${record?.product?.id}`}>
              <Typography.Link strong>{record?.product?.code}</Typography.Link>
            </Link>
          </Typography.Link>
          <Typography.Text style={{ textAlign: "start", margin: 0 }}>
            {record?.product?.name || record?.productName}
          </Typography.Text>
        </div>
      ),
      width: 280,
      fixed: "left",
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.quantity"),
      render: (_, record) => record?.quantity,
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.UNITPRICE"),
      render: (_, record) =>
        record?.currency === "VND" ? formatCurrency(record?.price) : formatCurrencyDollar(record?.price),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.money"),
      render: (_, record) =>
        record?.currency === "VND"
          ? formatCurrency(record?.quantity * record?.price)
          : formatCurrencyDollar(record?.quantity * record?.price),
    },
    {
      align: "center",
      title: "VAT",
      render: (_, record) => <Checkbox checked={record?.isVat} />,
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.timewarranty"),
      render: (_, record) => (
        <div>
          {record?.warranty} {convertUnit(record?.warrantyPeriod)}
        </div>
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.numberofdays"),
      render: (_, record) => record?.creditDays,
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.EstimatedDelivery"),
      render: (_, record, index) => (
        <Row justify="center">
          <Col style={{ width: "100px" }}>
            {record?.expectedDeliveryTime ? formatDateTime(record?.expectedDeliveryTime) : "- -"}
          </Col>
        </Row>
      ),
    },
    {
      align: "center",
      title: t("warehouse.recommendImport.COUNTPAYMENT"),
      render: (_, record, index) => <span>{record?.paymentNote || "- -"}</span>,
    },
  ];
  const isRejected = GET_QUERY?.statuses?.join("");

  let sumPrice = 0;
  for (let i = 0; i < selectedRow?.items?.length; i++) {
    const { quantity, price } = selectedRow?.items[i];
    sumPrice += quantity * price;
  }

  return (
    <div className="header-card-table-list-product">
      <Col style={{ marginTop: "16px" }} span={24}>
        <Card
          title={
            <div style={{ display: "flex" }}>
              <TitleCard title={t("warehouse.recommendImport.listproductby")} />
              <Typography.Link strong>{selectedRow.code}</Typography.Link>
            </div>
          }
        >
          <Row gutter={[16, 8]}>
            <Col span={24}>
              {isRejected === RECOMMEND_IMPORT?.REJECTED && (
                <Alert
                  message={
                    <Row>
                      <Typography.Text strong>
                        <Col>{t("warehouse.recommendImport.reasonImportReques")}: </Col>
                      </Typography.Text>
                      <Col>
                        <Typography.Text style={{ marginLeft: "4px" }}>{selectedRow?.rejectedReason}</Typography.Text>
                      </Col>
                    </Row>
                  }
                  icon={<ExclamationCircleOutlined />}
                  type="error"
                  showIcon
                  style={{ width: "100%", marginBottom: "10px" }}
                />
              )}

              <CustomTable columns={columns} dataSource={selectedRow?.items} />
              <div style={{ marginTop: "10px" }}>
                <Typography.Link strong>
                  {t("warehouse.recommendImport.sumByMoney")}:{" "}
                  {selectedRow?.items && selectedRow?.items[0]?.currency === "VND"
                    ? formatCurrency(sumPrice)
                    : formatCurrencyDollar(sumPrice)}
                </Typography.Link>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </div>
  );
}

export default TableProductList;
