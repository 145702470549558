import React from 'react';
import { Col, Row, Tag, Typography } from 'antd';
import { t } from 'i18next';
import { paymentRequest, paymentTerms } from 'features/Warehouse/constant';

function PaymentInfo({ data }) {
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <Typography.Text className="sub-title">{t('warehouse.create.Payment')}</Typography.Text>
          </Col>
          <Col span={24}>
            {data?.paymentMethods?.map((item) => (
              <Tag>{paymentRequest[item]}</Tag>
            ))}
          </Col>
        </Row>
        <Row gutter={[16, 8]} style={{ marginTop: '10px' }}>
          <Col span={24}>
            <Typography.Text className="sub-title">{t('warehouse.create.Rules')}</Typography.Text>
          </Col>
          <Col span={24}>
            {data?.paymentTerms?.map((item) => (
              <Tag>{paymentTerms[item]}</Tag>
            ))}
          </Col>
        </Row>
        <Row gutter={[16, 8]} style={{ marginTop: '10px' }}>
          <Col span={24}>
            <Typography.Text className="sub-title">
              {t('warehouse.create.transfercontent')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text>{data?.paymentNotes || '---'}</Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PaymentInfo;
