import { Checkbox, ConfigProvider, DatePicker, Form, Space, Spin, Typography, message } from "antd";
import viVN from "antd/es/locale/vi_VN";
import FormInput from "components/FormInput";
import { DATE_TIME_FORMAT3 } from "config/constants";
import { useSetDeliveryDeadline } from "hooks/order";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { parseIsoDateStringToMoment } from "utils/helperFuncs";

function ListReasonApproveOrder({ loadingApprove, orderID, chatURL, preOrder, deliveryDeadline, form }) {
  const [timeSelected, setTimeSelected] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isDatePickerChanging, setIsDatePickerChanging] = useState(false);

  const { handleSetDeliveryDeadline, loading } = useSetDeliveryDeadline();

  const renderCheckbox = (value, label, isDisabled) => {
    return (
      <Checkbox value={value} disabled={isDisabled}>
        {label}
      </Checkbox>
    );
  };
  const renderReason = (label, value, isDisabled = false) => (
    <div>
      <li style={{ marginLeft: 20, fontWeight: "bold", paddingBottom: 4 }}>{label}</li>
      {renderCheckbox(
        value,
        value === 2 ? (
          <Typography>
            {t("order.approvalOrder.reason2_1")}
            <Typography.Text type="warning">{t("order.approvalOrder.reason2_2")}</Typography.Text>.{" "}
            {t("order.approvalOrder.reason2_3")}
          </Typography>
        ) : (
          t(`order.approvalOrder.reason${value}`)
        ),
        isDisabled
      )}
    </div>
  );

  const fnSetDeliveryDeadline = async (deadlineValue) => {
    try {
      await handleSetDeliveryDeadline({
        orderID: orderID,
        input: { deadline: parseIsoDateStringToMoment(deadlineValue) },
      })
        .then(() => {
          setTimeSelected(deadlineValue);
          message.success(t("order.approvalOrder.successChangeTime"));
        })
        .catch((error) => {
          message.error(error.message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onChange = (value) => {
    if (isDatePickerChanging) {
      fnSetDeliveryDeadline(value);
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const handleChangeClick = (e) => {
    e.preventDefault();
    setShowDatePicker(true);
    setIsDatePickerChanging(true);
  };

  const handleOpenChange = (open) => {
    if (!open) {
      setIsDatePickerChanging(false);
    }
    setShowDatePicker(open);
  };

  const locale = {
    ...viVN,
    DatePicker: {
      ...viVN.DatePicker,
      lang: {
        ...viVN.DatePicker.lang,
        ok: t("order.approvalOrder.confirmChangeDeliveryDeadline"),
      },
    },
  };

  const renderWarningMessage = (preOrder, deliveryDeadline) => (
    <>
      {renderReason(t("order.approvalOrder.approvePrice"), 3)}
      {renderReason(t("order.approvalOrder.approveStock"), preOrder ? 2 : 1, !preOrder)}
      {deliveryDeadline && (
        <div>
          <li style={{ marginLeft: 20, fontWeight: "bold", paddingBottom: 4 }}>
            {t("order.approvalOrder.approvalDeliDeadline")}
          </li>
          {renderCheckbox(
            4,
            <>
              <Typography.Text>
                {t("order.approvalOrder.deliveryDeadline")}:{" "}
                <Typography.Text type="warning">
                  {(timeSelected && formatDateTime(timeSelected, DATE_TIME_FORMAT3)) ||
                    (deliveryDeadline && formatDateTime(deliveryDeadline, DATE_TIME_FORMAT3)) ||
                    "#"}
                </Typography.Text>
                .
                <Typography.Link style={{ position: "relative" }} onClick={handleChangeClick}>
                  {" "}
                  {t("order.approvalOrder.change")}
                  {showDatePicker && (
                    <div style={{ position: "absolute", opacity: 0 }}>
                      <ConfigProvider locale={locale}>
                        <DatePicker
                          disabledDate={disabledDate}
                          open={showDatePicker}
                          showNow={false}
                          showTime={{
                            format: "HH:mm",
                          }}
                          onOpenChange={handleOpenChange}
                          onChange={onChange}
                          style={{ width: 0, height: 0 }}
                        />
                      </ConfigProvider>
                    </div>
                  )}
                </Typography.Link>
              </Typography.Text>
              <br />
              <Typography.Text>{t("order.approvalOrder.confirmDelivery")}</Typography.Text>
            </>
          )}
        </div>
      )}
      <Space style={{ padding: "8px 0" }}>
        <Typography.Text>{t("order.approvalOrder.negotiate")}:</Typography.Text>
        <Typography.Link href={chatURL} target="_blank">
          {t("order.approvalOrder.contactViaChatwoot")}
        </Typography.Link>
      </Space>
    </>
  );

  useEffect(() => {
    const uniqueArray = (array) => Array.from(new Set(array.filter((item) => typeof item === "number")));

    form.setFieldsValue({
      options: uniqueArray([...form.getFieldsValue().options, !preOrder && 1, timeSelected && 4]),
    });
  }, [timeSelected]);

  return (
    <Spin spinning={loading || loadingApprove}>
      <Form form={form} layout="vertical" initialValues={{ options: [1] }}>
        <FormInput
          type="CHECK_BOX_GROUP"
          formItemOptions={{
            name: "options",
            validateTrigger: [],
            label: <Typography>{t("order.approvalOrder.warningMessage")}</Typography>,
            rules: [
              {
                required: true,
                message: "",
              },

              {
                validator: (rule, value) => {
                  const requiredCount = deliveryDeadline ? 3 : 2;
                  return value.length >= requiredCount
                    ? Promise.resolve()
                    : Promise.reject(new Error(t("order.approvalOrder.warningMessage2", { count: requiredCount })));
                },
              },
            ],
            style: { margin: 0 },
          }}
          inputChildren={
            <ol style={{ marginBottom: 0 }}>
              <Space direction="vertical">{renderWarningMessage(preOrder, deliveryDeadline)}</Space>
            </ol>
          }
        />
      </Form>
    </Spin>
  );
}

export default ListReasonApproveOrder;
