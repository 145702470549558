/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "antd";
import { SvgIcon } from "assets/icons";
import { FilterLoadMore } from "components/CustomFilterMenu/FilterLoadMore";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import TextAlignLeft from "components/TextAlignLeft";
import PopupDetailSerial from "features/Serial/components/PopupDetailSerial";
import { useGetBranchQuery } from "hooks/branch";
import { useGetListSerial } from "hooks/serial/query";
import useDebounce from "hooks/useDebounce";
import { useGetUserBranch } from "hooks/user/user";
import { useWarehouse } from "hooks/warehouse";
import { useGetStaff } from "hooks/warranty";
import { t } from "i18next";
import { isNil, omit } from "lodash";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import FilterMenu from "../../../components/FilterMenu";
import { IMPORT_TYPE, SORT_TYPES, VALUE_TAGS_IMPORT_NOTE_TYPE, typeImport, warrantyUnitLabel } from "../../../constant";

const GET_QUERY = getQuery();
const useList = () => {
  const { data: valueFormSelectCity } = useGetBranchQuery();
  const { branchIdByUser } = useGetUserBranch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const branchIDsParam = searchParams.get("branchIDs");
  const [branchIDs, setBranchIDs] = useState("");

  const onChangeBranchIDs = (e) => {
    setBranchIDs(e);
    const newParams = {
      ...paramsListBySerial,
      filters: {
        ...paramsListBySerial.filters,
        branchIDs: e,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParamsListBySerial(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: paramsListBySerial?.activeKey,
        key: paramsListBySerial?.key,
        branchIDs: newParams?.filters?.branchIDs,
      },
    });
  };

  const [paramsListBySerial, setParamsListBySerial] = useState();
  const { data, paginationData, loading } = useGetListSerial(paramsListBySerial);
  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(paramsStaff);
  const { warehouses } = useWarehouse(paramsStaff);

  const warehouseOptions = warehouses?.map(({ id, name }) => ({ value: id, label: name }));

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };
  const handleFilter = (value) => {
    const newParams = {
      ...paramsListBySerial,
      filters: {
        ...paramsListBySerial.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParamsListBySerial(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: paramsListBySerial?.activeKey,
        key: paramsListBySerial?.key,
      },
    });
  };

  const checkNullValue = (value) => {
    if (!value || value === null || value === "" || value === undefined) {
      return "- -";
    } else {
      return value;
    }
  };
  const handleSort = (by) => {
    if (paramsListBySerial.sort[0]?.sortType === "ASC") {
      const newParams = {
        ...paramsListBySerial,
        sort: [{ by: by, sortType: "DESC" }],
      };
      setParamsListBySerial(newParams);
    } else {
      const newParams = {
        ...paramsListBySerial,
        sort: [{ by: by, sortType: "ASC" }],
      };
      setParamsListBySerial(newParams);
    }
  };
  const columns = [
    {
      title: t("serialIE.stt"),
      render: (_, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      title: (
        <Typography.Text className="title-table" onClick={() => handleSort("CREATED_AT")}>
          {t("serialIE.dateImportSerial")}
          <SvgIcon.SortIcon className={paramsListBySerial?.sort[0]?.sortType} />
        </Typography.Text>
      ),
      width: 150,
      fixed: "left",
      render: (_, record) => checkNullValue(formatDateTime(record?.serialImportNoteItem?.serialImportNote?.createdAt)),
    },
    {
      fixed: "left",
      title: t("serialIE.NUMBER_SERIAL_IMEI"),
      render: (_, record) => <PopupDetailSerial button={record?.serial?.code} id={record?.serialID} />,
    },
    {
      fixed: "left",
      title: t("serialIE.productInfo.productCodeB"),
      render: (_, record) => <TextAlignLeft value={checkNullValue(<ProductLink product={record?.serial} />)} />,
    },
    {
      title: t("serialIE.productInfo.productNameB"),
      render: (_, record) => checkNullValue(record?.serial?.product?.name),
    },
    {
      title: t("serialIE.SERIAL_ENTER_CODE_CODE"),
      render: (_, record) =>
        checkNullValue(
          <Link to={`/import-serial/detail/${record?.serialImportNoteItem?.serialImportNote?.id}`}>
            <Typography.Link>{record?.serialImportNoteItem?.serialImportNote?.code}</Typography.Link>
          </Link>
        ),
    },
    {
      title: t("serialIE.WARRANTY_OF_NCC"),
      render: (_, record) => {
        const serialImportNoteItem = record?.serialImportNoteItem;
        return `${
          serialImportNoteItem?.serialImportNote?.type === IMPORT_TYPE.FIRST_TIME_IMPORT
            ? serialImportNoteItem?.warrantyPeriodProvider || "- -"
            : serialImportNoteItem?.warrantyOnSale || "- -"
        } ${warrantyUnitLabel?.[serialImportNoteItem?.warrantyUnit]}`;
      },
    },
    {
      title: t("serialIE.SUPPLIER_AGENT"),
      render: (_, record) =>
        checkNullValue(
          record?.serialImportNoteItem?.serialImportNote?.provider?.fullName ||
            record?.serialImportNoteItem?.serialImportNote?.seller?.fullName
        ),
    },
    {
      title: t("serialIE.ENTERPRISE_SERIAL"),
      render: (_, record) => checkNullValue(record?.warehouse?.name),
      filterDropdown: (
        <FilterMenu
          onLoadMoreData={onLoadMoreData}
          params={paramsListBySerial}
          onFilter={(value) => handleFilter({ warehouseIDs: value })}
          type="checkbox"
          options={warehouseOptions}
        />
      ),
    },
    {
      title: t("serialIE.staffInCharge"),
      render: (_, record) => checkNullValue(record?.serialImportNoteItem?.serialImportNote?.createdBy?.fullname),
      filterDropdown: (
        <FilterLoadMore
          params={paramsListBySerial}
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          onFilter={(value) => handleFilter({ createdByIDs: value })}
          onSearchTextChange={handleSearchStaffs}
          hasMore={hasMoreData}
        />
      ),
    },
    {
      title: t("serialIE.operation"),
      fixed: "right",
      filterDropdown: (
        <FilterMenu
          params={paramsListBySerial}
          onFilter={(value) => handleFilter({ serialImportNoteType: value })}
          type="checkbox"
          options={VALUE_TAGS_IMPORT_NOTE_TYPE}
        />
      ),
      render: (_, record, index) => typeImport?.[record?.serialImportNoteItem?.serialImportNote?.type],
    },
  ];

  const [searchTerm, setSearchTerm] = useState(GET_QUERY.query);
  const debouncedValue = useDebounce(searchTerm);

  const onSearchBySerial = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleRemoveFilterTag = (key) => {
    setParamsListBySerial({
      ...paramsListBySerial,
      filters: omit({ ...paramsListBySerial?.filters }, [`${key}`]),
      pagination: { offset: 0, limit: 10 },
      sort: paramsListBySerial.sort || [],
    });
    buildQueryString({
      params: {
        ...omit({ ...paramsListBySerial?.filters }, [`${key}`]),
        ...paramsListBySerial?.pagination,
        activeKey: paramsListBySerial.activeKey,
        key: paramsListBySerial.key,
      },
    });
  };
  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...paramsListBySerial,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      filters: {
        ...paramsListBySerial.filters,
      },
      sort: sorter?.order ? [{ by: "ID", sortType: SORT_TYPES[`${sorter?.order}`] }] : [],
    };
    setParamsListBySerial(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: paramsListBySerial.activeKey,
        key: paramsListBySerial.key,
        // branchIDs: newParams?.branchIDs,
      },
    });
  };

  const handlePrevNextPage = (offset) => {
    const newParams = {
      ...paramsListBySerial,
      pagination: {
        offset: offset,
        limit: 10,
      },
      filters: {
        ...paramsListBySerial.filters,
      },
    };
    setParamsListBySerial(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: paramsListBySerial.activeKey,
        key: paramsListBySerial.key,
        // branchIDs: newParams?.branchIDs,
      },
    });
  };
  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...paramsListBySerial,
        filters: { ...paramsListBySerial?.filters, query: debouncedValue },
        pagination: {
          offset: 0,
          limit: 10,
        },
      };
      setParamsListBySerial(newParams);
      buildQueryString({
        params: {
          ...newParams?.filters,
          ...newParams?.pagination,
          activeKey: "listBySerial",
          key: "listBySerial",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    setParamsListBySerial(() => {
      return {
        filters: {
          query: "",
          createdByIDs: [],
          serialImportNoteType: [],
          branchIDs: branchIdByUser,
        },
        pagination: {
          offset: 0,
          limit: 10,
        },
        sort: [
          {
            by: "ID",
            sortType: "DESC",
          },
        ],
      };
    });
    buildQueryString({
      params: { activeKey: "listBySerial" },
    });
  }, [branchIdByUser]);

  useEffect(() => {
    setBranchIDs(branchIDsParam || branchIdByUser);
  }, [branchIDsParam, branchIdByUser]);

  useEffect(() => {
    buildQueryString({
      params: {
        activeKey: "listBySerial",
        key: "listBySerial",
      },
    });
  }, []);
  return {
    valueFormSelectCity,
    columns,
    loading,
    paramsListBySerial,
    paramsStaff,
    paginationData,
    data,
    onSearchBySerial,
    handleChangeTable,
    onChangeBranchIDs,
    branchIDs,
    staffs,
    handleRemoveFilterTag,
    GET_QUERY,
    warehouseOptions,
    handlePrevNextPage,
  };
};

export default useList;
