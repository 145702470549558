import { Col, List, Row, Typography } from "antd";
import CustomModal from "components/CustomModal";
import { PAYMENT_METHOD } from "config/constants";
import { COMPANY_TYPE, convertPaymentMethod, convertTypeAgent } from "features/Agent/constants";
import { useSellerPermissions } from "hooks/seller";
import { useSupplierPermissions } from "hooks/supplier";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils/helperFuncs";
import Img from "../../../../../assets/images";
import CreateButton from "../../../../../components/CreateButton";
import CategoriesList from "../CategoriesList";

const Info = ({ dataDetail, refetch, isRedirectSeller, isSupplier }) => {
  const { canUpdate: canUpdateSeller } = useSellerPermissions();
  const { canUpdateSupplier } = useSupplierPermissions();

  const canUpdate = isSupplier ? canUpdateSupplier : canUpdateSeller;

  const fullAddress = `${dataDetail?.address ? dataDetail?.address + "," : ""} ${dataDetail?.ward?.name}, ${
    dataDetail?.district?.name
  }, ${dataDetail?.city?.name}`;

  return (
    <div className="wrapper-info">
      <Row gutter={[16, 16]} className="padding hr" justify={"space-between"} align={"middle"}>
        <Col span={16}>
          <Row gutter={[16, 16]}>
            <Col>
              <span className="title">{dataDetail?.fullName || "--"}</span>
            </Col>
            <Col>
              {dataDetail?.isActive || dataDetail?.isActiveSupplier ? (
                <span className="status-true">{t("accountant.cooperate")}</span>
              ) : (
                <span className="status-false">{t("accountant.stopCooperate")}</span>
              )}
            </Col>
          </Row>
        </Col>
        {canUpdate && (
          <Col>
            <CreateButton
              onClick={() => refetch()}
              title={
                <Link
                  to={
                    dataDetail?.isSupplier ? `/supplier/update/${dataDetail?.id}` : `/seller/update/${dataDetail?.id}`
                  }
                >
                  {t("order.orderDetail.editBtn")}
                </Link>
              }
            />
          </Col>
        )}
      </Row>

      <Row gutter={[16, 16]} style={{ padding: "12px" }}>
        <Col span={24} xl={4}>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img width={100} height={100} alt="" src={dataDetail?.logoUrl || Img.AvatarPlaceHolder} />
          </div>
        </Col>
        <Col span={24} xl={8}>
          {dataDetail?.isSeller ? (
            <Row gutter={[16, 8]}>
              {dataDetail?.idNumber && (
                <>
                  <Col span={8}>
                    <Typography.Text strong>{t("seller.labelInput.numberPerson")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text>{dataDetail?.idNumber || "--"}</Typography.Text>
                    {dataDetail?.type === COMPANY_TYPE.PERSONAL && (
                      <span style={{ color: "#2246DC" }}>
                        <span>
                          {" "}
                          {dataDetail?.vatInfo?.defaultExportVat ? "(Ưu tiên Xuất VAT" : "(Ưu tiên Không xuất VAT"}
                        </span>
                        <span>{dataDetail?.vatInfo?.allowEditVat ? " và được phép tùy chỉnh VAT)" : ")"}</span>
                      </span>
                    )}
                  </Col>
                </>
              )}

              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.taxIDNumber")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{dataDetail?.vatInfo?.taxIDNumber || "--"}</Typography.Text>
                {dataDetail?.type === COMPANY_TYPE.COMPANY && (
                  <span style={{ color: "#2246DC" }}>
                    <span>
                      {dataDetail?.vatInfo?.defaultExportVat ? "(Ưu tiên Xuất VAT" : "(Ưu tiên Không xuất VAT"}
                    </span>
                    <span>{dataDetail?.vatInfo?.allowEditVat ? " và được phép tùy chỉnh VAT)" : ")"}</span>
                  </span>
                )}
              </Col>
              {dataDetail?.vatInfo?.address && (
                <>
                  <Col span={8}>
                    <Typography.Text strong>{t("seller.labelInput.companyAddress")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text>{dataDetail?.vatInfo?.address || "--"}</Typography.Text>
                  </Col>
                </>
              )}
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.email")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{dataDetail?.email || "--"}</Typography.Text>
              </Col>

              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.contactPhone")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{dataDetail?.telephone || "--"}</Typography.Text>
              </Col>

              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.sellerGroup")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Link to={`/seller-group/detail/${dataDetail?.sellerGroup?.id}`}>
                  {dataDetail?.sellerGroup?.name || "--"}
                </Link>
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.address")}</Typography.Text>
              </Col>

              <Col span={16}>
                <Typography.Text>{fullAddress || "--"}</Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.typeSupplier")}</Typography.Text>
              </Col>

              <Col span={16}>
                <Typography.Text>
                  {convertTypeAgent(dataDetail?.type)}
                  {dataDetail?.isSupplier && (
                    <Typography.Text type="warning" italic>
                      {" "}
                      ({t("supplier.isCustomer")})
                    </Typography.Text>
                  )}
                </Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.loginAccount")}</Typography.Text>
              </Col>
              <Col span={16}>
                {dataDetail?.users?.length > 1 ? (
                  <List
                    size="small"
                    dataSource={dataDetail?.users || []}
                    renderItem={(item, index) => (
                      <List.Item style={{ padding: "0px" }}>
                        {index + 1}. {item?.fullname} - {item?.email} - {item?.telephone}
                      </List.Item>
                    )}
                  />
                ) : dataDetail?.users?.length > 0 ? (
                  <Typography.Text>
                    {dataDetail?.users?.[0]?.fullname} - {dataDetail?.users?.[0]?.email} -{" "}
                    {dataDetail?.users?.[0]?.telephone}
                  </Typography.Text>
                ) : (
                  <Typography.Text>{"---"}</Typography.Text>
                )}
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.paymentInfoBank")}</Typography.Text>
              </Col>
              <Col span={16}>
                <List
                  itemLayout="horizontal"
                  dataSource={dataDetail?.bankAccounts || []}
                  renderItem={(item, index) => (
                    <List.Item style={{ padding: "4px" }}>
                      {index + 1}. {item?.bank?.name} - {item?.bankAccountNumber} - {item?.name} - {item?.bankBranch}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          ) : (
            <Row gutter={[16, 16]}>
              {dataDetail?.idNumber && (
                <>
                  <Col span={8}>
                    <Typography.Text strong>{t("seller.labelInput.numberPerson")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text>{dataDetail?.idNumber || "--"}</Typography.Text>
                  </Col>
                </>
              )}
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.taxIDNumber")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{dataDetail?.vatInfo?.taxIDNumber || "--"}</Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.companyAddress")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{dataDetail?.vatInfo?.address || "--"}</Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.email")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{dataDetail?.email || "--"}</Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.contactPhone")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{dataDetail?.telephone || "--"}</Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.loginAccount")}</Typography.Text>
              </Col>
              <Col span={16}>
                {dataDetail?.users?.length > 1 ? (
                  <List
                    size="small"
                    dataSource={dataDetail?.users || []}
                    renderItem={(item, index) => (
                      <List.Item style={{ padding: "0px" }}>
                        {index + 1}. {item?.fullname} - {item?.email} - {item?.telephone}
                      </List.Item>
                    )}
                  />
                ) : dataDetail?.users?.length > 0 ? (
                  <Typography.Text>
                    {dataDetail?.users?.[0]?.fullname} - {dataDetail?.users?.[0]?.email} -{" "}
                    {dataDetail?.users?.[0]?.telephone}
                  </Typography.Text>
                ) : (
                  <Typography.Text>{"---"}</Typography.Text>
                )}
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.paymentInfoBank")}</Typography.Text>
              </Col>
              <Col span={16}>
                <List
                  itemLayout="horizontal"
                  dataSource={dataDetail?.bankAccounts || []}
                  renderItem={(item, index) => (
                    <List.Item style={{ padding: "4px" }}>
                      {index + 1}. {item?.bank?.name} - {item?.bankAccountNumber} - {item?.name} - {item?.bankBranch}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col span={24} xl={8}>
          {dataDetail?.isSeller ? (
            <Row gutter={[16, 8]}>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.warehouseIDs")}</Typography.Text>
              </Col>

              <Col span={16}>
                <List
                  size="small"
                  dataSource={dataDetail?.warehouses || []}
                  renderItem={(item) => (
                    <List.Item style={{ padding: "0px" }}>{`${item?.branch?.name} - ${item?.name}`}</List.Item>
                  )}
                />
              </Col>
              {dataDetail?.paymentMethod !== PAYMENT_METHOD.WALLET && (
                <>
                  <Col span={8}>
                    <Typography.Text strong>{t("seller.sellerDebtLimit")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text>{formatCurrency(dataDetail?.debtLimit) || "--"}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Typography.Text strong>{t("seller.labelInput.debtAge")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text>
                      {dataDetail?.debtAge} {t("common.DAY")}
                    </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Typography.Text strong>{t("cart.debtLimit")}</Typography.Text>
                  </Col>
                  <Col span={16}>
                    <Typography.Text>{formatCurrency(dataDetail?.availabilityLimit) || "--"}</Typography.Text>
                  </Col>
                </>
              )}

              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.typePay")}</Typography.Text>
              </Col>

              <Col span={16}>
                <Typography.Text>{convertPaymentMethod(dataDetail?.paymentMethod) || "--"}</Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.saleCategories")}</Typography.Text>
              </Col>
              <Col span={16}>
                <CustomModal
                  {...{
                    customComponent: (
                      <Typography.Text italic style={{ color: "#2246DC" }}>
                        {t("seller.labelInput.seeDetail")}
                      </Typography.Text>
                    ),

                    title: <Typography.Title level={3}>{t("seller.saleCategories")}</Typography.Title>,
                    footer: false,
                    hideConfirmButton: true,
                    hideCancelButton: true,
                    width: "fit-content",
                  }}
                >
                  <CategoriesList dataDetail={dataDetail} />
                </CustomModal>
              </Col>
            </Row>
          ) : (
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.typeSupplier")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>
                  {t(convertTypeAgent(dataDetail?.type)) || "--"}
                  {dataDetail?.isSeller && (
                    <Typography.Text type="warning" italic>
                      {" "}
                      ({t("supplier.isCustomer")})
                    </Typography.Text>
                  )}
                </Typography.Text>
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.sellerGroup")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Link to={`/seller-group/detail/${dataDetail?.sellerGroup?.id}`}>
                  {dataDetail?.sellerGroup?.name || "--"}
                </Link>
              </Col>
              <Col span={8}>
                <Typography.Text strong>{t("seller.labelInput.address")}</Typography.Text>
              </Col>
              <Col span={16}>
                <Typography.Text>{fullAddress || "--"}</Typography.Text>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Info;
