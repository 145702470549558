import { gql } from "@apollo/client";

export const POTENTIAL_CUSTOMER_LIST = gql`
  query PotentialCustomerList(
    $filters: PotentialCustomerFilters! = {
      inChargeStaffIDFilter: { filterType: ALL }
      priorityFilter: { filterType: ALL }
      isValidate: false
    }
    $pagination: PaginationRequest!
  ) {
    potentialCustomer {
      pagination(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        customers {
          id
          sellerID
          status
          fullName
          address
          rejectedReason
          cityID
          city {
            name
          }
          wardID
          ward {
            name
          }
          districtID
          district {
            name
          }
          email
          telephone
          type
          priority
          desistReason
          inChargeStaffID
          inChargeStaff {
            id
            fullname
          }
          careStaffID
          careStaff {
            id
            fullname
          }
          dataSource
          createdAt
          status
          createdBy {
            id
            fullname
          }
          expectedRevenue
          interestedCategories {
            id
            name
          }
          note
          legalRepresentativeName
          legalRepresentativeTelephone
          legalRepresentativeEmail
          registeredBusinessAddress
          representativeName
          representativeEmail
          representativeTelephone
          vatNumber
          idNumber
        }
      }
    }
  }
`;

export const COUNT_POTENTIAL_CUSTOMER = gql`
  {
    new: potentialCustomer {
      count(filters: { statuses: [NEW] })
    }
    takingCare: potentialCustomer {
      count(filters: { statuses: [TAKING_CARE] })
    }
    stop: potentialCustomer {
      count(filters: { statuses: [STOP_CARING] })
    }
    completeCaring: potentialCustomer {
      count(filters: { statuses: [COMPLETE_CARING] })
    }
  }
`;

export const CHECK_DUPLICATE_CUSTOMER = gql`
  query CheckDuplicateCustomer($filters: PotentialCustomerFilters!, $pagination: PaginationRequest!) {
    potentialCustomer {
      getLinkableCustomers(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        customers {
          id
          address
          fullName
          status
          type
          sellerType
          email
          potentialCustomerID
          potentialCustomer {
            id
            fullName
            telephone
            email
            idNumber
            vatNumber
          }
          sellerID
          seller {
            id
            fullName
            telephone
            email
            idNumber
            vatInfo {
              taxIDNumber
            }
          }
        }
      }
    }
  }
`;

export const POTENTIAL_CUSTOMER_DETAIL = gql`
  query PotentialCustomerDetail($id: ID!) {
    potentialCustomer {
      get(id: $id) {
        id
        fullName
        status
        careStaffID
        careStaff {
          fullname
        }
        inChargeStaffID
        inChargeStaff {
          fullname
        }
        type
        dataSource
        vatNumber
        idNumber
        email
        telephone
        address
        cityID
        city {
          id
          name
        }
        districtID
        district {
          id
          name
        }
        wardID
        ward {
          id
          name
        }
        priority
        expectedRevenue
        interestedCategories {
          id
          name
        }
        note
        representativeName
        representativeEmail
        representativeTelephone
        legalRepresentativeName
        legalRepresentativeEmail
        legalRepresentativeTelephone
        registeredBusinessAddress
        sellerID
        seller {
          id
          email
          fullName
          telephone
          users {
            id
            email
            fullname
            telephone
          }
        }
      }
    }
  }
`;

export const GET_FILE_SAMPLE_CUSTOMER = gql`
  query GetSampleFileCustomer {
    potentialCustomer {
      getBatchCreateExampleFile {
        path
        url
      }
    }
  }
`;

export const POTENTIAL_CUSTOMER_ACTIVITY_LOGS = gql`
  query PotentialCustomerActivityLogs($customerID: ID!) {
    potentialCustomer {
      getActivityLogs(customerID: $customerID) {
        potentialCustomerLogs {
          communicationType
          fileUrls
          eventType
          linkAccountEmail
          nextStatus
          note
          previousStatus
          rejectReason
          desistReason
          title
          type
          createdByID
          createdBy {
            fullname
          }
          createdAt
        }
      }
    }
  }
`;

export const POTENTIAL_CUSTOMER_LINK_ABLE = gql`
  query PotentialCustomerLinkAble(
    $filters: PotentialCustomerFilters! = { isValidate: false }
    $pagination: PaginationRequest!
  ) {
    potentialCustomer {
      getLinkableCustomers(filters: $filters, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        customers {
          id
          potentialCustomerID
          potentialCustomer {
            id
            sellerID
            status
            fullName
            address
            rejectedReason
            cityID
            city {
              name
            }
            wardID
            ward {
              name
            }
            districtID
            district {
              name
            }
            email
            telephone
            type
            priority
            desistReason
            inChargeStaffID
            inChargeStaff {
              id
              fullname
            }
            careStaffID
            careStaff {
              id
              fullname
            }
            dataSource
            createdAt
            status
            createdBy {
              id
              fullname
            }
            expectedRevenue
            interestedCategories {
              id
              name
            }
            note
            legalRepresentativeName
            legalRepresentativeTelephone
            legalRepresentativeEmail
            registeredBusinessAddress
            representativeName
            representativeEmail
            representativeTelephone
            vatNumber
            idNumber
            seller {
              type
              isSupplier
              isSeller
              sellerGroup {
                id
                name
              }
              idNumber
              vatInfo {
                taxIDNumber
                id
                address
                email
                businessName
                telephone
                representative
              }
              email
              telephone
              address
              ward {
                name
              }
              district {
                name
              }
              city {
                name
              }
              warehouses {
                id
                name
              }
              paymentMethod
              debtLimit
              debtAge
              availabilityLimit
              bankAccounts {
                name
                bankAccountNumber
                bankBranch
              }
              users {
                id
                fullname
                email
                telephone
              }
              representName
              representPhone
              representEmail
            }
          }
          sellerID
          seller {
            type
            isSupplier
            isSeller
            sellerGroup {
              id
              name
            }
            idNumber
            vatInfo {
              taxIDNumber
              id
              address
              email
              businessName
              telephone
              representative
            }
            email
            fullName
            telephone
            address
            ward {
              name
            }
            district {
              name
            }
            city {
              name
            }
            warehouses {
              id
              name
            }
            paymentMethod
            debtLimit
            debtAge
            availabilityLimit
            bankAccounts {
              name
              bankAccountNumber
              bankBranch
            }
            users {
              id
              fullname
              email
              telephone
            }
            representName
            representPhone
            representEmail
          }
          status
          fullName
          email
          address
          sellerType
          telephone
        }
      }
    }
  }
`;

export const GET_STAFF_WITH_IDS = gql`
  query GetStaffWithIds($filters: UsersFilters) {
    user {
      pagination(filters: $filters) {
        users {
          id
          fullname
        }
      }
    }
  }
`;
