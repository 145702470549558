import React, { useState } from 'react';
import { Button } from 'antd';
import CustomModal from 'components/CustomModal';
import { t } from 'i18next';
import { useManagerApproveSalesReturn } from 'hooks/recommendimport';
import { notify } from 'utils/helperFuncs';
import ModalWithIconQuestion from 'components/CustomModalWithIcon/Question';
import { SALES_RETURN } from 'features/Warehouse/constant';

function ButtonManagerApproveSalesReturn({ record }) {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const { handleManagerApproveSalesReturn, loading: loadingManager } =
    useManagerApproveSalesReturn();

  const confirmApprove = async () => {
    await handleManagerApproveSalesReturn({ id: record?.id })
      .then(() => {
        notify.success({
          message: t('warehouse.salesReturn.messageApproveRequest'),
        });
        setVisible(false);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };
  return (
    <CustomModal
      {...{
        customComponent: t('warehouse.recommendImport.accept'),
        footer: false,
        message: (
          <ModalWithIconQuestion
            title={
              record?.status === SALES_RETURN?.WAITING_APPROVAL &&
              record?.waitingApprovalCount === 1
                ? `${t('warehouse.recommendImport.questionaccept')} ${record?.code} ${t(
                    'warehouse.recommendImport.no'
                  )}`
                : `${t('warehouse.salesReturn.questionaccept')} ${record?.code} ${t(
                    'warehouse.salesReturn.no'
                  )}`
            }
          />
        ),
        centered: true,
        styleButton: 'notButton',
        hideConfirmButton: true,
        addMoreButton: (
          <Button type="primary" onClick={confirmApprove} loading={loadingManager}>
            {t('common.confirm')}
          </Button>
        ),
        visible: visible,
        onOpen: showModal,
        onCancel: handleCancel,
      }}
    />
  );
}

export default ButtonManagerApproveSalesReturn;
