import React from "react";
import { Input } from "antd";

import { Icon, FontAwesomeIcon } from "assets/icons";
import { useToolbar } from "./useToolbar";
import CreateButton from "components/CreateButton";
import FilterDate from "components/CustomFilterMenu/FilterDate";

import "./index.scss";
import { useCampaignPermissions } from "hooks/campaign";

function Toolbar({
  params,
  onChangeParams,
  isStatusAll,
  handleSearch,
  statusTabs,
  placeholder,
  titleButton,
  titleFilterData,
  sellerName,
  showInput = true,
  showButton = true,
  showFilterDate = true,
  children,
  handleGetValueVisibleModal,
}) {
  const { handleChangeDate, renderDateTag, renderTags, renderActionsTag } = useToolbar({
    params,
    onChangeParams,
    isStatusAll,
    statusTabs,
    sellerName,
  });

  const { canCreateCampaign } = useCampaignPermissions();

  return (
    <>
      <div className="filter-campaign-container-1">
        <div className="search-input">
          {showFilterDate && <FilterDate onChangeDate={handleChangeDate} title={titleFilterData} />}
          {showInput && (
            <Input
              className="custom-input input-seller"
              prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
              placeholder={placeholder}
              allowClear={true}
              onChange={(value) => handleSearch(value)}
            />
          )}
        </div>
        {showButton && canCreateCampaign && (
          <CreateButton title={titleButton} className="btn-create" onClick={() => handleGetValueVisibleModal(true)} />
        )}
        {children}
      </div>
      <div style={{ margin: "10px 0" }}>
        {renderDateTag()}
        {renderActionsTag()}
        {renderTags}
      </div>
    </>
  );
}

export default Toolbar;
