import React from "react";
import { Button } from "antd";
import { t } from "i18next";

function PrimaryButton({ title, ...rest }) {
  return (
    <Button type="primary" {...rest}>
      {title || t("common.save")}
    </Button>
  );
}

function PrimaryButtonOutLine({ title, primaryColor = "#2246dc", restStyle, ...rest }) {
  return (
    <Button style={{ color: primaryColor, borderColor: primaryColor, ...restStyle }} type="link" {...rest}>
      {title || t("common.save")}
    </Button>
  );
}

export { PrimaryButton, PrimaryButtonOutLine };
