import { t } from 'i18next';

export const RECOMMEND_IMPORT = {
  ALL: 'ALL',
  WAITING_APPROVAL: 'WAITING_APPROVAL',
  REJECTED: 'REJECTED',
  WAITING_FOR_BUYING: 'WAITING_FOR_BUYING',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};
export const IMPORT_REQUEST_STATUS = {
  CONFIRM_COMPLETE: 'CONFIRM_COMPLETE',
  CONFIRM_APPROVE: 'CONFIRM_APPROVE',
  CANCEL: 'CANCEL',
};

export const convertActiveKey = (activeKey) => {
  switch (activeKey) {
    case 'ALL':
      return [];
    case 'WAITING_APPROVAL':
      return [RECOMMEND_IMPORT.WAITING_APPROVAL];
    case 'REJECTED':
      return [RECOMMEND_IMPORT.REJECTED];
    case 'WAITING_FOR_BUYING':
      return [RECOMMEND_IMPORT.WAITING_FOR_BUYING];
    case 'COMPLETED':
      return [RECOMMEND_IMPORT.COMPLETED];
    case 'CANCELED':
      return [RECOMMEND_IMPORT.CANCELED];
    case 'WAITING_TECHNICAL_CONFIRM':
      return [SALES_RETURN.WAITING_TECHNICAL_CONFIRM];
    case 'WAITING_FOR_STOCK':
      return [SALES_RETURN.WAITING_FOR_STOCK];
    default:
      return [];
  }
};

export const PAYMENT_METHODS = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  DEBT: 'DEBT',
  CASH: 'CASH',
};

export const PAYMENT_TERMS = {
  ADVANCE_PAYMENT: 'ADVANCE_PAYMENT',
  CASH_ON_DELIVERY: 'CASH_ON_DELIVERY',
  DEFERRED_PAYMENT: 'DEFERRED_PAYMENT',
};

export const IMPORT_REQUEST_TYPE = {
  DOMESTIC: 'DOMESTIC',
  OVERSEAS: 'OVERSEAS',
  REFUND: 'REFUND',
  RE_IMPORT_WARRANTY: 'RE_IMPORT_WARRANTY',
};

export const paymentRequest = {
  [`${PAYMENT_METHODS.BANK_TRANSFER}`]: t('warehouse.recommendImport.BANK_TRANSFER'),
  [`${PAYMENT_METHODS.DEBT}`]: t('warehouse.recommendImport.DEBT'),
  [`${PAYMENT_METHODS.CASH}`]: t('warehouse.recommendImport.CASH'),
};

export const paymentTerms = {
  [`${PAYMENT_TERMS.ADVANCE_PAYMENT}`]: t('warehouse.recommendImport.ADVANCE_PAYMENT'),
  [`${PAYMENT_TERMS.CASH_ON_DELIVERY}`]: t('warehouse.recommendImport.CASH_ON_DELIVERY'),
  [`${PAYMENT_TERMS.DEFERRED_PAYMENT}`]: t('warehouse.recommendImport.DEFERRED_PAYMENT'),
};

export const importRequestType = {
  [`${IMPORT_REQUEST_TYPE.DOMESTIC}`]: t('warehouse.recommendImport.DOMESTIC'),
  [`${IMPORT_REQUEST_TYPE.OVERSEAS}`]: t('warehouse.recommendImport.OVERSEAS'),
  [`${IMPORT_REQUEST_TYPE.REFUND}`]: t('warehouse.salesReturn.REFUND'),
  [`${IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY}`]: t('warehouse.salesReturn.RE_IMPORT_WARRANTY'),
};

export const importRequestStatus = {
  [`${RECOMMEND_IMPORT.WAITING_APPROVAL}`]: t('warehouse.recommendImport.pending'),
  [`${RECOMMEND_IMPORT.REJECTED}`]: t('warehouse.recommendImport.rejected'),
  [`${RECOMMEND_IMPORT.WAITING_FOR_BUYING}`]: t('warehouse.recommendImport.waitingBuy'),
  [`${RECOMMEND_IMPORT.COMPLETED}`]: t('warehouse.recommendImport.complete'),
  [`${RECOMMEND_IMPORT.CANCELED}`]: t('warehouse.recommendImport.canceled'),
};

export const setColorStatusImportRequest = (status) => {
  switch (status) {
    case RECOMMEND_IMPORT.WAITING_APPROVAL:
      return '#EF9351';
    case RECOMMEND_IMPORT.WAITING_FOR_BUYING:
      return '#052987';
    case RECOMMEND_IMPORT.COMPLETED:
      return '#00AB78';
    case RECOMMEND_IMPORT.REJECTED:
      return '#D2434D';
    case RECOMMEND_IMPORT.CANCELED:
      return '#262F3B';
    default:
      break;
  }
};

export const optionImportRequestStatus = [
  {
    label: t('warehouse.recommendImport.pending'),
    value: RECOMMEND_IMPORT.WAITING_APPROVAL,
  },
  {
    label: t('warehouse.recommendImport.rejected'),
    value: RECOMMEND_IMPORT.REJECTED,
  },
  {
    label: t('warehouse.recommendImport.waitingBuy'),
    value: RECOMMEND_IMPORT.WAITING_FOR_BUYING,
  },
  {
    label: t('warehouse.recommendImport.complete'),
    value: RECOMMEND_IMPORT.COMPLETED,
  },
  {
    label: t('warehouse.recommendImport.canceled'),
    value: RECOMMEND_IMPORT.CANCELED,
  },
];

export const optionImportRequestType = [
  {
    label: t('warehouse.recommendImport.DOMESTIC'),
    value: IMPORT_REQUEST_TYPE.DOMESTIC,
  },
  {
    label: t('warehouse.recommendImport.OVERSEAS'),
    value: IMPORT_REQUEST_TYPE.OVERSEAS,
  },
];

export const optionPaymentRequest = [
  {
    label: t('warehouse.recommendImport.BANK_TRANSFER'),
    value: PAYMENT_METHODS.BANK_TRANSFER,
  },
  {
    label: t('warehouse.recommendImport.DEBT'),
    value: PAYMENT_METHODS.DEBT,
  },
  {
    label: t('warehouse.recommendImport.CASH'),
    value: PAYMENT_METHODS.CASH,
  },
];

export const optionPaymentTerms = [
  {
    label: t('warehouse.recommendImport.ADVANCE_PAYMENT'),
    value: PAYMENT_TERMS.ADVANCE_PAYMENT,
  },
  {
    label: t('warehouse.recommendImport.CASH_ON_DELIVERY'),
    value: PAYMENT_TERMS.CASH_ON_DELIVERY,
  },
  {
    label: t('warehouse.recommendImport.DEFERRED_PAYMENT'),
    value: PAYMENT_TERMS.DEFERRED_PAYMENT,
  },
];

export const CURRENCY_INPUT = {
  VND: 'VND',
  USD: 'USD',
};

export const currencyFormat = {
  [`${CURRENCY_INPUT.VND}`]: '₫',
  [`${CURRENCY_INPUT.USD}`]: '$',
};

export const optionCurrency = [
  {
    label: '₫',
    value: 'VND',
  },
  {
    label: '$',
    value: 'USD',
  },
];

export const WARRENTY_PERIOD = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  DAY: 'DAY',
};

export const optionwarrantyPeriod = [
  {
    label: t('common.day'),
    value: 'DAY',
  },
  {
    label: t('common.monthB'),
    value: 'MONTH',
  },
  {
    label: t('common.year'),
    value: 'YEAR',
  },
];

export const SALES_RETURN = {
  ALL: 'ALL',
  WAITING_APPROVAL: 'WAITING_APPROVAL',
  REJECTED: 'REJECTED',
  WAITING_TECHNICAL_CONFIRM: 'WAITING_TECHNICAL_CONFIRM',
  WAITING_FOR_STOCK: 'WAITING_FOR_STOCK',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};

export const SaleReturnStatus = {
  [`${SALES_RETURN.WAITING_APPROVAL}`]: t('warehouse.recommendImport.pending'),
  [`${SALES_RETURN.REJECTED}`]: t('warehouse.recommendImport.rejected'),
  [`${SALES_RETURN.WAITING_TECHNICAL_CONFIRM}`]: t('warehouse.salesReturn.quantityChecking'),
  [`${SALES_RETURN.WAITING_FOR_STOCK}`]: t('warehouse.salesReturn.waitingForStock'),
  [`${SALES_RETURN.COMPLETED}`]: t('warehouse.recommendImport.complete'),
  [`${SALES_RETURN.CANCELED}`]: t('warehouse.recommendImport.canceled'),
};

export const optionSalesReturnType = [
  {
    label: t('warehouse.salesReturn.REFUND'),
    value: IMPORT_REQUEST_TYPE.REFUND,
  },
  {
    label: t('warehouse.salesReturn.RE_IMPORT_WARRANTY'),
    value: IMPORT_REQUEST_TYPE.RE_IMPORT_WARRANTY,
  },
];

export const setColorStatusSalesReturn = (status) => {
  switch (status) {
    case SALES_RETURN.WAITING_APPROVAL:
      return '#EF9351';
    case SALES_RETURN.WAITING_TECHNICAL_CONFIRM:
      return '#052987';
    case SALES_RETURN.WAITING_FOR_STOCK:
      return '#EF9351';
    case SALES_RETURN.COMPLETED:
      return '#00AB78';
    case SALES_RETURN.REJECTED:
      return '#D2434D';
    case SALES_RETURN.CANCELED:
      return '#262F3B';
    default:
      break;
  }
};

export const optionSalesReturnStatus = [
  {
    label: t('warehouse.recommendImport.pending'),
    value: SALES_RETURN.WAITING_APPROVAL,
  },
  {
    label: t('warehouse.recommendImport.rejected'),
    value: SALES_RETURN.REJECTED,
  },
  {
    label: t('warehouse.salesReturn.quantityChecking'),
    value: SALES_RETURN.WAITING_TECHNICAL_CONFIRM,
  },
  {
    label: t('warehouse.salesReturn.waitingForStock'),
    value: SALES_RETURN.WAITING_FOR_STOCK,
  },
  {
    label: t('warehouse.recommendImport.complete'),
    value: SALES_RETURN.COMPLETED,
  },

  {
    label: t('warehouse.recommendImport.canceled'),
    value: SALES_RETURN.CANCELED,
  },
];

export const SHIPPING_TYPE = {
  BUS: 'BUS',
  URBAN_COD: 'URBAN_COD',
  DELIVER_3PARTY: 'DELIVER_3PARTY',
  PICK_UP_COUNTER: 'PICK_UP_COUNTER',
  URBAN_NOT_COD: 'URBAN_NOT_COD',
};
