import { Col, Row, Spin, Typography } from "antd";
import CustomInfoAdress from "components/CustomInfoAdress";
import { SelectLoadMore } from "components/CustomSelect";
import { t } from "i18next";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

function SellerInfo({
  sellerOptions,
  loadMoreData,
  hasMoreData,
  onSearch,
  sellerInfo,
  handleChangeInfo,
  loadingSellers,
}) {
  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <SelectLoadMore
          formItemOptions={{
            name: "sellerID",
            rules: [
              {
                required: true,
                message: t("serialIE.error.provider"),
              },
            ],
            className: "form-seller-select",
          }}
          inputOptions={{
            placeholder: t("serialIE.selectSeller"),
            options: sellerOptions,
            dropdownRender: (originNode) => <Spin spinning={loadingSellers}>{originNode}</Spin>,
            onSelect: (value, options) => handleChangeInfo(value, options),
          }}
          onLoadMore={loadMoreData}
          hasMoreData={hasMoreData}
          onSearch={onSearch}
        />
      </Col>
      <>
        <Col span={4}>
          <Typography.Text className={styles["sub-title"]}>{t("campaign.detail.sellerCode")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Link to={`/seller/detail/${sellerInfo?.id}`} target="_blank">
            <Typography.Link strong>{sellerInfo?.code || "--------"}</Typography.Link>
          </Link>
        </Col>
        <Col span={4}>
          <Typography.Text className={styles["sub-title"]}>{t("campaign.detail.sellerName")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{sellerInfo?.fullName || "--------"}</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className={styles["sub-title"]}>{t("serialIE.providerInfo.phone")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{sellerInfo?.telephone || "--------"}</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className={styles["sub-title"]}>{t("serialIE.providerInfo.email")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>{sellerInfo?.email || "--------"}</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className={styles["sub-title"]}>{t("serialIE.providerInfo.address")}</Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text>
            <CustomInfoAdress value={sellerInfo}></CustomInfoAdress>
          </Typography.Text>
        </Col>
      </>
      <Col span={24} style={{ textAlign: "center", width: "100%", marginTop: "16px" }}>
        {!sellerInfo && <Typography.Link strong>{t("warehouse.salesReturn.pleaseChooseSeller")}</Typography.Link>}
      </Col>
    </Row>
  );
}

export default SellerInfo;
