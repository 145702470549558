/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Input, Tag, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './index.scss';
import { t } from 'i18next';
import { useGetSerialExportNotesWarranty } from 'hooks/serial/query';
import { notify } from 'utils/helperFuncs';
import { SERIAL_AVAILABILITY } from 'config/constants';

const tagPlusStyle = {
  background: 'transition',
  borderStyle: 'dashed',
};

function InputTag({
  tags = [],
  onChange,
  moreThanNumber,
  searchResult = [],
  tagsValueResult,
  isHidden,
  idPopup,
  form,
  productId,
  quantity,
  orderID,
}) {
  const [inputValue, setInputValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState(true);
  const [visibleSearchResult, setVisibleSearchResult] = useState(false);

  const { handleGetSerialExportNotesWarranty } = useGetSerialExportNotesWarranty();
  const handleAdd = async (valueSearch) => {
    if (valueSearch?.length > 0) {
      let newArray = [];
      const newTags = inputValue
        ?.split(/[, ]+/)
        ?.filter((item) => item.trim() !== '')
        ?.map((tag) => tag?.trim());
      await Promise.all(
        newTags.map(async (item) => {
          const serialData = await handleGetSerialExportNotesWarranty({
            filter: {
              queries: item || [' '],
              productIDs: productId,
              statuses: SERIAL_AVAILABILITY.UNAVAILABLE,
              orderIDs: orderID,
            },
            pagination: {
              offset: 0,
              limit: 100,
            },
          });
          const checkSerial = serialData?.data?.serial?.pagination?.Serials?.map(
            (item) => item?.code
          );
          if (item === (checkSerial ? checkSerial[0] : '')) {
            newArray.push(item);
          } else {
            notify.warning({
              message: t('warehouse.salesReturn.serialError', { serial: item }),
            });
          }
        })
      );

      if (newArray.length > 0) {
        const duplicateElements = newArray.filter((tag) => tags.includes(tag));
        if (newArray.some((tag) => !tags.includes(tag))) {
          const newValues = [
            ...tags,
            ...newArray.filter((tag) => tag !== '' && !tags.includes(tag)),
          ];
          onChange(newValues);
        } else {
          notify.warning({
            message: t('warehouse.salesReturn.errorDuplicateSerial', {
              listSerial: duplicateElements.join(', '),
            }),
          });
        }
      }
    }
    setInputValue('');
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleRemove = (removedValue) => {
    const newValues = tags.filter((val) => val !== removedValue);
    onChange(newValues);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputPressEnter = (e) => {
    if (quantity > 0) {
      handleAdd(e?.target?.value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAdd();
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (searchResult?.length) {
      setVisibleSearchResult(true);
      setVisibleTags(false);
    } else {
      setVisibleSearchResult(false);
      setVisibleTags(true);
    }
  }, [JSON.stringify(searchResult)]);

  return (
    <div className={`input-tag-container-create`}>
      <div className={`${isHidden && 'isHidden'}`}>
        <div className="input-tag-tags">
          {tags?.length > 8 && idPopup === false && (
            <Tag className="input-tag-tag custom-tags-by-serial">
              <Typography.Link italic>{`+${tags?.length - 8} serial...`}</Typography.Link>
            </Tag>
          )}
          {tagsValueResult?.length > 0 && visibleSearchResult === false ? (
            <div className="message-no-data">{t('serialIEDetail.notFound')}</div>
          ) : (
            visibleTags &&
            idPopup === false &&
            tags.slice(-8).map((val) => (
              <Tag
                className="input-tag-tag custom-tags-by-serial"
                key={val}
                closable
                onClose={() => handleRemove(val)}
              >
                {val}
              </Tag>
            ))
          )}
          {tagsValueResult?.length > 0 && visibleSearchResult === false ? (
            <div className="message-no-data">{t('serialIEDetail.notFound')}</div>
          ) : (
            visibleTags &&
            idPopup === true &&
            tags.map((val) => (
              <Tag
                className="input-tag-tag custom-tags-by-serial"
                key={val}
                closable
                onClose={() => handleRemove(val)}
              >
                {val}
              </Tag>
            ))
          )}
          {visibleSearchResult &&
            searchResult?.map((val) => (
              <Tag
                className="input-tag-tag custom-tags-by-serial"
                key={val}
                closable
                onClose={() => handleRemove(val)}
              >
                {val}
              </Tag>
            ))}
        </div>
      </div>
      {idPopup ? (
        <></>
      ) : idPopup === false && inputVisible ? (
        <Input
          className="input-value input-enter-serial"
          ref={inputRef}
          type="text"
          size="small"
          style={{
            width: 100,
          }}
          disabled={quantity > 0 && tags?.length < quantity ? false : true}
          value={inputValue}
          onChange={handleChange}
          onPressEnter={handleInputPressEnter}
          onKeyDown={handleKeyDown}
          placeholder={t('serialImportEdit.newSerial')}
        />
      ) : (
        <Tag className="input-value input-enter-serial" onClick={showInput} style={tagPlusStyle}>
          <PlusOutlined /> {t('serialIEDetail.addNew')}
        </Tag>
      )}
    </div>
  );
}

export default InputTag;
