import { useForm } from 'antd/lib/form/Form';
import { IMPORT_REQUEST_STATUS } from 'features/Warehouse/constant';
import {
  useConfirmRejectImportRequest,
  useGetDetailImportRequest,
  useImportRequestPermissions,
  useUpdateStatusImportRequest,
} from 'hooks/recommendimport';
import { t } from 'i18next';
import { useState } from 'react';
import {  useParams } from 'react-router-dom';
import { notify } from 'utils/helperFuncs';

const useList = () => {
  const { id } = useParams();
  const { data, loading } = useGetDetailImportRequest({ id: id });
  const [form] = useForm();
  const {
    canImportRequestView,
    canImportRequestCreate,
    canImportRequestUpdate,
    canImportRequestApprove,
    canImportRequestReject,
    canImportRequestCancel,
    canImportRequestComplete,
    canImportRequestViewCreatedByMe,
  } = useImportRequestPermissions();

  const [visibleReject, setVisibleReject] = useState(false);
  const showModalReject = () => {
    setVisibleReject(true);
  };
  const handleCancelReject = () => {
    setVisibleReject(false);
  };

  const { handleConfirmRejectImportRequest, loading: loadingReject } =
    useConfirmRejectImportRequest();
  const confirmReject = async () => {
    const values = await form.validateFields();
    const params = {
      id: data?.id,
      request: {
        rejectedReason: values?.rejectedReason,
      },
    };
    await handleConfirmRejectImportRequest(params)
      .then(() => {
        notify.success({
          message: t('warehouse.recommendImport.messageReject'),
        });
        setVisibleReject(false);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };

  const [visibleAccept, setVisibleAccept] = useState(false);
  const showModalAccept = () => {
    setVisibleAccept(true);
  };
  const handleCancelAccept = () => {
    setVisibleAccept(false);
  };
  const { handleUpdateStatusImportRequest, loading: loadingAccept } =
    useUpdateStatusImportRequest();
  const onAccept = async () => {
    const params = {
      id: data?.id,
      actionType: IMPORT_REQUEST_STATUS.CONFIRM_APPROVE,
    };
    await handleUpdateStatusImportRequest(params)
      .then(() => {
        notify.success({
          message: t('warehouse.recommendImport.messageacceptrequest'),
        });
        setVisibleAccept(false);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };

  const [visibleCancel, setVisibleCancel] = useState(false);
  const showModalCancel = () => {
    setVisibleCancel(true);
  };
  const handleCancel = () => {
    setVisibleCancel(false);
  };
  const onCancel = async () => {
    const params = {
      id: data?.id,
      actionType: 'CANCEL',
    };
    await handleUpdateStatusImportRequest(params)
      .then(() => {
        notify.success({
          message: t('warehouse.recommendImport.messagecancelrequest'),
        });
        setVisibleCancel(false);
      })
      .catch((error) => {
        notify.error({
          message: error?.message,
        });
      });
  };
  return {
    data,
    loading,
    form,
    canImportRequestView,
    canImportRequestCreate,
    canImportRequestUpdate,
    canImportRequestApprove,
    canImportRequestReject,
    canImportRequestCancel,
    canImportRequestComplete,
    canImportRequestViewCreatedByMe,
    visibleReject,
    showModalReject,
    confirmReject,
    loadingReject,
    handleCancelReject,
    visibleAccept,
    showModalAccept,
    handleCancelAccept,
    loadingAccept,
    onAccept,
    visibleCancel,
    showModalCancel,
    onCancel,
    handleCancel,
  };
};

export default useList;
