import React from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Space, Typography } from "antd";

export default function ModalWithIconQuestion({ title }) {
  return (
    <Space direction="vertical">
      <QuestionCircleFilled
        style={{
          fontSize: "50px",
          color: "#2246DC",
          padding: "20px",
          borderRadius: 50,
          background:
            "radial-gradient(circle, rgba(231,240,255,1) 0%, rgba(244,248,255,1) 50%, rgba(255,255,255,1) 100%)",
        }}
      />
      <Typography.Text style={{ fontSize: "16px" }}>{title}</Typography.Text>
    </Space>
  );
}
