import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { DATE_ISO_8601_FORMAT } from 'config/constants';
import { REASON_CANCEL, ResultMethod } from 'features/WarrantyRequest/constant';
import { t } from 'i18next';
import { notify } from 'utils/helperFuncs';
import { useReceiveResult } from 'hooks/warranty';
import { formatDateTime } from 'utils/dateTime';
import moment from 'moment';
import { useUploadDocument } from 'hooks/upload-document';

function usePopupGetResult({ record, refetch, itemID }) {
  const [form] = Form.useForm();
  const [switchClose, setSwitchClose] = useState(true);
  const [resultValue, setResultValue] = useState(ResultMethod.EXCHANGE_SIMILAR_PRODUCT);
  const [resultOfHAValue, setResultOfHAValue] = useState(ResultMethod.EXCHANGE_SIMILAR_PRODUCT);
  const { handleReceiveResult, loading } = useReceiveResult();
  const [loadingBtn, setLoadingBtn] = useState(loading);
  const { handleUpload } = useUploadDocument();
  const [reason, setReason] = useState(REASON_CANCEL.EXPIRED);
  const [freetext, setFreetext] = useState(null);

  const initialValues = {
    result: resultOfHAValue,
    brandResult: resultValue,
    resultReceivedDate: moment(),
    alternativeProductSerial: null,
    alternativeProductName: null,
    alternativeProductCode: null,
    alternativeProductCategoryID: null,
    receiveAmount: null,
    returnAmount: null,
    repairAccessoryName: null,
    alternativeAccessoryName: null,
    alternativeAccessoryStatus: null,
    repairCost: null,
    resultFileURLs: [],
    declineReason: {
      defaultReason: reason,
      freetext: freetext,
    },
  };
  const handleValuesChange = (valueChange, allValues) => {
    if (valueChange.declineReason) {
      setReason(valueChange.declineReason.defaultReason);
      setFreetext(valueChange.declineReason.freetext);
    }
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    setLoadingBtn(true);
    const newImages = values?.resultFileURLs?.filter((item) => !item?.url);
    const resultFileURLs = await handleUpload({
      files: newImages?.map((item) => item?.originFileObj),
    });

    const params = {
      warrantyRequestItemID: record?.id || itemID,
      confirmResultRequest: {
        result: values?.result,
        brandResult: values?.brandResult,
        resultReceivedDate: formatDateTime(values?.resultReceivedDate, DATE_ISO_8601_FORMAT),
        alternativeProductSerial: values?.alternativeProductSerial,
        alternativeProductName: values?.alternativeProductName,
        alternativeProductCode: values?.alternativeProductCode,
        alternativeProductCategoryID: values?.alternativeProductCategoryID,
        alternativeAccessoryName: values?.alternativeAccessoryName,
        alternativeAccessoryStatus: values?.alternativeAccessoryStatus,
        receiveAmount: values?.receiveAmount,
        returnAmount: values?.returnAmount,
        repairAccessoryName: values?.repairAccessoryName,
        resultFileURLs: resultFileURLs?.map(({ url }) => url),
        note: values?.resultNote,
        declineReason: values?.declineReason,
      },
    };

    await handleReceiveResult(params)
      .then((values) => {
        setSwitchClose(!switchClose);
        setLoadingBtn(false);
        form.resetFields();
        notify.success({
          message: `${t('notiActions.getResult')}`,
        });
      })
      .catch((err) => {
        console.info('Validate Failed:', err);
      });
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    if (values?.reason?.defaultReason !== null) {
      form.setFieldsValue({
        ...values,
        reason: {
          ...values?.reason,
          freetext: null,
        },
      });
    }
  }, [reason]);

  return {
    initialValues,
    form,
    switchClose,
    resultOfHAValue,
    reason,
    loadingBtn,
    resultValue,
    setResultOfHAValue,
    handleOk,
    setResultValue,
    handleValuesChange,
  };
}

export default usePopupGetResult;
