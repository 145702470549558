import React from 'react';
import { Input } from 'antd';

import { Icon, FontAwesomeIcon } from 'assets/icons';
import { useToolbar } from './useToolbar';

import './index.scss';

function Toolbar({ params, onChangeParams, handleSearch, placeholder }) {
  const { renderTags } = useToolbar({
    params,
    onChangeParams,
  });

  return (
    <>
      <div className="filter-campaign-container">
        <div className="search-input">
          <Input
            className="custom-input input-seller"
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={placeholder}
            allowClear={true}
            onChange={(value) => handleSearch(value)}
          />
        </div>
      </div>
      <div className="tags-list">{renderTags}</div>
    </>
  );
}

export default Toolbar;
